import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import {fetchStore} from '../../../redux/actions/index'
import {ShowStore} from './ShowStore'
import useAuth from '../../../hooks/session/useAuth'

export const ShowStoreContainer = () => {
  const storeState = useSelector((state) => state.storeReducer)
  const dispatch = useDispatch()
  const {id} = useParams()
  const {auth} = useAuth()
  const [store, setStore] = useState({})
  const [storeRequested, setStoreRequested] = useState(false)
  const [deleteRequested, setDeleteRequested] = useState(false)
  const navigate = useNavigate()
  const [storeProducts, setStoreProducts] = useState([])
  const [storeCategories, setStoreCategories] = useState([])
  const [reviews, setReviews] = useState(4)

  useEffect(() => {
    if (deleteRequested && storeState?.store.length === 0) {
      setMsg(storeState?.msg)
      setStore(null)
      setTimeout(() => {
        navigate('/stores')
      }, 2500)
    }
  }, [storeState])

  if (!store.id) {
    let storeFound = null
    if (storeState?.stores.length) {
      storeFound = storeState?.stores.find((store) => store.id === id)
      if (storeFound) {
        setStore(storeFound)
        setStoreProducts(storeFound.products)
      }
    }
    if (!storeRequested && !storeFound) {
      dispatch(fetchStore(id))
      setStoreRequested(true)
    }
  }

  return (
    <ShowStore
      auth={auth}
      store={store}
      storeProducts={storeProducts}
      storeCategories={storeCategories}
      reviews={reviews}
    />
  )
}

import React from "react";

export const IconBack = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            width={24}
            x="0"
            y="0"
            fill="#646464"
            viewBox="0 0 32 32"
        >
            <path
                fill="#FFFFFF"
                d="M0 16l15.668 16v-9.347H32V9.348H15.668V0L0 16z"
            ></path>
        </svg>
    );
}


import {Link} from 'react-router-dom'
import {ButtonNotifications, ButtonBack, Gallery} from '../../../components'
import {RatingStars} from '../../../components'

export const ShowStore = (props) => {
  const {auth, store, storeProducts, storeCategories, reviews} = props

  return (
    <>
      <div className="background-circle" />
      <section className="item">
        <div className="item__top">
          <div className="item__top__left">
            <ButtonBack />
            <h1 className="item__top__left__user-name h4-bold ">
              {auth?.userInfo?.firstName}
            </h1>
          </div>
        </div>
        <div className="item__picture">
          {store.pictureSignedUrl ? (
            <img className="img-user" src={store.pictureSignedUrl} />
          ) : (
            <div className="img-placeholder-large" />
          )}
        </div>
        <div className="item__main">
          <div className="item__main__top">
            <h2 className="h2-bold">{store?.name}</h2>
            <h5 className="text-l">Distance: 5km</h5>
          </div>
          <div className="item__main__rating">
            <div className="item__main__rating__stars">
              <RatingStars rating={2} />
            </div>
            <div className="item__main__rating__reviews">
              <Link to="/reviews" className="item__main__rating__reviews__label text-l">
                {reviews}
                <h5 className="item__main__rating__reviews__number text-l ">
                  {reviews} 5{' '}
                </h5>
                Reviews{' '}
              </Link>
            </div>
          </div>
          <div className="item__main__description">
            <h5 className="text-m-s">Description</h5>
            <p className="text-l">{store?.description}</p>
          </div>
          <div className="item__main__bottom">
            <Gallery title="Products" items={storeProducts} target={'product'} />
            {/*<Gallery
              title="Categories"
              items={storeCategories}
              handleClick={handleOpenCategory}
            />*/}
          </div>
        </div>
      </section>
    </>
  )
}

export default ShowStore

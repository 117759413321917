import { actionsEnumCategories } from '../../../constants';
import { getCategorySelectOptionsAPI } from '../../../api';

const fetchCategorySelectOptionsRequest = () => ({
  type: actionsEnumCategories.FETCH_CATEGORY_SELECT_OPTIONS_REQUEST,
});

const fetchCategorySelectOptionsReceived = (categorySelectOptions) => ({
  type: actionsEnumCategories.FETCH_CATEGORY_SELECT_OPTIONS_RECEIVED,
  categorySelectOptions,
});

export const fetchCategorySelectOptions = () => (dispatch) => {
  dispatch(fetchCategorySelectOptionsRequest());
  getCategorySelectOptionsAPI().then((categorySelectOptions) => {
    dispatch(fetchCategorySelectOptionsReceived(categorySelectOptions));
    return categorySelectOptions;
  });
};

import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import {Rating} from './../../../components'
import {classStatus} from '../../../utils'
import {
  fetchOrder,
  acceptOrder,
  rejectOrder,
  readNewOrder,
  createChatRoom,
  closeOrder,
  resumeOrder,
} from '../../../redux'
import {ShowOrder} from './ShowOrder'
import useAuth from '../../../hooks/session/useAuth'

export const ShowOrderContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {id} = useParams()
  const {auth} = useAuth()
  const chatState = useSelector((state) => state.chatReducer)
  const orderState = useSelector((state) => state.orderReducer)
  const [order, setOrder] = useState(null)
  const [orderRequested, setOrderRequested] = useState(false)
  const [acceptRequested, setAcceptRequested] = useState(false)
  const [rejectRequested, setRejectRequested] = useState(false)
  const [closeRequested, setCloseRequested] = useState(false)
  const [resumeRequested, setResumeRequested] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [isLoadingReadOrder, setIsLoadingReadOrder] = useState(false)
  const [isLoadingReadOrderSuccesfully, setIsLoadingReadOrderSuccesfully] =
    useState(false)
  const [chatRoomRequested, setChatRoomRequested] = useState(false)
  const [chatRoomSuccess, setChatRoomSuccess] = useState(false)
  const [classDeliveryStatus, setClassDeliveryStatus] = useState(null)
  const [deliveryStatus, setDeliveryStatus] = useState(null)
  const [chatStatus, setChatStatus] = useState(null)
  const [ratingModal, setRatingModal] = useState(false)
  const [msg, setMsg] = useState(null)

  const handleReadNewOrder = (order) => {
    if (!order?.isRead && !isLoadingReadOrder && !isLoadingReadOrderSuccesfully) {
      const requestParameters = {
        auth: auth,
        orderId: order.id,
      }
      dispatch(readNewOrder(requestParameters))
      setIsLoadingReadOrder(true)
    }
  }

  useEffect(() => {
    if (chatState?.chatRoom?.id && chatRoomRequested && !chatRoomSuccess) {
      setChatRoomSuccess(true)
      setChatRoomRequested(false)
      setTimeout(() => {
        navigate(`/chatRoom/${chatState?.chatRoom?.id}`)
      }, 2500)
    }
  }, [
    setChatRoomSuccess,
    setChatRoomRequested,
    chatState?.chatRoom?.id,
    chatRoomRequested,
  ])

  useEffect(() => {
    if (!auth) {
      console.log('No auth')
    }
  }, [auth])

  useEffect(() => {
    if (orderState?.order?.length === 0) {
      setMsg(orderState?.msg)
      setOrder(null)
      setTimeout(() => {
        navigate('/shop')
      }, 2500)
    }

    if (id && !order) {
      if (order?.id !== id && orderState?.order?.id) {
        setOrder(orderState?.order)
        handleReadNewOrder(orderState?.order)
      }
    }

    if (orderState?.orders?.length > 0 && id !== order?.id) {
      const order = orderState?.orders.find((p) => p.id === id)
      setMsg(orderState?.msg)
      setOrder(order)
      handleReadNewOrder(order)
      setDeliveryStatus(order?.deliveryStatus)
      setClassDeliveryStatus(classStatus(order))
    }

    if (
      (acceptRequested || rejectRequested || closeRequested || resumeRequested) &&
      !requestSuccess
    ) {
      const orderUpdated = orderState?.order
      setOrder(orderUpdated)
      handleReadNewOrder(orderUpdated)
      setDeliveryStatus(orderUpdated?.deliveryStatus)
      setClassDeliveryStatus(classStatus(orderUpdated))
      setRejectRequested(false)
      setAcceptRequested(false)
      setResumeRequested(false)
      setRequestSuccess(true)
    }
  }, [orderState])

  if (!order) {
    let orderFound = null
    if (orderState?.orders?.length) {
      orderFound = orderState?.orders.find((order) => order?.id === id)
      if (orderFound) {
        setDeliveryStatus(orderFound?.deliveryStatus)
        setClassDeliveryStatus(classStatus(orderFound))
        setOrder(orderFound)
        return handleReadNewOrder(orderFound)
      }
    }
    if (!orderRequested && !orderFound) {
      const requestParameters = {
        auth: auth,
        orderId: id,
      }
      dispatch(fetchOrder(requestParameters))
      setOrderRequested(true)
    }
  }

  const handleAcceptOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(acceptOrder(requestParameters))
    setRequestSuccess(false)
    setAcceptRequested(true)
  }

  const handleRejectOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(rejectOrder(requestParameters))
    setRequestSuccess(false)
    setRejectRequested(true)
  }

  const handleCloseOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(closeOrder(requestParameters))
    setRequestSuccess(false)
    setCloseRequested(true)
    setRatingModal(true)
  }

  const handleResumeOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(resumeOrder(requestParameters))
    setRequestSuccess(false)
    setResumeRequested(true)
  }

  const handleCancelOrder = () => {
    navigate('/show-orders')
  }

  const handleChat = (e) => {
    e.preventDefault()
    const {
      id,
      buyerId,
      buyerName,
      buyerPicture,
      buyerPictureSignedUrl,
      storeId,
      storeName,
      storePicture,
      storePictureSignedUrl,
    } = order

    const requestParameters = {
      origin: 'order',
      itemId: id,
      itemName: `${storeName} - ${buyerName} ${id}`,
      userId: buyerId,
      userName: buyerName,
      userPicture: buyerPicture,
      userPictureSignedUrl: buyerPictureSignedUrl,
      receiverId: storeId,
      receiverName: storeName,
      receiverPicture: storePicture,
      receiverPictureSignedUrl: storePictureSignedUrl,
    }
    dispatch(createChatRoom(requestParameters))
    setChatRoomRequested(true)
  }

  const props = {
    handleAcceptOrder,
    handleCancelOrder,
    handleRejectOrder,
    handleCloseOrder,
    handleResumeOrder,
    handleChat,
    auth,
    order,
    deliveryStatus,
    chatStatus,
    classDeliveryStatus,
    ratingModal,
  }

  return <ShowOrder parameters={props} />
}

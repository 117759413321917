import {ButtonRoundSmall} from '../common'
import {IconNote} from '../../icons'

export const ButtonAddNote = (props) => {
  const {handleClick} = props
  return (
    <ButtonRoundSmall
      className="button-add-note"
      icon={IconNote}
      type="button"
      placeholder={null}
      onClick={handleClick}
    />
  )
}

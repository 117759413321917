import {useParams} from 'react-router-dom'
import {useCallback, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {ChatRoom} from './ChatRoom'
import {
  fetchMostRecentMessages,
  sendMessage,
  joinChatRoom,
  readNewMessage,
} from '../../../redux'
import useAuth from '../../../hooks/session/useAuth'
import {startSocketOn} from '../../../utils/socket'

export const ChatRoomContainer = () => {
  const dispatch = useDispatch()
  const {id} = useParams()
  const {auth} = useAuth()
  const chatState = useSelector((state) => state.chatReducer)
  const [message, setMessage] = useState('')
  const [lastReceivedMessage, setLastReceivedMessage] = useState(null)
  const [isLoadingReadMessage, setIsLoadingReadMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)

  const chatRoom = chatState?.chatRoom
  const isConnected = id && id === chatRoom?.id
  const messages = chatState?.messages ?? []

  useEffect(() => {
    if (chatRoom.id) {
      startSocketOn()
    }
  }, [chatRoom])

  const handleReadNewMessagesAndRoom = useCallback(
    function handleReadNewMessagesAndRoomImpl() {
      const newMessages = chatState?.newMessages
      if (newMessages?.length && auth && auth.userInfo) {
        const found = newMessages.find((message) => message.chatRoomId === id)
        if (found) {
          setLastReceivedMessage(newMessages[newMessages.length - 1])
          return handleReadNewMessage(auth, id)
        }
      }
    },
    [auth, chatState, dispatch, id, isLoadingReadMessage],
  )

  const handleReadNewMessage = useCallback(
    (auth, id) => {
      const requestParameters = {
        userId: auth.userInfo.storeId ? auth.userInfo.storeId : auth.userInfo.id,
        chatRoomId: id,
      }
      setIsLoadingReadMessage(true)
      dispatch(readNewMessage(requestParameters))
    },
    [dispatch, readNewMessage],
  )

  const handleMessageChange = useCallback(
    (message) => {
      setMessage(message)
    },
    [setMessage],
  )

  const handleSendMessage = useCallback(() => {
    if (message && chatRoom) {
      let authorId = chatRoom.userId
      let authorName = chatRoom.userName
      let receiverId = chatRoom.receiverId
      let receiverName = chatRoom.receiverName

      if (
        chatRoom.receiverName === auth?.userInfo?.firstName ||
        chatRoom.receiverName === auth?.userInfo?.storeName
      ) {
        authorId = chatRoom.receiverId
        authorName = chatRoom.receiverName
        receiverId = chatRoom.userId
        receiverName = chatRoom.userName
      }
      const requestParameters = {
        chatRoomId: id,
        chatRoomName: chatRoom.chatRoomName,
        messageText: message,
        authorId,
        authorName,
        receiverId,
        receiverName,
      }
      setIsLoading(true)
      dispatch(sendMessage(requestParameters))
      setMessage('')
    }
  }, [auth, chatRoom, dispatch, id, message, setIsLoading])

  useEffect(
    function joinRoomOnLoadComponent() {
      if (id && chatRoom?.id !== id && !isLoading) {
        dispatch(joinChatRoom(id))
        setIsLoading(true)
      }
    },
    [id, chatRoom, dispatch, isLoading, setIsLoading],
  )

  useEffect(
    function loadMessagesAfterConnect() {
      const hasMessages = messages?.length ? true : null
      let wrongRoomMessages = null
      if (hasMessages) {
        wrongRoomMessages = messages[0].chatRoomId !== id
      }
      if (
        isConnected &&
        (!hasMessages || wrongRoomMessages) &&
        !isLoadingMessages &&
        id
      ) {
        dispatch(fetchMostRecentMessages(id))
        setIsLoadingMessages(true)
      }
    },
    [dispatch, isConnected, isLoadingMessages, messages, setIsLoadingMessages],
  )

  useEffect(
    function readMessagesSinceTrackedLastReceived() {
      const readInterval = setInterval(() => {
        if (messages?.length) {
          handleReadNewMessagesAndRoom()
        }
      }, 50)
      return () => {
        clearInterval(readInterval)
      }
    },
    [handleReadNewMessagesAndRoom, isLoadingReadMessage, isLoadingMessages, messages],
  )

  const parameters = {
    handleMessageChange,
    handleSendMessage,
    isConnected,
    messages,
    chatRoom,
    message,
    auth,
  }
  return <ChatRoom parameters={parameters} />
}

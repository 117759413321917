import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonNotifications,
  ButtonBack,
  ButtonLarge,
  ButtonAdd,
  InputLarge,
  InputTextArea,
} from '../../../components'

export const UpdateOrder = (props) => {
  const {
    handleSubmit,
    buyerPictureSignedUrl,
    buyerName,
    buyerId,
    storeId,
    storeName,
    buyerLocation,
    buyerAddress,
    storePictureSignedUrl,
    storeAddress,
    storeLocation,
    subTotal,
    orderTotal,
    shipping,
    cart,
    fields,
    form,
    errors,
    bannerMessage,
  } = props.parameters
  const t = useTranslate()

  return (
    <section className="order">
      {/*{errors ? <h6 className="error">Error: {errors}</h6> : null}*/}
      {bannerMessage ? <h6 className="banner-message">{bannerMessage}</h6> : null}
      <div className="order__top">
        <div className="order__top__left">
          <ButtonBack />
          <h1 className="order__top__left__user-name h4-bold ">{t('user_name')}</h1>
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{t('order')}</h2>
      </div>
      <div>
        <div className="order__user">
          <img className="order_user_img img-user-small" src={buyerPictureSignedUrl} />
          <div className="order__user__left">
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s ">
                {t('buyer_name')}
              </h4>
              <h3 name="buyerName" className="order__user__left__field__text text-s ">
                {buyerName}
              </h3>
            </div>
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('buyer_id')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{buyerId}</h3>
            </div>
          </div>

          <div className="order__user__right">
            {/*<div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">Buyer address:</h4>
              <h3 className="order__user__left__field__text text-s ">{buyerAddress}</h3>
            </div>*/}

            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('buyer_location')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{buyerLocation}</h3>
            </div>
          </div>
        </div>

        <div className="order__user">
          <img className="order_user_img img-user-small" src={storePictureSignedUrl} />
          <div className="order__user__left">
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_name')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{storeName}</h3>
            </div>
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_id')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{storeId}</h3>
            </div>
          </div>
          <div className="order__user__right">
            {/*<div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">Store address</h4>
              <h3 className="order__user__left__field__text text-s ">{storeAddress}</h3>
            </div>*/}
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_location')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{storeLocation}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="order__details">
        <fieldset className="order__details__radio flex-column">
          <div>
            <input
              type="radio"
              id="delivery"
              name="delivery"
              value={fields.delivery}
              checked
              onChange={form.handleChangeEvent}
            />
            <label className="order__details__label text-m-s" htmlFor="delivery">
              {t('delivery')}
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="pickup"
              name="pickup"
              value={fields.pickup}
              onChange={form.handleChangeEvent}
            />
            <label className="order__details__label text-m-s" htmlFor="pickup">
              {t('pickup')}
            </label>
          </div>
        </fieldset>
        <div className="order__details__fields">
          <InputLarge
            textHelper={t('delivery_date_range_start')}
            id="deliveryDateRangeStart"
            name="deliveryDateRangeStart"
            value={fields.deliveryDateRangeStart}
            type="date"
            handleChange={form.handleChangeEvent}
          />
          <InputLarge
            textHelper={t('delivery_date_range_end')}
            id="deliveryDateRangeEnd"
            name="deliveryDateRangeEnd"
            value={fields.deliveryDateRangeEnd}
            type="date"
            handleChange={form.handleChangeEvent}
          />
          <InputLarge
            textHelper={t('delivery_date_time_start')}
            id="deliveryTimeRangeStart"
            name="deliveryTimeRangeStart"
            value={fields.deliveryTimeRangeStart}
            type="time"
            handleChange={form.handleChangeEvent}
          />
          <InputLarge
            textHelper={t('delivery_date_time_end')}
            id="deliveryTimeRangeEnd"
            name="deliveryTimeRangeEnd"
            value={fields.deliveryTimeRangeEnd}
            type="time"
            handleChange={form.handleChangeEvent}
          />
          {/*
          <InputLarge
            textHelper="Delivery address"
            id="deliveryAddress"
            name="deliveryAddress"
            value={fields.deliveryAddress}
            handleChange={form.handleChangeEvent}
          />*/}
          <div className="order__details__fields__location ">
            <InputLarge
              textHelper={t('delivery_location')}
              id="deliveryLocation"
              name="deliveryLocation"
              value={fields.deliveryLocation}
              handleChange={form.handleChangeEvent}
            />
            {/*<div className="order__details__fields__location__button">
              <h4 className="order__details_field__location__label text-m-s">
                Find in Map
              </h4>
              <ButtonAdd handleClick={form.handleChangeEvent} />
            </div>*/}
          </div>

          <InputTextArea
            textHelper={t('delivery_note')}
            id="deliveryNote"
            name="deliveryNote"
            value={fields.deliveryNote}
            handleChange={form.handleChangeEvent}
          />
        </div>
        <div className="order__cart__bottom">
          <h3 className="text-m-l">{t('product')}</h3>
          <div className="order__cart__bottom__details">
            <div className="order__cart__bottom__details__products">
              <h4 className="text-m-s">{t('product')}</h4>
              <h4 className="text-m-s">{t('price')}</h4>
              <h4 className="text-m-s">{t('quantity')}</h4>
              <h4 className="text-m-s">Total</h4>
            </div>
            {cart?.products
              ? cart.products.map((product) => (
                  <div className="order__cart__bottom__details__products">
                    <h4 className="text-l">{product.name}</h4>
                    <div className="order__cart__bottom__details__products__price">
                      <h4 className="text-l">${product.price}</h4>
                      <p className="text-xs">/{product.measurementUnit}</p>
                    </div>

                    <h4 className="text-l">x{product.quantity} </h4>
                    <h4 className="text-l">${product.total}</h4>
                  </div>
                ))
              : null}
            <div className="order__cart__bottom__details__subtotal">
              <h4 className="text-l">Subtotal</h4>
              <h4 className="text-l">${subTotal}</h4>
            </div>
            <div className="order__cart__bottom__details__shipping">
              <h4 className="text-l">{t('shipping')}</h4>
              <h4 className="text-l">${shipping}</h4>
            </div>
            <div className="order__cart__bottom__details__total">
              <h4 className="text-m-l">Total</h4>
              <h4 className="text-m-l">${orderTotal}</h4>
            </div>
            <ButtonLarge type="submit" text={'order_now'} handleClick={handleSubmit} />
          </div>
        </div>
      </div>
    </section>
  )
}

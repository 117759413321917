import {useTranslate} from 'react-redux-multilingual'
import {useNavigate} from 'react-router-dom'

export const Unauthorized = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const t = useTranslate()

  return (
    <section>
      <h1>{t('unauthorized')}</h1>
      <br />
      <p>{t('no_access')}</p>
      <div className="flexGrow">
        <button type="button" onClick={goBack}>
          {t('go_back')}
        </button>
      </div>
    </section>
  )
}

import { StatusPilot } from "../../../../components"

export const Rooms = (props) => {
    const { data, handleOpenChatRoom } = props
    return data.length ? (
        <>
            <div className="chat-rooms">
                <h3 className="chat-rooms__title">Rooms</h3>
                <ul className="chat-rooms__list">
                    {data?.map((room, index) => (
                        <li onClick={handleOpenChatRoom} id={room.id} key={index} className="chat-rooms__list__chat-room">
                            <StatusPilot status={room.isRead} />
                            <h6> {room.chatRoomName} </h6>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    ) : (
        <></>
    )
}

import {actionsEnumSearch} from '../../constants'

const initialState = {
  searchQuery: '',
  storeSearchResult: [],
  productSearchResult: [],
  msg: null,
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumSearch.FETCH_SEARCH_REQUEST:
      return {
        ...state,
        searchQuery: action.searchQuery,
      }

    case actionsEnumSearch.FETCH_SEARCH_RECEIVED:
      return {
        ...state,
        storeSearchResult: action.result?.storeSearchResult?.length
          ? action.result.storeSearchResult
          : [],
        productSearchResult: action.result?.productSearchResult?.length
          ? action.result.productSearchResult
          : [],
      }

    case actionsEnumSearch.SEARCH_REQUEST_CLEAR:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
export default searchReducer

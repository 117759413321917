import {IconBell} from '../../icons/IconBell'

export const ButtonSubscribe = (props) => {
  const {text, handleClick, disabled} = props

  return (
    <div className="button-subscribe-container">
      <button className="button button-subscribe" type={'button'} onClick={handleClick}>
        {text}
        <IconBell />
      </button>
    </div>
  )
}

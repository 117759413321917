import {Routes, Route} from 'react-router-dom'
import {Register, Login, RequireAuth, Unauthorized, UpdateUser} from '../pages/session'
import {
  DashboardPublicPage,
  DashboardBuyerPage,
  DashboardStorePage,
  CreateProductPage,
  ShowProductPage,
  UpdateProductPage,
  ShowStoresPage,
  ShowStorePage,
  UpdateStorePage,
  CartPage,
  CreateOrderPage,
  UpdateOrderPage,
  EditBasketOrderPage,
  ShowOrderPage,
  ShowOrdersPage,
  ChatRoomsPage,
  ChatRoomPage,
  ShowSearchResultPage,
  NotificationsPage,
  CreateBasketPage,
} from '../pages'
import {ROLES} from '../constants'

export const RoutesPage = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardPublicPage />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="update-user" element={<UpdateUser />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="product/:id" element={<ShowProductPage />} />
      <Route path="store/:id" element={<ShowStorePage />} />
      <Route path="show-stores" element={<ShowStoresPage />} />
      <Route path="cart" element={<CartPage />} />
      <Route path="search-result" element={<ShowSearchResultPage />} />
      <Route path="create-basket" element={<CreateBasketPage />} />

      <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
        <Route path="shop" element={<DashboardBuyerPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor]} />}>
        <Route path="my-shop" element={<DashboardStorePage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor]} />}>
        <Route path="create-product" element={<CreateProductPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor]} />}>
        <Route path="update-product/:id" element={<UpdateProductPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor, ROLES.User]} />}>
        <Route path="update-order/:id" element={<UpdateOrderPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor]} />}>
        <Route path="edit-basket-order/:id" element={<EditBasketOrderPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor, ROLES.User]} />}>
        <Route path="show-order/:id" element={<ShowOrderPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor, ROLES.User]} />}>
        <Route path="show-orders" element={<ShowOrdersPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
        <Route path="create-order" element={<CreateOrderPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor]} />}>
        <Route path="update-store/:id" element={<UpdateStorePage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
        <Route path="dashboard-store/:id" element={<ShowStorePage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor, ROLES.User]} />}>
        <Route path="chatRooms" element={<ChatRoomsPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor, ROLES.User]} />}>
        <Route path="chatRoom/:id" element={<ChatRoomPage />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.Vendor, ROLES.User]} />}>
        <Route path="notifications" element={<NotificationsPage />} />
      </Route>
    </Routes>
  )
}

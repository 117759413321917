import axios from './axios'
import {URL_STORES} from '../urls'
import {formatIds} from '../utils/formatIds'

const appendToFormData = (storeParameters) => {
  const {fields, file} = storeParameters
  const {
    picture,
    pictureSignedUrl,
    userName,
    userId,
    id,
    name,
    description,
    location,
    address,
    type,
  } = fields

  const formData = new FormData()
  formData.append('storePicture', file)
  formData.append('picture', picture)
  formData.append('pictureSignedUrl', pictureSignedUrl)
  formData.append('userName', userName)
  formData.append('userId', userId)
  formData.append('id', id)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('location', location)
  formData.append('address', '')
  formData.append('type', type)
  return formData
}

const getStoresRequestAPI = async (auth) => {
  try {
    const response = await axios.post(`${URL_STORES}/`, null, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    const storesWithId = stores.map((store) => {
      const {_id, ...rest} = store
      return {id: _id, ...rest}
    })
    return storesWithId
  } catch (err) {
    return err
  }
}

const getStoreBasketRequestAPI = async () => {
  try {
    const response = await axios.get(`${URL_STORES}/basketStore`)
    const storeBasket = formatIds([response.data?.store])[0]
    return storeBasket
  } catch (err) {
    return err
  }
}

const getStoreRequestAPI = async (storeId) => {
  try {
    const response = await axios.get(`${URL_STORES}/${storeId}`)
    const store = formatIds([response.data?.store])[0]
    const productsWithId = formatIds(store.products)
    store.products = productsWithId
    return store
  } catch (err) {
    return err
  }
}

const createStoreAPI = async (requestParameters) => {
  const {storeParameters, auth} = requestParameters
  const formData = appendToFormData(storeParameters)
  try {
    const response = await axios.post(URL_STORES, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    const store = response.data.store
    store.id = store._id
    delete store['_id']
    return store
  } catch (err) {
    return err.response.data
  }
}

const updateStoreAPI = async (requestParameters) => {
  const {storeParameters, auth} = requestParameters
  const formData = appendToFormData(storeParameters)

  try {
    const response = await axios.patch(
      `${URL_STORES}/${auth?.userInfo?.storeId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth?.accessToken}`,
          User: `${auth?.userInfo?.id}`,
        },
        withCredentials: true,
      },
    )
    const store = response.data.store
    store.id = store._id
    delete store['_id']
    return store
  } catch (err) {
    return err
  }
}

const deleteStoreAPI = async (orderId, auth) => {
  try {
    const response = await axios.delete(`${URL_STORES}/${orderId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.msg
  } catch (err) {
    return err
  }
}

export {
  getStoresRequestAPI,
  getStoreRequestAPI,
  getStoreBasketRequestAPI,
  updateStoreAPI,
  createStoreAPI,
  deleteStoreAPI,
}

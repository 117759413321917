import { actionsEnumCategories } from '../../constants';

const initialState = {
  category: {
    name: "",
    description: "",
    sku: 0,
    picture: "",
    pictureSignedUrl: "",
    count: 0,
    parentName: "",
    parentId: {},
    children: {},
    timestamps: true
  },
  categoriesByStore: [],
  categories: [],
  categorySelectOptions: [],
  categoryNameSuggestion: "",
  msg: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumCategories.FETCH_CATEGORIES_RECEIVED:
      if (action.categories.message) {
        return {
          ...state,
          msg: action.categories.message,
        }
      }
      return {
        ...state,
        categories: action.categories,
        msg: null
      }

    case actionsEnumCategories.FETCH_CATEGORY_RECEIVED:
      if (action.category) {
        return {
          ...state,
          msg: action.category.message,
        }
      }
      return {
        ...state,
        category: action.category,
        msg: null
      }

    case actionsEnumCategories.CREATE_CATEGORY_RECEIVED:
      if (action.category.err) {
        return {
          ...state,
          msg: action.category.err,
        };
      }
      return {
        ...state,
        category: action.category,
        msg: null
      };

    case actionsEnumCategories.UPDATE_CATEGORY_RECEIVED:
      if (action.category.err) {
        return {
          ...state,
          msg: action.category.err,
        };
      }
      return {
        ...state,
        category: action.category,
        msg: null
      };

    case actionsEnumCategories.DELETE_CATEGORY_RECEIVED:
      return {
        ...state,
        category: [],
        msg: action.msg,
      };

    case actionsEnumCategories.FETCH_CATEGORY_SELECT_OPTIONS_RECEIVED:
      if (action.categorySelectOptions.message) {
        return {
          ...state,
          categorySelectOptions: [],
          msg: action.categorySelectOptions.message
        };
      }

      return {
        ...state,
        categorySelectOptions: action.categorySelectOptions,
        msg: action.msg,
      };

    case actionsEnumCategories.FETCH_CATEGORIES_BY_STORE_RECEIVED:
      return {
        ...state,
        categoriesByStore: action.categories,
        msg: null
      };

    case actionsEnumCategories.FETCH_CATEGORY_NAME_SUGGESTION_RECEIVED:

      return {
        ...state,
        categorieNameSuggestion: action.category ? action.category : "",
        msg: null
      };

    default:
      return state;
  }
};
export default categoryReducer;

import React from 'react'

export const IconFlagEn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 1.2 1.2"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse">
          <use width="100%" height="100%" x="0" y="0" xlinkHref="#g5308"></use>
        </clipPath>
        <clipPath id="clipPath5316" clipPathUnits="userSpaceOnUse">
          <circle
            cx="24.873"
            cy="15"
            r="15"
            fill="#ff6464"
            fillOpacity="0.5"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="4"
            strokeOpacity="0.98"
            strokeWidth="0.508"
            opacity="1"
            paintOrder="markers stroke fill"
          ></circle>
        </clipPath>
      </defs>
      <clipPath id="t">
        <path d="M25 15h25v15zm0 0v15H0zm0 0H0V0zm0 0V0h25z"></path>
      </clipPath>
      <g clipPath="url(#clipPath5316)" transform="translate(-.395) scale(.04)">
        <g id="g5308">
          <path fill="#012169" d="M0 0v30h50V0z"></path>
          <path stroke="#fff" strokeWidth="6" d="M0 0l50 30m0-30L0 30"></path>
          <path
            stroke="#c8102e"
            strokeWidth="4"
            d="M0 0l50 30m0-30L0 30"
            clipPath="url(#t)"
          ></path>
          <path
            fill="#c8102e"
            stroke="#fff"
            strokeWidth="2"
            d="M-1 11h22V-1h8v12h22v8H29v12h-8V19H-1z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

import axios from './axios'
import {URL_PRODUCTS, URL_PRODUCTS_UPDATE, URL_PRODUCTS_BY_STORE} from '../urls'

/**
 *
 * @param {/products/filter_by_vendor/:category/:vendor/:index/:limit} storeId
 * @returns
 */
const getProductsByStoreRequestAPI = async (props) => {
  const {category, storeId, indexStart, indexEnd} = props
  try {
    const response = await axios.get(
      `${URL_PRODUCTS_BY_STORE}/${category}/${storeId}/${indexStart}/${indexEnd}`,
    )
    const {products} = response.data
    const productsWithId = products?.map((product) => {
      const {_id, ...rest} = product
      return {id: _id, ...rest}
    })
    return productsWithId
  } catch (err) {
    return err
  }
}

const getMyProductsRequestAPI = async (props) => {
  const {category, storeId, indexStart, indexEnd} = props
  try {
    const response = await axios.get(
      `${URL_PRODUCTS_BY_STORE}/${category}/${storeId}/${indexStart}/${indexEnd}`,
    )
    const {products} = response.data
    const myProducts = products?.map((product) => {
      const {_id, ...rest} = product
      return {id: _id, ...rest}
    })
    return myProducts
  } catch (err) {
    return err
  }
}
const getProductsByCategoryAndIndexRequestAPI = async (props) => {
  const {category, indexStart, indexEnd} = props
  try {
    const response = await axios.get(
      `${URL_PRODUCTS}/${category}/${indexStart}/${indexEnd}`,
    )
    const {filteredProducts} = response.data
    const productsWithId = filteredProducts?.map((product) => {
      const {_id, ...rest} = product
      return {id: _id, ...rest}
    })
    return productsWithId
  } catch (err) {
    return err
  }
}

const getProductsRequestAPI = async () => {
  try {
    const response = await axios.get(URL_PRODUCTS)
    const {products} = response.data
    const productsWithId = products.map((product) => {
      const {_id, ...rest} = product
      return {id: _id, ...rest}
    })
    return productsWithId
  } catch (err) {
    return err
  }
}

const getProductRequestAPI = async (productId) => {
  try {
    const response = await axios.get(`${URL_PRODUCTS}/${productId}`)
    const product = response.data.product
    product.id = product._id
    delete product['_id']
    return product
  } catch (err) {
    return err
  }
}

const deleteProductAPI = async (productId, auth) => {
  try {
    const response = await axios.delete(`${URL_PRODUCTS}/${productId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.msg
  } catch (err) {
    return err
  }
}

const createProductAPI = async (requestParameters) => {
  const {productParameters, auth} = requestParameters
  const {fields, file} = productParameters
  const {
    name,
    description,
    price,
    categories,
    categoryId,
    categoryName,
    categoryParentName,
    categoryPath,
    storeId,
    storeName,
    count,
    measurementUnit,
    availableAt,
    picture,
    pictureSignedUrl,
  } = fields

  const formData = new FormData()
  formData.append('productPicture', file)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('price', price)
  formData.append('categoryId', categoryId)
  formData.append('categoryName', categoryName)
  formData.append('categoryParentName', categoryParentName)
  formData.append('categoryPath', categoryPath)
  formData.append('categories', categories)
  formData.append('storeId', storeId)
  formData.append('storeName', storeName)
  formData.append('count', count)
  formData.append('measurementUnit', measurementUnit)
  formData.append('rating', 5)
  formData.append('availableAt', availableAt)
  formData.append('picture', picture)
  formData.append('pictureSignedUrl', '')

  try {
    const response = await axios.post(URL_PRODUCTS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    const product = response.data.product
    product.id = product._id
    delete product['_id']
    const categories = product.categories

    if (categories.length === 1) {
      const arr = categories[0].split('/')
      product.categories = arr
    }
    return product
  } catch (err) {
    return err
  }
}

const updateProductAPI = async (requestParameters) => {
  const {productParameters, auth} = requestParameters
  const {
    id,
    name,
    description,
    price,
    categoryId,
    categoryName,
    categoryParentName,
    categoryPath,
    storeId,
    storeName,
    count,
    reStock,
    measurementUnit,
    rating,
    availableAt,
    picture,
    pictureSignedUrl,
  } = productParameters.fields

  const formData = new FormData()
  formData.append('_id', id)
  formData.append('productPicture', productParameters?.file)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('price', price)
  formData.append('categoryId', categoryId)
  formData.append('categoryName', categoryName)
  formData.append('categoryParentName', categoryParentName)
  formData.append('categoryPath', categoryPath)
  formData.append('storeId', storeId)
  formData.append('storeName', storeName)
  formData.append('itemsCount', count)
  formData.append('measurementUnit', measurementUnit)
  formData.append('rating', rating)
  formData.append('availableAt', availableAt ? availableAt : '')
  formData.append('picture', picture)
  formData.append('pictureSignedUrl', pictureSignedUrl)

  try {
    const response = await axios.post(`${URL_PRODUCTS_UPDATE}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    const product = response.data.product
    product.id = product._id
    delete product['_id']
    return product
  } catch (err) {
    return err
  }
}

export {
  getProductsRequestAPI,
  getMyProductsRequestAPI,
  getProductRequestAPI,
  createProductAPI,
  updateProductAPI,
  deleteProductAPI,
  getProductsByStoreRequestAPI,
  getProductsByCategoryAndIndexRequestAPI,
}

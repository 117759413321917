import {useRef, useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from '../../api/axios'
import {USER_REGEX, REGISTER_URL} from '../../constants/sessionConsts'
import {ButtonBack, ButtonLarge, ButtonAdd, Input, InputTextArea} from '../../components'
import useAuth from '../../hooks/session/useAuth'
import {setToLocalStorage} from '../../utils/localStorage'
import {useTranslate} from 'react-redux-multilingual'
import {resizeFile} from '../../utils'

const UpdateUser = () => {
  const {auth, setAuth} = useAuth()
  const userInfo = auth?.userInfo
  const errRef = useRef()
  const navigate = useNavigate()
  const [firstName, setName] = useState(userInfo?.firstName)
  const [validName, setValidName] = useState(false)
  const [lastName, setLastName] = useState(userInfo?.lastName)
  const [location, setLocation] = useState(userInfo?.location)
  const [isVendor, setIsVendor] = useState(userInfo ? userInfo?.isVendor : false)
  const [intro, setIntro] = useState(userInfo?.intro)
  const [mobile, setMobile] = useState(userInfo?.mobile)
  const [address, setAddress] = useState(userInfo?.address)
  const [email, setEmail] = useState(userInfo?.email)
  const [file, setFile] = useState([])
  const [picture, setPicture] = useState(userInfo?.picture)
  const [pictureSignedUrl, setPictureSignedUrl] = useState(userInfo?.pictureSignedUrl)
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const t = useTranslate()

  useEffect(() => {
    if (!auth?.userInfo) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(firstName))
  }, [firstName])

  useEffect(() => {
    setValidName(USER_REGEX.test(lastName))
  }, [lastName])

  useEffect(() => {
    setErrMsg('')
  }, [firstName, lastName, location, isVendor, intro, mobile, address, email])

  const handleLogout = () => {
    setAuth({})
    localStorage.removeItem('auth')
    navigate('/login')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const removeQuotes = (s) => {
      s = s.replaceAll('"', '')
      s = s.replaceAll('/', '')
      s = s.replaceAll(' ', '')
      return s
    }
    const formData = new FormData()
    formData.append('userPicture', file)
    formData.append('firstname', firstName)
    formData.append('lastName', lastName)
    formData.append('intro', intro)
    formData.append('mobile', mobile)
    formData.append('address', address)
    formData.append('location', removeQuotes(location))
    formData.append('email', email)
    formData.append('isVendor', isVendor)
    formData.append('picture', picture)
    formData.append('pictureSignedUrl', pictureSignedUrl)

    try {
      const response = await axios.patch(`users/${auth?.userInfo?.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth?.accessToken}`,
          User: `${auth?.userInfo?.id}`,
        },
        withCredentials: true,
      })

      const accessToken = response?.data?.accessToken
      const userInfo = response?.data?.user
      userInfo.id = userInfo?._id
      delete userInfo?._id
      const roles = userInfo?.roles
      const pwd = auth.pwd
      const user = userInfo?.firstName

      setAuth({
        ...auth,
      })

      setToLocalStorage('auth', {auth: {user, pwd, roles, accessToken, userInfo}})

      setName(userInfo?.firstName)
      setPictureSignedUrl(userInfo?.pictureSignedUrl)
      navigate('/', {replace: true})
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 409) {
        setErrMsg('Username Taken')
      } else {
        setErrMsg('Registration Failed')
      }
      errRef.current.append()
    }
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handleLastName = (e) => {
    setLastName(e.target.value)
  }

  const handleLocation = (e) => {
    setLocation(e.target.value)
  }

  const handleIntro = (e) => {
    setIntro(e.target.value)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleIsVendor = () => {
    setIsVendor(!isVendor)
  }

  const handleMobile = (e) => {
    setMobile(e.target.value)
  }

  const handleAddress = (e) => {
    setAddress(e.target.value)
  }

  const handleFile = (f) => {
    setFile(f)
  }

  const onChangePicture = async (e) => {
    if (e.target?.files.length) {
      if (e.target?.files[0]?.name) {
        const newFile = e.target.files[0]

        await resizeFile({file: newFile, handleFile})
        const reader = new FileReader()
        reader.readAsDataURL(newFile)

        reader.onload = () => {
          setPictureSignedUrl(reader.result)
        }
      }
    }
  }

  return (
    <>
      <section className="session">
        <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
          {errMsg}
        </p>
        <div className="background-circle" />
        <ButtonBack />
        <h1 className="title h1-bold">{t('update_user')}</h1>
        <form onSubmit={handleSubmit}>
          <div className="session__block__picture">
            <div className="session__block__picture__placeholder">
              <ButtonAdd
                type="file"
                name="userPicture"
                className="session__block__picture__add"
                onChange={(e) => onChangePicture(e)}
              />
              {pictureSignedUrl && <img className="img-user" src={pictureSignedUrl} />}
            </div>
            <input
              className="session__block__picture__input"
              type="file"
              name="userPicture"
              onChange={(e) => onChangePicture(e)}
            ></input>
          </div>

          <div className="session__block">
            <div className="session__block__left">
              <Input
                label={t('first_name')}
                type="text"
                id="firstName"
                handleChange={handleName}
                value={firstName}
              />
            </div>
            <div className="session__block__right">
              <Input
                label={t('last_name')}
                type="text"
                id="lastName"
                name="lastName"
                handleChange={handleLastName}
                value={lastName}
              />
            </div>
          </div>
          <div className="session__block">
            <div className="session__block__left">
              <Input
                label={t('location')}
                type="text"
                id="location"
                name="location"
                handleChange={handleLocation}
                value={location}
              />
            </div>
            <div className="session__block__right">
              <div className="checkbox">
                <label className="text-s" htmlFor="vendor">
                  {' '}
                  {t('are_you_vendor')}
                </label>
                <input
                  type="checkbox"
                  id="vendor"
                  name="vendor"
                  onChange={handleIsVendor}
                  checked={isVendor ? 'checked' : null}
                />
              </div>
            </div>
          </div>
          <div className="session__block">
            <div className="session__block__left">
              <Input
                label={t('mobile')}
                type="telephone"
                id="mobile"
                name="mobile"
                handleChange={handleMobile}
                value={mobile}
              />
            </div>
            <div className="session__block__right">
              <Input
                label="Email:"
                type="email"
                id="email"
                name="email"
                handleChange={handleEmail}
                value={email}
              />
            </div>
          </div>
          <div className="session__block">
            <InputTextArea
              label="Intro:"
              type="text"
              id="intro"
              name="intro"
              handleChange={handleIntro}
              value={intro}
            />
          </div>
          <ButtonLarge
            type="submit"
            text={t('update_user')}
            handleClick={handleSubmit}
            disabled={!firstName && !email}
          />
          <ButtonLarge
            type="button"
            text={t('logout')}
            handleClick={handleLogout}
            disabled={!firstName && !email}
          />
        </form>
      </section>
    </>
  )
}

export default UpdateUser

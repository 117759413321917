import {actionsEnumNotifications} from '../../../constants'
import {joinNotificationsAPI} from '../../../api'

const joinNotificationsRequest = () => ({
  type: actionsEnumNotifications.JOIN_NOTIFICATIONS_REQUEST,
})

export const joinNotificationsReceived = (message) => ({
  type: actionsEnumNotifications.JOIN_NOTIFICATIONS_COMPLETED,
  message,
})

export const joinNotifications = (requestParameters) => (dispatch) => {
  dispatch(joinNotificationsRequest())
  joinNotificationsAPI(requestParameters).then((message) => {
    dispatch(joinNotificationsReceived(message))
    return message
  })
}

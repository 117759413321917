import { useEffect, useState } from 'react'
import { ButtonAdd, ButtonRemove } from '../buttons'

export const ItemAdder = (props) => {
    const { totalItems, measurementUnit, handleQuantity, stock } = props
    const [items, setItems] = useState(totalItems)
    const [unit, setUnit] = useState(measurementUnit)
    const [preventDefault, setPreventDefault] = useState(false)
    //const [items, setItems] = useState(3)
    //const [unit, setUnit] = useState('lb')

    useEffect(() => {
        if (preventDefault) {
            handleQuantity(items)
        }
    }, [items])

    const handleAdd = () => {
        if (items < stock) {
            setItems(items + 1)
        }
        setPreventDefault(true)
    }

    const handleRemove = () => {
        if (items > 0) {
            setItems(items - 1)
        }
        setPreventDefault(true)
    }

    return (
        <div className='item-adder'>
            <ButtonRemove handleClick={handleRemove} />
            <div className='item-adder__total'>
                <p className='item-adder__total__count text-s'>{items}</p>
                <p className='item-adder__total__count text-s'>{unit}</p>

            </div>
            <ButtonAdd handleClick={handleAdd} />
        </div>
    )
}

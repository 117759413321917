import { actionsEnumOrder } from '../../../constants';
import { acceptOrderAPI } from '../../../api';

const acceptOrderRequest = () => ({
  type: actionsEnumOrder.ACCEPT_ORDER_REQUEST,
});

const acceptOrderReceived = (order) => ({
  type: actionsEnumOrder.ACCEPT_ORDER_RECEIVED,
  order,
});

export const acceptOrder = (requestParameters) => (dispatch) => {
  dispatch(acceptOrderRequest());
  acceptOrderAPI(requestParameters).then((order) => {
    dispatch(acceptOrderReceived(order));
    return order;
  });
};

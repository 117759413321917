import {NotificationsList} from './components'

export const Notifications = (props) => {
  const {userId, handleOpenNotification, notifications} = props.notificationsProps
  return (
    <div className="notifications">
      <NotificationsList
        userId={userId}
        data={notifications}
        handleOpenNotification={handleOpenNotification}
      />
    </div>
  )
}

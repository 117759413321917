import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useFormInputValidation} from 'react-form-input-validation'
import {UpdateOrder} from './UpdateOrder'
import useAuth from '../../../hooks/session/useAuth'
import {updateOrder, updateCart, deleteOrder} from '../../../redux/actions'

export const UpdateOrderContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartState = useSelector((state) => state.cartReducer)
  const orderState = useSelector((state) => state.orderReducer)
  const storeState = useSelector((state) => state.storeReducer)
  const {auth} = useAuth()
  const [cart, setCart] = useState(null)
  const [order, setOrder] = useState(null)
  const [store, setStore] = useState(null)
  const [cartId, setCartId] = useState('')
  const [buyerPicture, setBuyerPicture] = useState('')
  const [buyerPictureSignedUrl, setBuyerPictureSignedUrl] = useState('')
  const [buyerName, setBuyerName] = useState('')
  const [buyerId, setBuyerId] = useState('')
  const [buyerAddress, setBuyerAddress] = useState('')
  const [buyerLocation, setBuyerLocation] = useState('')
  const [storePicture, setStorePicture] = useState('')
  const [storePictureSignedUrl, setStorePictureSignedUrl] = useState('')
  const [storeName, setStoreName] = useState('')
  const [storeId, setStoreId] = useState('')
  const [storeAddress, setStoreAddress] = useState('')
  const [storeLocation, setStoreLocation] = useState('')
  const [orderItems, setOrderItems] = useState([{}])
  const [subTotal, setSubTotal] = useState(null)
  const [total, setTotal] = useState(null)
  const [shipping, setShipping] = useState(null)
  const [cartUpdateRequested, setCartUpdateRequested] = useState(false)
  const [cartUpdatedSuccessfully, setCartUpdatedSuccessfully] = useState(false)
  const [orderUpdateRequested, setOrderUpdateRequested] = useState(false)
  const [bannerMessage, setBannerMessage] = useState('')
  const [fields, errors, form] = useFormInputValidation(
    {
      pickup: false,
      delivery: true,
      deliveryStatus: 'Pending',
      deliveryAddress: null,
      deliveryLocation: null,
      deliveryNote: null,
      deliveryDateRangeStart: null,
      deliveryDateRangeEnd: null,
      deliveryTimeRangeStart: null,
      deliveryTimeRangeEnd: null,
    },
    {
      pickup: 'required',
      delivery: 'required',
      deliveryStatus: 'required',
      deliveryAddress: 'required',
      deliveryLocation: 'required',
      deliveryNote: 'required',
      deliveryDateRangeStart: 'required',
      deliveryDateRangeEnd: 'required',
      deliveryTimeRangeStart: 'required',
      deliveryTimeRangeEnd: 'required',
    },
  )

  const orderParameters = {
    fields: {
      ...fields,
      buyerId: buyerId,
      buyerName: buyerName,
      buyerAddress: buyerAddress,
      buyerLocation: buyerLocation,
      buyerPicture: buyerPicture,
      buyerPictureSignedUrl: buyerPictureSignedUrl,
      storeName: storeName,
      storeId: storeId,
      storeAddress: storeAddress,
      storeLocation: storeLocation,
      storePicture: storePicture,
      storePictureSignedUrl: storePictureSignedUrl,
      cartId: cartId,
      orderItems: orderItems,
      subTotal: subTotal,
      total: total,
      shipping: shipping,
      accepted: false,
      rejected: false,
    },
  }

  useEffect(() => {
    if (auth) {
      setBuyerPicture(auth?.userInfo?.picture)
      setBuyerPictureSignedUrl(auth?.userInfo?.pictureSignedUrl)
      setBuyerName(auth?.userInfo?.firstName)
      setBuyerId(auth?.userInfo?.id)
      setBuyerAddress(auth?.userInfo?.address)
      setBuyerLocation(auth?.userInfo?.location)
    }
  }, [auth])

  useEffect(() => {
    const newStore = storeState?.store
    if (newStore !== store) {
      setStorePicture(newStore?.picture)
      setStorePictureSignedUrl(newStore?.pictureSignedUrl)
      setStoreName(newStore?.name)
      setStoreId(newStore?.id)
      setStoreAddress(newStore?.address)
      setStoreLocation(newStore?.location)
      setStore(newStore)
    }
  }, [storeState])

  useEffect(() => {
    const newCart = cartState?.cart
    if (cartUpdateRequested && !cartUpdatedSuccessfully && newCart?.id) {
      setCartUpdatedSuccessfully(true)
      setCartUpdateRequested(false)
      setSubTotal(newCart?.subTotal)
      setTotal(newCart?.total)
      setShipping(newCart?.shipping)
      setOrderItems(newCart?.items)
      setCartId(newCart?.id)
      setCart(newCart)
    }

    if (newCart?.id !== cart?.id) {
      setSubTotal(newCart?.subTotal)
      setTotal(newCart?.total)
      setShipping(newCart?.shipping)
      setOrderItems(newCart?.items)
      setCartId(newCart?.id)
      setCart(newCart)
      if (newCart.items.length === 0) {
        dispatch(deleteOrder({auth, orderId: order?.id}))
        navigate('/cart')
      }
    }
    if (newCart?.userId && !cartUpdateRequested && !newCart?.id) {
      setCartUpdateRequested(true)
      setCartUpdatedSuccessfully(false)
      dispatch(updateCart({auth, cart: newCart}))
    }
  }, [cart])

  useEffect(() => {
    if (!order && orderState?.order !== order) {
      setOrder(orderState?.order)
    }
    if (orderUpdateRequested && orderState?.order?.id) {
      setOrderUpdateRequested(false)
      setBannerMessage('Order updated successfully')
      setTimeout(() => {
        navigate('/shop')
      }, 3000)
    }
  }, [orderState])

  useEffect(() => {
    if (cartState?.cart) {
      setCart(cartState?.cart)
      setOrderItems(cartState?.cart?.items)
    }
  }, [cartState])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = await form.validate(e)
    if (isValid && !orderUpdateRequested) {
      const requestParameters = {
        orderParameters,
        auth,
      }
      setOrderUpdateRequested(true)
      dispatch(updateOrder(requestParameters))
    }
  }

  const props = {
    handleSubmit,
    buyerPictureSignedUrl,
    buyerName,
    buyerId,
    storeId,
    storeName,
    buyerLocation,
    buyerAddress,
    storePictureSignedUrl,
    storeAddress,
    storeLocation,
    subTotal,
    total,
    shipping,
    cart,
    fields,
    form,
    errors,
    bannerMessage,
  }

  return <UpdateOrder parameters={props} />
}

import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonBack,
  InputLarge,
  ModalStoreEditItemInList,
  ModalConfirmation,
  ButtonExtraLarge,
} from '../../../components'
import {StoreEditBasketTable} from '../../../components/tables/StoreEditBasketTable'

export const EditBasketOrder = (props) => {
  const {
    basketItems,
    bannerMessage,
    auth,
    order,
    deliveryStatus,
    chatStatus,
    classDeliveryStatus,
    productName,
    productBrand,
    productCount,
    productMeasurementUnit,
    productPrice,
    productTotalPrice,
    productNote,
    modalOpen,
    isUpdating,
    productNameDisabled,
    productBrandDisabled,
    productCountDisabled,
    subTotal,
    total,
    shipping,
    ratingModal,
    handleAcceptOrder,
    handleCancelOrder,
    handleRejectOrder,
    handleCloseOrder,
    handleResumeOrder,
    handleUpdateProductInList,
    handleProductName,
    handleProductBrand,
    handleProductCount,
    handleProductMeasurementUnit,
    handleProductPrice,
    handleProductTotalPrice,
    handleEditProduct,
    handleSubTotal,
    handleShipping,
    handleProductNote,
    handleModal,
  } = props.parameters

  const t = useTranslate()

  return (
    <section className="order">
      {modalOpen ? (
        <ModalStoreEditItemInList
          modalOpen={modalOpen}
          productName={productName}
          productBrand={productBrand}
          productCount={productCount}
          productPrice={productPrice}
          productTotalPrice={productTotalPrice}
          productMeasurementUnit={productMeasurementUnit}
          productNote={productNote}
          isUpdating={isUpdating}
          productNameDisabled={productNameDisabled}
          productBrandDisabled={productBrandDisabled}
          productCountDisabled={productCountDisabled}
          handleProductName={handleProductName}
          handleProductBrand={handleProductBrand}
          handleProductCount={handleProductCount}
          handleProductPrice={handleProductPrice}
          handleProductTotalPrice={handleProductTotalPrice}
          handleProductMeasurementUnit={handleProductMeasurementUnit}
          handleProductNote={handleProductNote}
          handleUpdateProductInList={handleUpdateProductInList}
          handleModal={handleModal}
        />
      ) : null}
      {/*{errors ? <h6 className="error">Error: {errors}</h6> : null}*/}
      {bannerMessage ? (
        <h6 className="banner-message">{t(`${'bannerMessage'}`)}</h6>
      ) : null}
      <div className="order__top">
        <div className="order__top__left">
          <ButtonBack />
          <h1 className="order__top__left__user-name h4-bold ">{t('user_name')}</h1>
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{t('order')}</h2>
      </div>
      <div>
        <div className="order__user">
          <img
            className="order_user_img img-user-small"
            src={order?.buyerPictureSignedUrl}
          />
          <div className="order__user__left">
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s ">
                {t('buyer_name')}
              </h4>
              <h3 name="buyerName" className="order__user__left__field__text text-s ">
                {order?.buyerName}
              </h3>
            </div>
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('buyer_id')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{order?.buyerId}</h3>
            </div>
          </div>

          <div className="order__user__right">
            {/*<div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">Buyer address:</h4>
              <h3 className="order__user__left__field__text text-s ">{buyerAddress}</h3>
            </div>*/}

            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('buyer_location')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">
                {order?.buyerLocation}
              </h3>
            </div>
          </div>
        </div>

        <div className="order__user">
          <img
            className="order_user_img img-user-small"
            src={order?.storePictureSignedUrl}
          />
          <div className="order__user__left">
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_name')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">
                {order?.storeName}
              </h3>
            </div>
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_id')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{order?.storeId}</h3>
            </div>
          </div>
          <div className="order__user__right">
            {/*<div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">Store address</h4>
              <h3 className="order__user__left__field__text text-s ">{storeAddress}</h3>
            </div>*/}
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_location')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">
                {order?.storeLocation}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="order__details__show__group">
        <div className="order__details__show">
          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_range_start')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryDateRangeStart}
              </h3>
            </div>
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_range_end')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryDateRangeEnd}
              </h3>
            </div>
          </div>

          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_time_start')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryTimeRangeStart}
              </h3>
            </div>
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_time_end')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryTimeRangeEnd}
              </h3>
            </div>
          </div>

          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_location')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryLocation}
              </h3>
            </div>
          </div>

          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">{t('delivery_note')}</h4>
              <h3 className="order__user__field__text text-s ">{order?.deliveryNote}</h3>
            </div>
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_status')}
              </h4>
              <h3 className={`order__user__field__text text-s ${classDeliveryStatus}`}>
                {deliveryStatus}
              </h3>
            </div>
          </div>
        </div>
        <div className="order__cart__bottom">
          <h3 className="text-m-l">{t('product')}</h3>
          <div className="order__cart__bottom__details">
            <StoreEditBasketTable
              basketItems={basketItems}
              productPrice={productPrice}
              productTotalPrice={productTotalPrice}
              handleEditProduct={handleEditProduct}
            />

            <div className="order__cart__bottom__details__shipping">
              <InputLarge
                textHelper={`${t('shipping')} $`}
                type="number"
                id="deliver-fee"
                handleChange={handleShipping}
                value={`${shipping}`}
              />
            </div>
            <div className="order__cart__bottom__details__total">
              <h4 className="text-m-l">SubTotal</h4>
              <h4 className="text-m-l">${subTotal}</h4>
            </div>
            <div className="order__cart__bottom__details__total">
              <h4 className="text-m-l">Total</h4>
              <h4 className="text-m-l">${total}</h4>
            </div>

            <div className="order__cart__bottom__details__total">
              <h4 className="text-m-l">{t('order_status')}</h4>
              <h4 className={`text-m-l ${classDeliveryStatus}`}>{deliveryStatus}</h4>
            </div>

            {auth?.userInfo?.id === order?.buyerId &&
            (deliveryStatus === 'Pending' || deliveryStatus === 'Accepted') ? (
              <ModalConfirmation
                title={t('confirm')}
                description={t('are_you_sure')}
                callbackFunction={handleCancelOrder}
              >
                {(confirm) => (
                  <ButtonExtraLarge
                    type="Cancel Order"
                    text={t('cancel_order')}
                    handleClick={confirm(handleCancelOrder)}
                  />
                )}
              </ModalConfirmation>
            ) : null}

            {auth?.userInfo?.storeId === order?.storeId &&
            deliveryStatus === 'Pending' ? (
              <>
                <ModalConfirmation
                  title={t('confirm')}
                  description={t('are_you_sure')}
                  callbackFunction={handleAcceptOrder}
                >
                  {(confirm) => (
                    <ButtonExtraLarge
                      type="Accept Order"
                      text={t('accept_order')}
                      handleClick={confirm(handleAcceptOrder)}
                    />
                  )}
                </ModalConfirmation>

                <ModalConfirmation
                  title={t('confirm')}
                  description={t('are_you_sure')}
                  callbackFunction={handleRejectOrder}
                >
                  {(confirm) => (
                    <ButtonExtraLarge
                      type="Reject Order"
                      text={t('reject_order')}
                      handleClick={confirm(handleRejectOrder)}
                    />
                  )}
                </ModalConfirmation>
              </>
            ) : null}
            {auth?.userInfo?.storeId === order?.storeId &&
            deliveryStatus === 'Accepted' ? (
              <>
                <ModalConfirmation
                  title="Confirm"
                  description="Are you sure?"
                  callbackFunction={handleRejectOrder}
                >
                  {(confirm) => (
                    <ButtonExtraLarge
                      type="Reject Order"
                      text={t('reject_order')}
                      handleClick={confirm(handleRejectOrder)}
                    />
                  )}
                </ModalConfirmation>

                <ModalConfirmation
                  title={t('confirm')}
                  description={t('are_you_sure')}
                  callbackFunction={handleCloseOrder}
                >
                  {(confirm) => (
                    <ButtonExtraLarge
                      type="Close Order"
                      text={t('close_order')}
                      handleClick={confirm(handleCloseOrder)}
                    />
                  )}
                </ModalConfirmation>
              </>
            ) : null}

            {auth?.userInfo?.storeId === order?.storeId &&
            deliveryStatus === 'Rejected' ? (
              <>
                <ModalConfirmation
                  title={t('resume')}
                  description={t('are_you_sure')}
                  callbackFunction={handleResumeOrder}
                >
                  {(confirm) => (
                    <ButtonExtraLarge
                      type="Resume Order"
                      text={t('resume_order')}
                      handleClick={confirm(handleResumeOrder)}
                    />
                  )}
                </ModalConfirmation>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

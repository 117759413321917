import {actionsEnumOrder} from '../../constants'

const initialState = {
  order: {
    id: '',
    isRead: false,
    buyerId: '',
    buyerPicture: '',
    buyerName: '',
    buyerAddress: '',
    buyerLocation: {},
    storeId: {},
    storeName: '',
    storePicture: '',
    storeLocation: {},
    storeAddress: [],
    cartId: {},
    basketItems: [],
    orderItems: [],
    subTotal: 0,
    total: 0,
    status: '',
    pickup: false,
    delivery: true,
    deliveryDateRange: null,
    deliveryTimeRange: null,
    deliveryNote: '',
    deliveryAddress: '',
    deliveryLocation: {},
    shipping: 0,
    deliveryTime: {
      from: '',
      to: '',
    },
  },
  orders: [],
  newOrders: [],
  msg: null,
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumOrder.REMOVE_BASKET_ITEMS_FROM_ORDER:
      if (action.basketItems) {
        return {
          ...state,
          order: {...state.order, basketItems: []},
        }
      }
    case actionsEnumOrder.ADD_BASKET_ITEMS_TO_ORDER:
      if (action.basketItems) {
        return {
          ...state,
          order: {...state.order, basketItems: action.basketItems},
        }
      }

    case actionsEnumOrder.FETCH_ORDER_RECEIVED:
      if (action.order?.message) {
        return {
          ...state,
          msg: action.order.message,
        }
      }
      return {
        ...state,
        order: action.order,
      }

    case actionsEnumOrder.FETCH_ORDERS_RECEIVED:
      if (action.orders.message) {
        return {
          ...state,
          msg: action.orders.message,
        }
      }
      return {
        ...state,
        orders: action.orders,
      }

    case actionsEnumOrder.FETCH_NEW_ORDERS_COMPLETED:
      return {
        ...state,
        newOrders: action.newOrders ? action.newOrders : [],
      }

    case actionsEnumOrder.CREATE_ORDER_RECEIVED:
      if (action.order.message) {
        return {
          ...state,
          msg: action.order.message,
        }
      }
      return {
        ...state,
        order: action.order,
        orders: [...state.orders, action.order],
      }

    case actionsEnumOrder.UPDATE_ORDER_RECEIVED:
      return {
        ...state,
        order: action.order,
      }

    case actionsEnumOrder.READ_NEW_ORDER_RECEIVED:
      if (action.order?.message) {
        return {
          ...state,
          msg: action.order.message,
        }
      }
      if (state.orders.length) {
        const updatedOrders = []
        state.orders?.forEach((o) => {
          const order = o
          if (order.id === action.order._id) {
            order.isRead = true
            return updatedOrders.push(order)
          }
          return updatedOrders.push(order)
        })

        return {
          ...state,
          orders: updatedOrders,
        }
      }
      return {
        ...state,
      }

    case actionsEnumOrder.DELETE_ORDER_RECEIVED:
      return {
        ...state,
        order: [],
        msg: action.msg,
      }

    case actionsEnumOrder.ACCEPT_ORDER_RECEIVED:
      return {
        ...state,
        order: action.order,
      }

    case actionsEnumOrder.REJECT_ORDER_RECEIVED:
      return {
        ...state,
        order: action.order,
      }

    case actionsEnumOrder.CLOSE_ORDER_RECEIVED:
      return {
        ...state,
        order: action.order,
      }

    default:
      return state
  }
}
export default orderReducer

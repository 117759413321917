import { actionsEnumCategories } from '../../../constants';
import { getCategoriesRequestAPI } from '../../../api';

export const fetchCategories = () => (dispatch) => {
  dispatch(fetchCategoriesRequest());
  getCategoriesRequestAPI().then((categories) => {
    dispatch(fetchCategoriesReceived(categories));
    return categories;
  });
};

const fetchCategoriesRequest = () => ({
  type: actionsEnumCategories.FETCH_CATEGORIES_REQUEST,
});

const fetchCategoriesReceived = (categories) => ({
  type: actionsEnumCategories.FETCH_CATEGORIES_RECEIVED,
  categories,
});


import {StatusPilot} from '../../../../components'

export const NotificationsList = (props) => {
  const {userId, data, handleOpenNotification} = props

  const chekIfIsRead = (notification) => {
    return notification?.isReadUserId?.includes(userId)
  }

  return data.length ? (
    <>
      <div className="notifications">
        <h3 className="notifications__title">Notifications</h3>
        <ul className="notifications__list">
          {data?.map((notification, index) => (
            <li
              onClick={() => handleOpenNotification({notification})}
              id={notification.productId}
              key={index}
              name={notification.id}
              className="notifications__list__notification"
            >
              <StatusPilot status={chekIfIsRead(notification)} />
              <div className="notifications__list__notification__left">
                <img className="card__img" src={notification.pictureSignedUrl} />
              </div>
              <div className="notifications__list__notification__right">
                <h6> {notification.storeRoomName} </h6>
                <h5>{notification.productName}</h5>
                <h4>{notification.notificationText}</h4>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  ) : (
    <></>
  )
}

import { ButtonRoundSmall } from '../common';
import { StatusPilot } from './StatusPilot'
import { IconUser } from '../../icons/IconUser'

export const ButtonSession = (props) => {
    const { handleClick, status } = props
    return (
        <div className="button-notifications-container">
            <StatusPilot status={status} />
            <ButtonRoundSmall className="button-round-notifications" icon={IconUser} type="button" placeholder={null} onClick={handleClick} />
        </div>
    );
}
import {combineReducers} from 'redux'
import {IntlReducer as Intl} from 'react-redux-multilingual'
import productReducer from './productReducer'
import categoryReducer from './categoriesReducer'
import cartReducer from './cartReducer'
import orderReducer from './orderReducer'
import storeReducer from './storeReducer'
import chatReducer from './chatReducer'
import searchReducer from './searchReducer'
import notificationReducer from './notificationsReducer'

export const reducers = combineReducers(
  Object.assign(
    {
      cartReducer,
      categoryReducer,
      productReducer,
      orderReducer,
      storeReducer,
      chatReducer,
      searchReducer,
      notificationReducer,
    },
    {Intl},
  ),
)

import {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {UpdateProduct} from './UpdateProduct'
import {
  updateProduct,
  fetchCategorySelectOptions,
  createNotifications,
} from '../../../redux/actions'
import {categoryNameSuggester, useOutsideAlerter, checkCategory} from '../../../utils'
import useAuth from '../../../hooks/session/useAuth'

export const UpdateProductContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productState = useSelector((state) => state.productReducer)
  const categoryState = useSelector((state) => state.categoryReducer)
  const [categorySelectOptions, setCategorySelectOtions] = useState([])
  const [categorySelectOptionsRequested, setCategorySelectOptionsRequested] =
    useState(false)
  const [categorySelectOptionsReceived, setCategorySelectOptionsReceived] =
    useState(false)
  const {id} = useParams()
  const {auth} = useAuth()
  const [product, setProduct] = useState({})
  const products = productState.products
  const [picture, setPicture] = useState('')
  const [pictureSignedUrl, setPictureSignedUrl] = useState('')
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(false)
  const [requested, setRequested] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState(0)
  const [categoriesRequested, setCategoriesRequested] = useState(false)
  const [categoryId, setCategoryId] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [categoryParentName, setCategoryParentName] = useState('')
  const categoryNameInputRef = useRef(null)
  const categoryParentNameInputRef = useRef(null)
  const [categorySearched, setCategorySearched] = useState('')
  const [categoryPath, setCategoryPath] = useState(null)
  const [categories, setCategories] = useState([])
  const [categoryNameSuggestion, setCategoryNameSuggestion] = useState('')
  const [categoryNameSuggestionRequested, setCategoryNameSuggestionRequested] =
    useState(false)
  const [categoryNameSuggestionReceived, setCategoryNameSuggestionReceived] =
    useState(false)
  const [modalSuggestionOpen, setModalSuggestionOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [storeId, setStoreId] = useState(auth?.userInfo?.storeId)
  const [storeName, setStoreName] = useState(auth?.userInfo?.storeName)
  const [itemsCount, setItemsCount] = useState(0)
  const [outOfStock, setOutOfStock] = useState(null) // This is needed to avoid mongoDb weird bug
  const [reStock, setReStock] = useState(false) // This is needed to avoid mongoDb weird bug
  const [measurementUnit, setMeasurementUnit] = useState('unit')
  const [availableAt, setAvailableAt] = useState('')

  const productParameters = {
    fields: {
      id: id,
      name: name,
      description,
      price: price ? price : 1,
      categories,
      categoryId,
      categoryName,
      categoryParentName,
      categoryPath,
      storeId,
      rating: 0,
      storeName,
      count: itemsCount,
      reStock,
      measurementUnit: measurementUnit ? measurementUnit : 'unit',
      availableAt: availableAt ? availableAt : '',
      picture,
      pictureSignedUrl,
    },
    file,
  }

  const handleClickEvent = (parameters) => {
    const {stringToSearch, inputElement} = parameters
    if (stringToSearch !== '') {
      switch (inputElement) {
        case 'categoryName':
          const suggestedCategory = categoryNameSuggester({
            categories: categoryState.categories,
            stringToSearch,
          })
          if (suggestedCategory) {
            setModalSuggestionOpen(true)
            setCategoryNameSuggestion(suggestedCategory)
          }
          break
        case 'categoryParentName':
          const suggestedParentCategory = categoryNameSuggester({
            categoryName,
            categories: categoryState.categories,
            stringToSearch,
          })
          if (suggestedParentCategory) {
            setModalSuggestionOpen(true)
            setCategoryNameSuggestion(suggestedParentCategory)
          }
          break
        default:
      }
    }
  }

  useOutsideAlerter({ref: categoryNameInputRef, handleClickEvent})
  useOutsideAlerter({ref: categoryParentNameInputRef, handleClickEvent})

  useEffect(() => {
    if (!categoriesRequested && categorySelectOptions.length === 0) {
      dispatch(fetchCategorySelectOptions())
      setCategorySelectOptionsReceived(false)
      setCategorySelectOptionsRequested(true)
      setCategoriesRequested(true)
    }
    if (
      categoriesRequested &&
      categorySelectOptions &&
      categorySelectOptions.length > 0
    ) {
      setCategoryPath(categorySelectOptions[0])
      setCategoriesRequested(false)
    }
    if (!categoryNameSuggestionReceived && categoryNameSuggestionRequested) {
      const categoryNameSuggestionState = categoryState?.categoryNameSuggestion
      setCategoryNameSuggestion(categoryNameSuggestionState)
      setCategoryNameSuggestionReceived(true)
      setModalSuggestionOpen(true)
    }

    if (
      !categorySelectOptions.length &&
      categoryState.categorySelectOptions.length &&
      categorySelectOptionsRequested &&
      !categorySelectOptionsReceived
    ) {
      setCategorySelectOtions(categoryState.categorySelectOptions)
      setCategorySelectOptionsReceived(true)
      setCategorySelectOptionsRequested(false)
    }
  }, [categoryState])

  useEffect(() => {
    const receivedProduct = productState.product
    if (requested && receivedProduct !== product) {
      setProduct(receivedProduct)
      setRequested(false)
      setSuccessfullyUpdated(true)
      navigate('/my-shop')
    }
    if (!product?.id && products.length) {
      const productFound = products.find((p) => p.id === id)
      setProduct(productFound)
      setName(product.name)
      const {
        name,
        description,
        price,
        categories,
        categoryId,
        categoryName,
        categoryParentName,
        categoryPath,
        storeId,
        storeName,
        itemsCount,
        measurementUnit,
        availableAt,
        picture,
        pictureSignedUrl,
      } = productFound

      setName(name)
      setDescription(description)
      setPrice(price)
      setCategories(categories)
      setCategoryId(categoryId)
      setCategoryName(categoryName)
      setCategoryParentName(categoryParentName)
      setCategoryPath(categoryPath)
      setStoreId(storeId)
      setStoreName(storeName)
      setItemsCount(itemsCount)
      setMeasurementUnit(measurementUnit)
      setAvailableAt(availableAt)
      setPicture(picture)
      setPictureSignedUrl(pictureSignedUrl)
      if (itemsCount === 0) {
        setOutOfStock(true)
      }
    }
  }, [productState, product, setProduct])

  useEffect(() => {
    if (!storeId && auth?.userInfo?.storeId) {
      setStoreId(auth.userInfo.storeId)
    }
  }, [auth])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      !requested &&
      storeId &&
      storeName &&
      categoryId &&
      categories.length &&
      price &&
      itemsCount &&
      measurementUnit &&
      description
    ) {
      const requestParameters = {
        productParameters,
        auth,
      }

      setRequested(true)
      dispatch(updateProduct(requestParameters))
      if (product.itemsCount === 0 && itemsCount > 0) {
        dispatch(
          createNotifications({
            storeRoomName: storeName,
            storeId: storeId,
            storeName: storeName,
            notificationText: 'The product is available for sell again',
            productId: product.id,
            productName: product.name,
            picture: product.picture,
            pictureSignedUrl: '',
          }),
        )
      }
    }
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handlePrice = (e) => {
    setPrice(e.target.value)
  }

  const handleDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleItemsCount = (e) => {
    setItemsCount(e.target.value)
  }

  const handleMeasurementUnit = (e) => {
    setMeasurementUnit(e.target.value)
  }

  const handleCategoryPath = (e) => {
    if (e?.value) {
      const categoryArray = e.value?.split('/')
      const updatedCategoryArray = []
      categoryArray.forEach((category) => {
        if (category !== '') {
          updatedCategoryArray.push(category)
        }
      })
      !updatedCategoryArray.length ? updatedCategoryArray.push('categories') : null
      categoryName ? updatedCategoryArray.push(categoryName) : null
      setCategories(updatedCategoryArray)
      setCategoryParentName(e.name)
      setCategoryPath(e.value)
      setCategoryId(e.categoryId)
    }
  }

  const handleCategoryName = (e) => {
    const newCategoryName = e.target.value
    checkCategory(newCategoryName)
    setCategoryName(newCategoryName)
    setCategorySearched('categoryName')
  }

  const handleCategoryParentName = (e) => {
    const newCategoryName = e.target.value
    checkCategory(newCategoryName)
    setCategoryParentName(newCategoryName)
    setCategorySearched('categoryParentName')
  }

  const handleModalSuggestionAccepted = (e) => {
    if (categorySearched === 'categoryName') {
      setModalSuggestionOpen(false)
      setCategoryName(categoryNameSuggestion)
    }
    if (categorySearched === 'categoryParentName') {
      setCategoryParentName(categoryNameSuggestion)
      setModalSuggestionOpen(false)
    }
  }

  const handleModalSuggestionRejected = (e) => {
    setModalSuggestionOpen(false)
  }

  const handleBlur = (e) => {
    const inputElement = e.target.id
    if (inputElement === 'categoryName' || inputElement === 'categoryParentName')
      return handleClickEvent({stringToSearch: e.target.value, inputElement: e.target.id})
  }

  const handleChangePicture = async (e) => {
    if (e.target?.files.length) {
      if (e.target?.files[0]?.name) {
        const newFile = e.target.files[0]

        await resizeFile({file: newFile, handleFile})
        const reader = new FileReader()
        reader.readAsDataURL(newFile)

        reader.onload = () => {
          setPictureSignedUrl(reader.result)
        }
      }
    }
  }

  return (
    <UpdateProduct
      handleSubmit={handleSubmit}
      handleChangePicture={handleChangePicture}
      handleName={handleName}
      handleDescription={handleDescription}
      handleItemsCount={handleItemsCount}
      handleMeasurementUnit={handleMeasurementUnit}
      handlePrice={handlePrice}
      handleCategoryName={handleCategoryName}
      handleCategoryParentName={handleCategoryParentName}
      handleCategoryPath={handleCategoryPath}
      handleModalSuggestionAccepted={handleModalSuggestionAccepted}
      handleModalSuggestionRejected={handleModalSuggestionRejected}
      handleBlur={handleBlur}
      auth={auth}
      name={name}
      description={description}
      price={price}
      categoryId={categoryId}
      categoryName={categoryName}
      categoryParentName={categoryParentName}
      categoryPath={categoryPath}
      storeId={storeId}
      itemsCount={itemsCount}
      measurementUnit={measurementUnit}
      availableAt={availableAt}
      file={file}
      successfullyUpdated={successfullyUpdated}
      pictureSignedUrl={pictureSignedUrl}
      categorySelectOptions={categorySelectOptions}
      modalSuggestionOpen={modalSuggestionOpen}
      categoryNameSuggestion={categoryNameSuggestion}
      categoryNameInputRef={categoryNameInputRef}
      categoryParentNameInputRef={categoryParentNameInputRef}
    />
  )
}

export default UpdateProductContainer

import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {EditBasketOrder} from './EditBasketOrder'
import useAuth from '../../../hooks/session/useAuth'
import {
  closeOrder,
  createChatRoom,
  fetchOrder,
  readNewOrder,
  rejectOrder,
  resumeOrder,
  updateOrder,
} from '../../../redux/actions'
import {classStatus, checkValueAndZeroIt} from '../../../utils'

export const EditBasketOrderContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const chatState = useSelector((state) => state.chatReducer)
  const orderState = useSelector((state) => state.orderReducer)
  const {auth} = useAuth()
  const {id} = useParams()
  const [order, setOrder] = useState(null)
  const [orderRequested, setOrderRequested] = useState(false)
  const [subTotal, setSubTotal] = useState('')
  const [total, setTotal] = useState('')
  const [shipping, setShipping] = useState('')
  const [basketItems, setBasketItems] = useState({})
  // Shown information of the basket product
  const [productName, setProductName] = useState('')
  const [productBrand, setProductBrand] = useState('')
  const [productCount, setProductCount] = useState('')
  const [productMeasurementUnit, setProductMeasurementUnit] = useState('')
  const [productNote, setProductNote] = useState('')
  const [productPrice, setProductPrice] = useState('')
  const [productTotalPrice, setProductTotalPrice] = useState('')
  const productNameDisabled = 'disabled'
  const productBrandDisabled = 'disabled'
  const productCountDisabled = 'disabled'

  const [acceptRequested, setAcceptRequested] = useState(false)
  const [rejectRequested, setRejectRequested] = useState(false)
  const [closeRequested, setCloseRequested] = useState(false)
  const [resumeRequested, setResumeRequested] = useState(false)

  const [requestSuccess, setRequestSuccess] = useState(false)
  const [orderUpdateRequested, setOrderUpdateRequested] = useState(false)
  const [isLoadingReadOrder, setIsLoadingReadOrder] = useState(false)
  const [isLoadingReadOrderSuccesfully, setIsLoadingReadOrderSuccesfully] =
    useState(false)
  const [chatRoomRequested, setChatRoomRequested] = useState(false)
  const [chatRoomSuccess, setChatRoomSuccess] = useState(false)
  const [classDeliveryStatus, setClassDeliveryStatus] = useState(null)
  const [deliveryStatus, setDeliveryStatus] = useState(null)
  const [chatStatus, setChatStatus] = useState(null)
  const [bannerMessage, setBannerMessage] = useState('')
  // Basket variables
  const [modalOpen, setModalOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [ratingModal, setRatingModal] = useState(false)

  const populateOrderValues = (orderToParse) => {
    setTotal(orderToParse.total)
    setSubTotal(orderToParse.subTotal)
    setShipping(orderToParse.shipping)
    setDeliveryStatus(orderToParse.deliveryStatus)
    setClassDeliveryStatus(classStatus(orderToParse))
    setOrder(orderToParse)
    setBasketItems(orderToParse?.basketItems)
  }

  const handleReadNewOrder = (order) => {
    if (!order?.isRead && !isLoadingReadOrder && !isLoadingReadOrderSuccesfully) {
      const requestParameters = {
        auth: auth,
        orderId: order.id,
      }
      dispatch(readNewOrder(requestParameters))
      setIsLoadingReadOrder(true)
    }
  }

  useEffect(() => {
    if (chatState?.chatRoom?.id && chatRoomRequested && !chatRoomSuccess) {
      setChatRoomSuccess(true)
      setChatRoomRequested(false)
      setTimeout(() => {
        navigate(`/chatRoom/${chatState?.chatRoom?.id}`)
      }, 2500)
    }
  }, [
    setChatRoomSuccess,
    setChatRoomRequested,
    chatState?.chatRoom?.id,
    chatRoomRequested,
  ])

  useEffect(() => {
    if (orderState?.order?.length === 0) {
      setOrder(null)
      setTimeout(() => {
        navigate('/shop')
      }, 2500)
    }

    if (!order) {
      let orderFound = null
      if (orderState?.orders.length) {
        orderFound = orderState?.orders.find((order) => order?.id === id)
        if (orderFound) {
          populateOrderValues(orderFound)
          return handleReadNewOrder(orderFound)
        }
      }
      if (!orderRequested && !orderFound) {
        const requestParameters = {
          auth: auth,
          orderId: id,
        }
        dispatch(fetchOrder(requestParameters))
        setOrderRequested(true)
      }
    }

    if (
      (acceptRequested || rejectRequested || closeRequested || resumeRequested) &&
      !requestSuccess &&
      !_.isEqual(orderState?.order, order)
    ) {
      const orderUpdated = orderState?.order
      if (orderUpdated) {
        setDeliveryStatus(orderUpdated.deliveryStatus)
        setClassDeliveryStatus(classStatus(orderUpdated))
        if (orderUpdated.rejected) {
          setRejectRequested(true)
          setAcceptRequested(false)
          setCloseRequested(false)
          setResumeRequested(false)
        }
        if (orderUpdated.accepted) {
          setRejectRequested(false)
          setAcceptRequested(true)
          setCloseRequested(false)
          setResumeRequested(false)
        }
        if (orderUpdated.pending) {
          setRejectRequested(false)
          setAcceptRequested(false)
          setCloseRequested(false)
          setResumeRequested(true)
        }
        if (orderUpdated.delivered) {
          setRejectRequested(false)
          setAcceptRequested(false)
          setCloseRequested(true)
          setResumeRequested(false)
        }
        setRequestSuccess(true)
        setBannerMessage('accepted_order')
      }
    }
  }, [orderState])

  const handleAcceptOrder = () => {
    const updatedOrder = _.clone(order)
    updatedOrder.acceptOrder = true
    updatedOrder.pending = false
    updatedOrder.rejected = false
    updatedOrder.deliveryStatus = 'Accepted'
    updatedOrder.shipping = shipping
    setDeliveryStatus('Accepted')
    setAcceptRequested(true)
    setRejectRequested(false)
    setCloseRequested(false)
    setResumeRequested(false)
    const requestParameters = {
      orderParameters: {fields: updatedOrder},
      auth: auth,
    }
    dispatch(updateOrder(requestParameters))
    setAcceptRequested(true)
  }

  const handleRejectOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(rejectOrder(requestParameters))
    setRequestSuccess(false)
    setDeliveryStatus('Rejected')
    setAcceptRequested(false)
    setRejectRequested(true)
    setCloseRequested(false)
    setResumeRequested(false)
  }

  const handleCloseOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(closeOrder(requestParameters))
    setRequestSuccess(false)
    setDeliveryStatus('Delivered')
    setAcceptRequested(false)
    setRejectRequested(false)
    setCloseRequested(true)
    setResumeRequested(false)
    setRatingModal(true)
  }

  const handleResumeOrder = () => {
    const requestParameters = {
      orderId: order?.id,
      auth: auth,
    }
    dispatch(resumeOrder(requestParameters))
    setRequestSuccess(false)
    setDeliveryStatus('Pending')
    setAcceptRequested(false)
    setRejectRequested(false)
    setCloseRequested(false)
    setResumeRequested(true)
  }

  const resetProductForm = () => {
    setProductName('')
    setProductBrand('')
    setProductCount('')
    setProductMeasurementUnit('')
    setProductNote('')
    setProductPrice('')
    setProductTotalPrice('')
  }

  const handleUpdateProductInList = () => {
    const index = order?.basketItems.findIndex((item) => item.productName === productName)

    const productParameters = {
      productName: productName,
      productBrand: productBrand,
      productCount: productCount,
      productMeasurementUnit: productMeasurementUnit,
      productNote: productNote,
      productPrice: productPrice,
      productTotalPrice: productTotalPrice,
    }
    const updatedBasketItems = _.clone(basketItems)
    updatedBasketItems[index] = productParameters
    const updatedOrder = _.clone(order)
    updatedOrder.basketItems = updatedBasketItems

    setIsUpdating(false)
    setBasketItems(updatedBasketItems)

    const checkedProductTotalPrice = checkValueAndZeroIt(productTotalPrice)
    const checkedSubTotal = checkValueAndZeroIt(subTotal)
    const updatedSubTotal = checkedSubTotal + checkedProductTotalPrice
    setSubTotal(updatedSubTotal)
    const checkedShipping = checkValueAndZeroIt(shipping)
    const updatedTotal = updatedSubTotal + checkedShipping
    setTotal(updatedTotal)
    updatedOrder.shipping = checkedShipping
    updatedOrder.subTotal = updatedSubTotal
    updatedOrder.total = updatedTotal
    setOrder(updatedOrder)
    handleModal()
  }

  const handleProductName = (e) => {
    const value = e.currentTarget.value
    setProductName(value)
  }

  const handleProductBrand = (e) => {
    const value = e.currentTarget.value
    setProductBrand(value)
  }

  const handleProductMeasurementUnit = (e) => {
    const value = e.currentTarget.value
    setProductMeasurementUnit(value)
  }

  const handleProductNote = (e) => {
    const value = e.currentTarget.value
    setProductNote(value)
  }

  const handleProductCount = (e) => {
    const checkedCount = checkValueAndZeroIt(e.currentTarget.value)
    setProductCount(checkedCount)
    const updatedProductTotalPrice = checkedCount * productTotalPrice
    setProductTotalPrice(updatedProductTotalPrice)
  }

  const handleProductPrice = (e) => {
    const value = checkValueAndZeroIt(e.currentTarget.value)
    const checkedProductCount = checkValueAndZeroIt(productCount)
    setProductPrice(value)
    const updatedProductTotalPrice = value * checkedProductCount
    setProductTotalPrice(updatedProductTotalPrice)
  }

  const handleShipping = (e) => {
    const checkedShipping = checkValueAndZeroIt(e.currentTarget.value)
    setShipping(checkedShipping)
    const checkedSubTotal = checkValueAndZeroIt(subTotal)
    if (checkedShipping === 0) {
      setTotal(checkedSubTotal)
    }
    if (checkedShipping > 0) {
      const updatedTotal = checkedShipping + checkedSubTotal
      setTotal(updatedTotal)
    }
  }

  const handleEditProduct = (index) => {
    const productToUpdateInModal = order?.basketItems[index]

    if (productToUpdateInModal?.productName) {
      setProductName(productToUpdateInModal.productName)
      setProductBrand(productToUpdateInModal.productBrand)
      setProductCount(productToUpdateInModal.productCount)
      setProductMeasurementUnit(productToUpdateInModal.productMeasurementUnit)
      setProductNote(productToUpdateInModal.productNote)
    }
    setIsUpdating(true)
    if (!modalOpen) {
      setModalOpen(true)
    }
  }

  const handleModal = () => {
    if (modalOpen) {
      setModalOpen(false)
      return
    }
    resetProductForm()
    setModalOpen(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = await form.validate(e)
    if (isValid && !orderUpdateRequested) {
      const requestParameters = {
        orderParameters,
        auth,
      }
      setOrderUpdateRequested(true)
      dispatch(updateOrder(requestParameters))
    }
  }

  const handleCancelOrder = () => {
    navigate('/show-orders')
  }

  const handleChat = (e) => {
    e.preventDefault()
    const {
      id,
      buyerId,
      buyerName,
      buyerPicture,
      buyerPictureSignedUrl,
      storeId,
      storeName,
      storePicture,
      storePictureSignedUrl,
    } = order

    const requestParameters = {
      origin: 'order',
      itemId: id,
      itemName: `${storeName} - ${buyerName} ${id}`,
      userId: buyerId,
      userName: buyerName,
      userPicture: buyerPicture,
      userPictureSignedUrl: buyerPictureSignedUrl,
      receiverId: storeId,
      receiverName: storeName,
      receiverPicture: storePicture,
      receiverPictureSignedUrl: storePictureSignedUrl,
    }
    dispatch(createChatRoom(requestParameters))
    setChatRoomRequested(true)
  }

  const props = {
    basketItems,
    bannerMessage,
    auth,
    order,
    deliveryStatus,
    chatStatus,
    classDeliveryStatus,
    productName,
    productBrand,
    productCount,
    productMeasurementUnit,
    productNote,
    productPrice,
    productTotalPrice,
    modalOpen,
    isUpdating,
    productNameDisabled,
    productBrandDisabled,
    productCountDisabled,
    subTotal,
    total,
    shipping,
    ratingModal,
    handleAcceptOrder,
    handleCancelOrder,
    handleRejectOrder,
    handleCloseOrder,
    handleResumeOrder,
    handleUpdateProductInList,
    handleChat,
    handleProductName,
    handleProductBrand,
    handleProductCount,
    handleProductMeasurementUnit,
    handleProductPrice,
    handleEditProduct,
    handleShipping,
    handleProductNote,
    handleModal,
    handleSubmit,
  }

  return <EditBasketOrder parameters={props} />
}

export const ButtonSmall = (props) => {
    const { type, text, onClick, icon } = props
    return (
        <div>
            <button className='button button-small' type={type} onClick={onClick} icon={icon} >
                {text}
            </button>
        </div>

    )
}

import {actionsEnumStore} from '../../../constants'
import {getStoreBasketRequestAPI} from '../../../api'

const fetchStoreBasketRequest = () => ({
  type: actionsEnumStore.FETCH_STORE_BASKET_REQUEST,
})

const fetchStoreBasketReceived = (storeBasket) => ({
  type: actionsEnumStore.FETCH_STORE_BASKET_RECEIVED,
  storeBasket,
})

export const fetchStoreBasket = () => (dispatch) => {
  dispatch(fetchStoreBasketRequest())
  getStoreBasketRequestAPI().then((storeBasket) => {
    dispatch(fetchStoreBasketReceived(storeBasket))
  })
}

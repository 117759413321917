import { actionsEnumStore } from '../../../constants';
import { updateStoreAPI } from '../../../api';

const updateStoreRequest = () => ({
  type: actionsEnumStore.UPDATE_STORE_REQUEST,
});

const updateStoreReceived = (order) => ({
  type: actionsEnumStore.UPDATE_STORE_RECEIVED,
  order,
});

export const updateStore = (requestParameters) => (dispatch) => {
  dispatch(updateStoreRequest());
  updateStoreAPI(requestParameters).then((order) => {
    dispatch(updateStoreReceived(order));
    return order;
  });
};

import {useLocation, Navigate, Outlet} from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/session/useAuth'

const RequireAuth = (props) => {
  const {auth} = useAuth()
  const location = useLocation()
  const {allowedRoles} = props

  const ComponentToShow = () => {
    if (
      auth?.roles?.find((role) => {
        let result = null
        if (typeof role === 'string') {
          const roleNumber = parseInt(role)
          result = allowedRoles?.includes(roleNumber)
        } else {
          result = allowedRoles?.includes(role)
        }
        return result
      })
    ) {
      return <Outlet />
    }
    if (auth?.userInfo) {
      return <Navigate to="/unauthorized" state={{from: location}} replace />
    }
    return <Navigate to="/login" state={{from: location}} replace />
  }

  return <ComponentToShow />
}

RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default RequireAuth

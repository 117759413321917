import { actionsEnumChat } from '../../../constants';
import { getChatRoomsAPI } from '../../../api';

const fetchChatRoomsRequest = () => ({
    type: actionsEnumChat.FETCH_CHAT_ROOMS_REQUEST,
});

const fetchChatRoomsReceived = (chatRooms) => ({
    type: actionsEnumChat.FETCH_CHAT_ROOMS_RECEIVED,
    chatRooms,
});

export const fetchChatRooms = (requestParameters) => (dispatch) => {
    dispatch(fetchChatRoomsRequest());
    getChatRoomsAPI(requestParameters).then((chatRooms) => {
        dispatch(fetchChatRoomsReceived(chatRooms));
        return chatRooms;
    });
};

import {createContext, useEffect, useState} from 'react'
import {loadFromLocalStorage} from '../utils/localStorage'
import {updatePictureUrlAPI} from '../api'

const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
  const [auth, setAuth] = useState({})
  let loggedIn = auth?.accessToken ? true : false

  useEffect(() => {
    if (!auth?.accessToken) {
      loggedIn = true
    }
  }, [])

  if (loggedIn === false) {
    const storedAuth = loadFromLocalStorage('auth')
    if (!auth?.accessToken && storedAuth?.accessToken) {
      const newUrl = async () =>
        await updatePictureUrlAPI({
          auth: storedAuth,
          pictureName: storedAuth?.userInfo?.picture,
        })
      newUrl().then((url) => {
        storedAuth.pictureSignedUrl = url
        setAuth(storedAuth)
      })
    }
  }

  return <AuthContext.Provider value={{auth, setAuth}}>{children}</AuthContext.Provider>
}

export default AuthContext

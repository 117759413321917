import {ButtonAdd} from './ButtonAdd'

export const ButtonCreateItem = (props) => {
  const {title, handleClick} = props
  return (
    <div className="button-create-item">
      <h3 className="text-b-l">{title}</h3>
      <div className="button-create-item__button">
        <ButtonAdd handleClick={(e) => handleClick(e)} />
      </div>
    </div>
  )
}

import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import useAuth from '../../hooks/session/useAuth'
import {ButtonBack, ButtonLarge, InputLarge} from '../../components'
import {loginApi} from '../../api'
import {setToLocalStorage} from '../../utils/localStorage'
import {useTranslate} from 'react-redux-multilingual'

const Login = (data) => {
  const {parsedName, parsedPwd} = data
  const {auth, setAuth} = useAuth()
  const navigate = useNavigate()
  const [name, setName] = useState(parsedName || '')
  const [pwd, setPwd] = useState(parsedPwd || '')
  const [error, setError] = useState(null)
  const t = useTranslate()

  parsedName ? handleSubmit() : null

  useEffect(() => {
    if (auth?.accessToken) {
      navigate('/')
    }
  }, [auth])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const user = name
      const response = await loginApi({user, pwd})
      if (typeof response === 'object') {
        const accessToken = response?.data?.accessToken
        const userInfo = response?.data?.filteredUser
        userInfo.id = userInfo._id
        const roles = userInfo?.roles
        delete userInfo._id
        setAuth({
          user,
          pwd,
          roles,
          accessToken,
          userInfo,
        })
        setToLocalStorage('auth', {user, pwd, roles, accessToken, userInfo})

        setName(response?.data?.filteredUser)
        setPwd('')
        setError(null)
        navigate('/', {replace: true})
        // navigate(from, { replace: true });
      } else {
        if (typeof response === 'string') {
          setError(response)
        }
      }
    } catch (err) {
      console.log('Error:', err)
      setError(err)
    }
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handlePwd = (e) => {
    setPwd(e.target.value)
  }

  const handleClickBack = (e) => {
    e.preventDefault()
    navigate(`/`, {replace: true})
  }

  return (
    <>
      <div className="background-circle" />
      {error ? <h6 className="error">Error: {error}</h6> : null}

      <section className="session">
        <ButtonBack handleClick={handleClickBack} />
        <h1 className="title sign-in  h1-bold"> {t('sign_in')} </h1>
        <form onSubmit={handleSubmit}>
          <div className="session__block">
            <InputLarge
              label={t('name')}
              type="text"
              id="firstName"
              handleChange={handleName}
              value={name}
            />
          </div>
          <div className="session__block">
            <InputLarge
              label={t('password')}
              type="text"
              id="password"
              name="password"
              handleChange={handlePwd}
              value={pwd}
            />
          </div>
          <ButtonLarge
            type="submit"
            text={t('sign_in')}
            handleClick={handleSubmit}
            disabled={!!(!name || !pwd)}
          />
        </form>
        <div className="session__options">
          <p className="text-s">
            {t('need_an_account')}
            <br />
            <span className="line">
              <Link to="/register">{t('sign_up')}</Link>
            </span>
          </p>
        </div>
      </section>
    </>
  )
}

export default Login

;('https://flotando-app-images.s3.us-east-1.amazonaws.com/228d9ff47ad7d4756d22064904f67c2b6b964797c6e316d6ab2b5d5de47d75d4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIARY6R7CG3E5GHFRE2%2F20240726%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240726T014756Z&X-Amz-Expires=3600&X-Amz-Signature=188673e4099ec33fb7b6b741ef3ba171d415f8d71d635cc1e02bf334fab17be6&X-Amz-SignedHeaders=host&x-id=GetObject')

import { actionsEnumOrder } from '../../../constants';
import { getNewOrdersRequestAPI } from '../../../api';

const fetchNewOrdersRequest = () => ({
    type: actionsEnumOrder.FETCH_NEW_ORDERS_REQUESTED,
});

const fetchNewOrdersCompleted = (newOrders) => ({
    type: actionsEnumOrder.FETCH_NEW_ORDERS_COMPLETED,
    newOrders,
});

export const fetchNewOrders = (requestParameters) => (dispatch) => {
    dispatch(fetchNewOrdersRequest());
    getNewOrdersRequestAPI(requestParameters).then((newOrders) => {
        dispatch(fetchNewOrdersCompleted(newOrders));
        return newOrders;
    });
};

import { actionsEnumCart } from '../../../constants';

export const removeFromCart = (id) => (dispatch) => {
  dispatch(removeItemFromCart(id));
  dispatch(itemRemovedFromCart());
};

const removeItemFromCart = (id) => ({
  type: actionsEnumCart.REMOVE_FROM_CART,
  payload: { id: id },
});

const itemRemovedFromCart = () => ({
  type: actionsEnumCart.REMOVED_FROM_CART,
});
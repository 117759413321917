import { ButtonRoundSmall } from '../common'
import { IconMinus } from '../../icons/IconMinus'

export const ButtonRemove = (props) => {
    const { handleClick } = props
    return (
        <ButtonRoundSmall
            className="button-add"
            icon={IconMinus}
            type="button"
            placeholder={null}
            onClick={handleClick} />
    );
}
import { actionsEnumOrder } from '../../../constants';
import { updateOrderAPI } from '../../../api';

const updateOrderRequest = () => ({
  type: actionsEnumOrder.UPDATE_ORDER_REQUEST,
});

const updateOrderReceived = (order) => ({
  type: actionsEnumOrder.UPDATE_ORDER_RECEIVED,
  order,
});

export const updateOrder = (requestParameters) => (dispatch) => {
  dispatch(updateOrderRequest());
  updateOrderAPI(requestParameters).then((order) => {
    dispatch(updateOrderReceived(order));
    return order;
  });
};

import {useNavigate} from 'react-router-dom'
import {IconPlus} from '../../icons'

export const Card = (props) => {
  const navigate = useNavigate()
  const {title, img, price, target, id} = props

  const handleClick = (e) => {
    e.preventDefault()
    navigate(`/${target}/${e.currentTarget.id}`, {replace: true})
  }
  return (
    <div className="card" id={id} onClick={handleClick}>
      <img className="card__img" src={img} />
      <h5 className="card__title h5-bold">{title}</h5>
      {price ? <h5 className="card__price h5-bold">${price}</h5> : null}
    </div>
  )
}

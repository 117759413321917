import {IconBellWhite} from '../../icons/IconBellWhite'

export const ButtonUnsubscribe = (props) => {
  const {text, handleClick} = props

  return (
    <div className="button-subscribe-container">
      <button className="button button-unsubscribe" type={'button'} onClick={handleClick}>
        {text}
        <IconBellWhite />
      </button>
    </div>
  )
}

import {actionsEnumProducts} from '../../constants'

const initialState = {
  product: {
    id: null,
    name: '',
    description: '',
    sku: '',
    categoryName: '',
    categoryId: '',
    vendorName: '',
    vendorId: '',
    picture: '',
    pictureSignedUrl: '',
    price: '',
    count: '',
    measurementUnit: '',
    rating: '',
    availableAt: '',
  },
  products: [],
  myProducts: [],
  productsByStore: [],
  msg: null,
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumProducts.FETCH_PRODUCTS_RECEIVED:
      if (action.products.message) {
        return {
          ...state,
          msg: action.products.message,
        }
      } else {
        return {
          ...state,
          products: action.products,
        }
      }

    case actionsEnumProducts.FETCH_MY_PRODUCTS_RECEIVED:
      if (action.myProducts.message) {
        return {
          ...state,
          msg: action.myProducts.message,
        }
      } else {
        return {
          ...state,
          myProducts: action.myProducts,
        }
      }

    case actionsEnumProducts.FETCH_PRODUCTS_BY_STORE_RECEIVED:
      if (action.products.message) {
        return {
          ...state,
          msg: action.products.message,
        }
      } else {
        return {
          ...state,
          productsByStore: action.products,
          msg: null,
        }
      }

    case actionsEnumProducts.FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_RECEIVED:
      if (action.products?.message) {
        return {
          ...state,
          msg: action.products.message,
        }
      } else {
        return {
          ...state,
          products: action.products,
          msg: null,
        }
      }

    case actionsEnumProducts.FETCH_PRODUCT_RECEIVED:
      if (action.products?.message) {
        return {
          ...state,
          msg: action.product.message,
        }
      } else {
        return {
          ...state,
          product: action.product,
          msg: null,
        }
      }

    case actionsEnumProducts.CREATE_PRODUCT_RECEIVED:
      if (action.product.message) {
        return {
          ...state,
          msg: action.product.message,
        }
      } else {
        return {
          ...state,
          product: action.product,
          products: [...state.products, action.product],
          msg: null,
        }
      }

    case actionsEnumProducts.UPDATE_PRODUCT_RECEIVED:
      if (action.products?.message) {
        return {
          ...state,
          msg: action.product.message,
        }
      } else {
        return {
          ...state,
          product: action.product,
          msg: null,
        }
      }

    case actionsEnumProducts.DELETE_PRODUCT_RECEIVED:
      return {
        ...state,
        product: [],
        msg: action.msg,
      }

    default:
      return state
  }
}
export default productReducer

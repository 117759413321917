import {actionsEnumNotifications} from '../../../constants'
import {markNotificationAsReadAPI} from '../../../api'

const markNotificationAsReadRequest = () => ({
  type: actionsEnumNotifications.MARK_NOTIFICATION_AS_READ_REQUEST,
})

 const markNotificationAsReadReceived = (notification) => ({
  type: actionsEnumNotifications.MARK_NOTIFICATION_AS_READ_COMPLETED,
  notification,
})

export const markNotificationAsRead = (requestParameters) => (dispatch) => {
  dispatch(markNotificationAsReadRequest())
  markNotificationAsReadAPI(requestParameters).then((notification) => {
    dispatch(markNotificationAsReadReceived(notification))
    return notification
  })
}

import { receiveMessageAPI } from '../../../api';
import { actionsEnumChat } from '../../../constants';

const receiveMessageRequest = () => ({
  type: actionsEnumChat.RECEIVE_MESSAGE_REQUEST,
});

export const receiveMessageCompleted = (message) => ({
  type: actionsEnumChat.RECEIVE_MESSAGE_COMPLETED,
  message,
});

export const receiveMessage = (userOrStoreId) => (dispatch) => {
  dispatch(receiveMessageRequest());
  receiveMessageAPI(userOrStoreId)
  dispatch(receiveMessageCompleted(message));
  return message;
};


import { actionsEnumProducts } from '../../../constants';
import { getProductsRequestAPI } from '../../../api';

const fetchProductsRequest = () => ({
  type: actionsEnumProducts.FETCH_PRODUCTS_REQUEST,
});

const fetchProductsReceived = (products) => ({
  type: actionsEnumProducts.FETCH_PRODUCTS_RECEIVED,
  products,
});

export const fetchProducts = () => (dispatch) => {
  dispatch(fetchProductsRequest());
  getProductsRequestAPI().then((products) => {
    dispatch(fetchProductsReceived(products));
    return products;
  });
};

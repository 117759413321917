import { actionsEnumCart } from '../../../constants';
import { deleteCartAPI } from '../../../api';

const deleteCartRequest = () => ({
  type: actionsEnumCart.DELETE_CART_REQUEST,
});

const deleteCartReceived = (msg) => ({
  type: actionsEnumCart.DELETE_CART_RECEIVED,
  msg,
});

export const deleteCart = (id, auth) => (dispatch) => {
  dispatch(deleteCartRequest());
  deleteCartAPI(id, auth).then((msg) => {
    dispatch(deleteCartReceived(msg));
  });
};

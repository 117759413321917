import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DashboardBuyer} from './DashboardBuyer'
import {
  fetchOrders,
  fetchStore,
  fetchProductsByCategoryAndIndex,
  fetchCategories,
} from '../../../redux/actions'
import {actionsEnumCategories} from '../../../constants'
import useAuth from '../../../hooks/session/useAuth'
import {useNavigate} from 'react-router-dom'
import isEqual from 'lodash.isequal'

export const DashboardBuyerContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const categoryState = useSelector((state) => state.categoryReducer)
  const productState = useSelector((state) => state.productReducer)
  const storeState = useSelector((state) => state.storeReducer)
  const orderState = useSelector((state) => state.orderReducer)
  const chatState = useSelector((state) => state.chatReducer)
  const {auth} = useAuth()
  const {store} = storeState
  const [storeId, setStoreId] = useState(auth?.userInfo?.storeId)
  const [indexStart, setIndexStart] = useState(0)
  const [indexEnd, setIndexEnd] = useState(10)
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [chatNewMessages, setChatNewMessages] = useState([])
  const [categoriesRequested, setCategoriesRequested] = useState(false)
  const [productsRequested, setProductsRequested] = useState(false)
  const [productsSuccessfullyReceived, setProductsSuccessfullyReceived] = useState(false)
  const [storeRequested, setStoreRequested] = useState(false)
  const [storeSuccessfullyReceived, setStoreSuccessfullyReceived] = useState(false)
  const [ordersRequested, setOrdersRequested] = useState(false)
  const [ordersSuccessfullyReceived, setOrdersSuccessfullyReceived] = useState(false)
  const [chatNewMessagesRequested, setChatNewMessagesRequested] = useState(false)
  const [chatNewMessagesSuccessfullyReceived, setChatNewMessagesSuccessfullyReceived] =
    useState(false)

  useEffect(() => {
    if (auth?.pwd && !storeId) {
      setStoreId(auth?.userInfo?.storeId)
    }
  }, [])

  useEffect(() => {
    const receivedCategories = categoryState.categories
    const errorApi = categoryState.msg
    if (!categoriesRequested && categories.length === 0) {
      dispatch(fetchCategories())
      setCategoriesRequested(true)
    }
    if (categoriesRequested && receivedCategories !== categories) {
      setCategories(receivedCategories)
      setCategoriesRequested(false)
    }

    if (!categoriesRequested && categories.length === 0) {
      dispatch(fetchCategories())
      setCategoriesRequested(true)
    }
  }, [categoryState])

  useEffect(() => {
    const receivedProducts = productState.products
    const errorApi = categoryState.msg
    if (
      productsRequested &&
      receivedProducts?.length !== 0 &&
      !isEqual(products, receivedProducts)
    ) {
      setProducts(receivedProducts)
      setProductsRequested(false)
      setProductsSuccessfullyReceived(true)
    }
    if (errorApi) {
      setProductsSuccessfullyReceived(false)
    }
    if (!productsRequested && products?.length === 0 && !productsSuccessfullyReceived) {
      const requestArguments = {
        category: actionsEnumCategories.CATEGORIES_ROOT,
        indexStart: indexStart,
        indexEnd: indexEnd,
      }
      dispatch(fetchProductsByCategoryAndIndex(requestArguments))
      setProductsRequested(true)
    }
  }, [productState])

  useEffect(() => {
    if (!store?.storeId && !storeRequested && !storeSuccessfullyReceived && storeId) {
      dispatch(fetchStore(storeId))
      setStoreRequested(true)
    }
    if (storeRequested && !storeSuccessfullyReceived && store) {
      setStoreSuccessfullyReceived(true)
      setStoreRequested(false)
    }
  }, [storeState])

  useEffect(() => {
    if (!ordersRequested && !ordersSuccessfullyReceived) {
      dispatch(fetchOrders({auth: auth}))
      setOrdersRequested(true)
    }
    if (ordersRequested && !ordersSuccessfullyReceived && orderState.orders) {
      setOrdersSuccessfullyReceived(true)
      setOrdersRequested(false)
    }
  }, [orderState])

  useEffect(() => {
    if (!chatNewMessagesRequested && !chatNewMessagesSuccessfullyReceived) {
      setChatNewMessagesRequested(true)
    }
    if (
      chatNewMessagesRequested &&
      !chatNewMessagesSuccessfullyReceived &&
      chatState?.newMessages
    ) {
      setChatNewMessages(chatState?.newMessages)
      setChatNewMessagesSuccessfullyReceived(true)
      setChatNewMessagesRequested(false)
    }
  }, [chatState])

  const handleCreateStore = (e) => {
    e.preventDefault()
    navigate(`/update-user`, {replace: true})
  }

  const handleCarousel = (e) => {
    e.preventDefault()
    const category = e.currentTarget.innerText
    const requestArguments = {
      category: category,
      indexStart: indexStart,
      indexEnd: indexEnd,
    }
    dispatch(searchClear())
    dispatch(fetchProductsByCategoryAndIndex(requestArguments))
    setProductsRequested(true)
    setProductsSuccessfullyReceived(false)
  }

  return (
    <DashboardBuyer
      handleCreateStore={handleCreateStore}
      categories={categories}
      products={products}
      handleCarousel={handleCarousel}
    />
  )
}

import {actionsEnumSearch} from '../../../constants'
import {searchAPI} from '../../../api'

const searchRequest = (searchQuery) => ({
  type: actionsEnumSearch.FETCH_SEARCH_REQUEST,
  searchQuery,
})

const searchReceived = (result) => ({
  type: actionsEnumSearch.FETCH_SEARCH_RECEIVED,
  result,
})

export const search = (requestParameters) => (dispatch) => {
  dispatch(searchRequest(requestParameters.searchQuery))
  searchAPI(requestParameters).then((result) => {
    dispatch(searchReceived(result))
  })
}

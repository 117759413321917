import axios from './axios'
import {URL_CARTS} from '../urls'

const appendToFormData = (props) => {
  const {userId, userInfo, items, itemsCount, groupsByStore, subTotal, shipping, total} =
    props

  const formData = new FormData()
  formData.append('userId', userId)
  formData.append('userInfo', JSON.stringify(userInfo))
  formData.append('items', JSON.stringify(items))
  formData.append('groupsByStore', JSON.stringify(groupsByStore))
  formData.append('itemsCount', itemsCount)
  formData.append('subTotal', subTotal)
  formData.append('total', total)
  formData.append('shipping', shipping)
  return formData
}

const getCartRequestAPI = async (auth) => {
  try {
    const response = await axios.post(`${URL_CART}/`, null, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    return response.data.cart
  } catch (err) {
    return err
  }
}

const createCartAPI = async (requestParameters) => {
  const {cart, auth} = requestParameters
  const formData = appendToFormData(cart)

  try {
    const response = await axios.post(URL_CARTS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    const {_id, userInfo, items, groupsByStore, ...rest} = response.data.cart
    const updatedCart = {
      id: _id,
      userInfo: JSON.parse(userInfo),
      items: items,
      groupsByStore: JSON.parse(groupsByStore[0]),
      ...rest,
    }
    return updatedCart
  } catch (err) {
    return err?.response?.data
  }
}

const updateCartAPI = async (requestParameters) => {
  const {cartParameters, auth} = requestParameters
  const formData = appendToFormData(cartParameters)

  try {
    const response = await axios.patch(URL_CART, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    return response.data.cart
  } catch (err) {
    return err.response.data
  }
}

const deleteCartAPI = async (cartId, auth) => {
  try {
    const response = await axios.delete(`${URL_CART}/${cartId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.msg
  } catch (err) {
    return err
  }
}

export {getCartRequestAPI, createCartAPI, updateCartAPI, deleteCartAPI}

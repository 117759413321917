import {actionsEnumOrder} from '../../../constants'

const addBasketToOrderCompleted = (basketItems) => ({
  type: actionsEnumOrder.ADD_BASKET_ITEMS_TO_ORDER,
  basketItems,
})

export const addBasketToOrder = (basketItems) => (dispatch) => {
  dispatch(addBasketToOrderCompleted(basketItems))
}

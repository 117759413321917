import { actionsEnumChat } from '../../../constants';
import { getMostRecentsMessagesAPI } from '../../../api';

const fetchMostRecentMessagesRequest = () => ({
  type: actionsEnumChat.FETCH_MOST_RECENT_MESSAGES_REQUEST,
});

export const fetchMostRecentMessagesCompleted = (messages) => ({
  type: actionsEnumChat.FETCH_MOST_RECENT_MESSAGES_RECEIVED,
  messages,
});

export const fetchMostRecentMessages = (requestParameters) => (dispatch) => {
  dispatch(fetchMostRecentMessagesRequest());
  getMostRecentsMessagesAPI(requestParameters).then((messages) => {
    dispatch(fetchMostRecentMessagesCompleted(messages));
    return messages;
  });
};

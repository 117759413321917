export const actionsEnumStore = {
  FETCH_STORE_REQUEST: 'FETCH_STORE_REQUEST',
  FETCH_STORE_RECEIVED: 'FETCH_STORE_RECEIVED',
  FETCH_STORES_REQUEST: 'FETCH_STORES_REQUEST',
  FETCH_STORES_RECEIVED: 'FETCH_STORES_RECEIVED',
  FETCH_STORE_BASKET_REQUEST: 'FETCH_STORE_BASKET_REQUEST',
  FETCH_STORE_BASKET_RECEIVED: 'FETCH_STORE_BASKET_RECEIVED',
  REMOVE_STORE_BASKET_COMPLETED: 'REMOVE_STORE_BASKET_COMPLETED',
  CREATE_STORE_REQUEST: 'CREATE_STORE_REQUEST',
  CREATE_STORE_RECEIVED: 'CREATE_STORE_RECEIVED',
  UPDATE_STORE_REQUEST: 'UPDATE_STORE_REQUEST',
  UPDATE_STORE_RECEIVED: 'UPDATE_STORE_RECEIVED',
  DELETE_STORE_REQUEST: 'DELETE_STORE_REQUEST',
  DELETE_STORE_RECEIVED: 'DELETE_STORE_RECEIVED',
}

import {useTranslate} from 'react-redux-multilingual'
import {ButtonSearch} from '../buttons/ButtonSearch'

export const SearchBarComponent = (props) => {
  const {searchQuery, handleChange, handleSearch} = props
  const t = useTranslate()

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder={t('search')}
        onChange={handleChange}
        value={searchQuery}
      />
      <ButtonSearch handleClick={handleSearch} />
    </div>
  )
}

import { actionsEnumProducts } from '../../../constants';
import { createProductAPI } from '../../../api';

const createProductRequest = () => ({
  type: actionsEnumProducts.CREATE_PRODUCT_REQUEST,
});

const createProductReceived = (product) => ({
  type: actionsEnumProducts.CREATE_PRODUCT_RECEIVED,
  product,
});

export const createProduct = (requestParameters) => (dispatch) => {
  dispatch(createProductRequest());
  createProductAPI(requestParameters).then((product) => {
    dispatch(createProductReceived(product));
    return product;
  });
};

import {useTranslate} from 'react-redux-multilingual'
import {ButtonSmall} from '../common'
import {InputLarge, InputTextArea} from '../inputs'

const ModalStoreEditItemInList = (props) => {
  const {
    productName,
    productBrand,
    productCount,
    productMeasurementUnit,
    productPrice,
    productTotalPrice,
    productNote,
    isUpdating,
    productNameDisabled,
    productBrandDisabled,
    productCountDisabled,
    handleCreateListItem,
    handleUpdateProductInList,
    handleProductName,
    handleProductBrand,
    handleProductCount,
    handleProductMeasurementUnit,
    handleProductPrice,
    handleProductNote,
    handleModal,
  } = props
  const t = useTranslate()

  return (
    <form autoComplete="off" onSubmit={handleCreateListItem}>
      <div className="modal-add-item-to-list">
        <div className="modal-add-item-to-list-content">
          <h4 className="modal-add-item-to-list-content__title">{t('add_product')}</h4>
          <div className="modal-add-item-to-list-content__container">
            <div className="modal-add-item-to-list-content__container__item">
              <InputLarge
                textHelper={t('product_name')}
                type="text"
                id="product-name"
                handleChange={handleProductName}
                value={productName}
                isDisabled={productNameDisabled}
              />
            </div>
            <div className="modal-add-item-to-list-content__container__item">
              <InputLarge
                textHelper={t('product_brand')}
                type="text"
                id="product-brand"
                handleChange={handleProductBrand}
                value={productBrand}
                isDisabled={productBrandDisabled}
              />
            </div>
            <div className="modal-add-item-to-list-content__container__item">
              <InputLarge
                textHelper={t('count')}
                type="text"
                id="product-count"
                handleChange={handleProductCount}
                value={productCount}
                isDisabled={productCountDisabled}
              />
            </div>

            <div className="modal-add-item-to-list-content__container__item">
              <InputLarge
                textHelper={t('measurement')}
                type="text"
                id="product-measurement"
                handleChange={handleProductMeasurementUnit}
                value={productMeasurementUnit}
              />
            </div>
            <div className="modal-add-item-to-list-content__container__item">
              <InputLarge
                textHelper={t('price_single_unit')}
                type="text"
                id="single-product-price"
                handleChange={handleProductPrice}
                value={productPrice}
              />
            </div>
            <div className="modal-add-item-to-list-content__container__item">
              <InputLarge
                textHelper={t('price_total_units')}
                type="text"
                id="total_product-price"
                handleChange={null}
                value={productTotalPrice}
              />
            </div>
            <div className="modal-add-item-to-list-content__container__item">
              <InputTextArea
                label={t('product_note')}
                type="text"
                id="product-measurement"
                handleChange={handleProductNote}
                value={productNote}
              />
            </div>
          </div>
          <div className="modal-add-item-to-list-content__actions">
            {!isUpdating ? (
              <ButtonSmall
                type="button"
                text={t('add_product')}
                onClick={(e) => handleCreateListItem(e)}
              />
            ) : (
              <ButtonSmall
                type="button"
                text={t('update_product')}
                onClick={(e) => handleUpdateProductInList(e)}
              />
            )}

            <ButtonSmall type="button" text={t('cancel')} onClick={handleModal} />
          </div>
        </div>
      </div>
    </form>
  )
}

export {ModalStoreEditItemInList}

import {IconSearch} from '../../icons/IconSearch'

export const ButtonSearch = (props) => {
  const {handleClick} = props

  return (
    <div className="button-search-container">
      <IconSearch onClick={handleClick} />
    </div>
  )
}

import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import {reducers} from './reducers'
//import { setToLocalStorage, loadFromLocalStorage } from '../utils';

//const persistedState = loadFromLocalStorage('store');

const store = createStore(
  reducers,
  {Intl: {locale: 'es'}},
  //persistedState,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  ),
)

//store.subscribe(() => {
//  const state = store.getState()
//  //setToLocalStorage('store', state)
//});

export default store

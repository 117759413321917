import {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import useAuth from '../../../hooks/session/useAuth'
import {CreateBasket} from './CreateBasket'
import {addBasketToOrder, fetchStoreBasket} from '../../../redux'
import {checkValueAndZeroIt} from '../../../utils'

export const CreateBasketContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {auth} = useAuth()
  const [modalOpen, setModalOpen] = useState(false)
  const [userName, setUserName] = useState('')
  const [bannerMessage, setBannerMessage] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  // Shown information of the product
  const [listOfItems, setListOfItems] = useState([])
  const [productName, setProductName] = useState('')
  const [productBrand, setProductBrand] = useState('')
  const [productCount, setProductCount] = useState('')
  const [productMeasurementUnit, setProductMeasurementUnit] = useState('')
  const [productNote, setProductNote] = useState('')

  useEffect(() => {
    if (auth?.userInfo) {
      setUserName(auth.userInfo.firstName)
    }
  }, [auth])

  const resetProductForm = () => {
    setProductName('')
    setProductBrand('')
    setProductCount('')
    setProductMeasurementUnit('')
    setProductNote('')
  }

  const handleCreateListItem = async (e) => {
    let isValid
    isValid = productName ? true : false
    if (!isValid) {
      setBannerMessage('Name')
    }
    isValid = !isNaN(Number(parseInt(productCount)))
    if (!isValid) {
      setBannerMessage('Quantity')
    }

    if (isValid) {
      let newList = _.clone(listOfItems)

      const productParameters = {
        productName: productName,
        productBrand: productBrand,
        productCount: productCount,
        productMeasurementUnit: productMeasurementUnit,
        productNote: productNote,
      }

      newList.push(productParameters)
      setListOfItems(newList)
      resetProductForm()
      handleModal()
      return
    }
  }

  const handleUpdateProductInList = () => {
    const index = listOfItems.findIndex((item) => item.productName === productName)

    const productParameters = {
      productName: productName,
      productBrand: productBrand,
      productCount: productCount,
      productMeasurementUnit: productMeasurementUnit,
      productNote: productNote,
    }
    const newList = _.clone(listOfItems)
    newList[index] = productParameters
    setIsUpdating(false)
    setListOfItems(newList)
    resetProductForm()
    handleModal()
  }

  const handleProductName = (e) => {
    const value = e.currentTarget.value
    setProductName(value)
  }

  const handleProductBrand = (e) => {
    const value = e.currentTarget.value
    setProductBrand(value)
  }

  const handleProductCount = (e) => {
    const checkedCount = checkValueAndZeroIt(e.currentTarget.value)
    setProductCount(checkedCount)
  }

  const handleProductMeasurementUnit = (e) => {
    const value = e.currentTarget.value
    setProductMeasurementUnit(value)
  }

  const handleProductNote = (e) => {
    const value = e.currentTarget.value
    setProductNote(value)
  }

  const handleEditProductNote = (index) => {
    const productToUpdateInModal = listOfItems[index]

    if (productToUpdateInModal?.productName) {
      setProductName(productToUpdateInModal.productName)
      setProductBrand(productToUpdateInModal.productBrand)
      setProductCount(productToUpdateInModal.productCount)
      setProductMeasurementUnit(productMeasurementUnit.productMeasurementUnit)
      setProductNote(productToUpdateInModal.productNote)
    }
    setIsUpdating(true)
    if (!modalOpen) {
      setModalOpen(true)
    }
  }

  const handleRemoveItemFromList = (index) => {
    setListOfItems(listOfItems.splice(index, 1))
  }

  const handlePlaceOrder = () => {
    if (listOfItems?.length) {
      dispatch(addBasketToOrder(listOfItems))
      dispatch(fetchStoreBasket())
      navigate('/create-order')
    }
  }

  const handleModal = () => {
    if (modalOpen) {
      setModalOpen(false)
      return
    }
    setModalOpen(true)
  }

  const props = {
    userName,
    bannerMessage,
    listOfItems,
    productName,
    productBrand,
    productCount,
    productMeasurementUnit,
    productNote,
    modalOpen,
    isUpdating,
    handleCreateListItem,
    handleUpdateProductInList,
    handleProductName,
    handleProductBrand,
    handleProductCount,
    handleProductMeasurementUnit,
    handleProductNote,
    handleEditProductNote,
    handleRemoveItemFromList,
    handlePlaceOrder,
    handleModal,
  }

  return <CreateBasket parameters={props} />
}

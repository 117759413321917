export const actionsEnumCategories = {
  FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_RECEIVED: 'FETCH_CATEGORIES_RECEIVED',
  FETCH_CATEGORY_REQUEST: 'FETCH_CATEGORY_REQUEST',
  FETCH_CATEGORY_RECEIVED: 'FETCH_CATEGORY_RECEIVED',
  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_RECEIVED: 'CREATE_CATEGORY_RECEIVED',
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_RECEIVED: 'DELETE_CATEGORY_RECEIVED',
  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_RECEIVED: 'UPDATE_CATEGORY_RECEIVED',
  FETCH_CATEGORY_SELECT_OPTIONS_REQUEST: 'FETCH_CATEGORY_SELECT_OPTIONS_REQUEST',
  FETCH_CATEGORY_SELECT_OPTIONS_RECEIVED: 'FETCH_CATEGORY_SELECT_OPTIONS_RECEIVED',
  FETCH_CATEGORIES_BY_STORE_REQUEST: 'FETCH_CATEGORIES_BY_STORE_REQUEST',
  FETCH_CATEGORIES_BY_STORE_RECEIVED: 'FETCH_CATEGORIES_BY_STORE_RECEIVED',
  SEARCH_CATEGORY: 'SEARCH_CATEGORY',
  CATEGORIES_ROOT: 'categories',
  FETCH_CATEGORY_NAME_SUGGESTION_REQUEST: 'FETCH_CATEGORY_NAME_SUGGESTION_REQUEST',
  FETCH_CATEGORY_NAME_SUGGESTION_RECEIVED: 'FETCH_CATEGORY_NAME_SUGGESTION_RECEIVED'
};

import { ConnectionManager } from "../../../components"
import { Rooms } from "./components"

export const ChatRooms = (props) => {
    const { handleOpenChatRoom, chatRooms } = props.chatRoomsProps
    return (
        <div className="chat">
            <Rooms data={chatRooms} handleOpenChatRoom={handleOpenChatRoom} />
        </div>
    )
}

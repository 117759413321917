import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonLarge,
  ButtonBack,
  ButtonAdd,
  Input,
  InputTextArea,
  ButtonCreateItem,
} from '../../../components'
import {IconStarGold, IconStarGrey} from '../../../icons'

const UpdateStore = (props) => {
  const {
    handleSubmit,
    handleCancel,
    handleChangePicture,
    handleCreateProduct,
    handleLocation,
    handleDescription,
    handleName,
    handleType,
    storeUpdatedSuccessfully,
    reviews,
    pictureSignedUrl,
    name,
    description,
    location,
    auth,
    type,
  } = props
  const t = useTranslate()

  const stars = ['gold', 'gold', 'gold', 'gold', 'gold']

  /* eslint-disable */
  return storeUpdatedSuccessfully ? (
    <div> {t('updated_store')} </div>
  ) : (
    <>
      <div className="background-circle" />
      <section className="item">
        <div className="item__top">
          <div className="item__top__left">
            <ButtonBack />
            <h1 className="item__top__left__user-name h4-bold ">
              {auth?.userInfo?.firstName}
            </h1>
          </div>
        </div>
        <div className="layout__header">
          <h2 className="layout__header__label"> {t('updated_store')} </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="item__picture">
            {pictureSignedUrl ? (
              <img className="img-user" src={pictureSignedUrl} />
            ) : (
              <div className="session__block__picture__placeholder">
                <ButtonAdd
                  type="file"
                  name="storePicture"
                  className="session__block__picture__add"
                  handleClick={(e) => handleChangePicture(e)}
                />
              </div>
            )}
            <input
              className="item__picture__input"
              type="file"
              name="storePicture"
              onChange={(e) => handleChangePicture(e)}
            ></input>
          </div>
          <div className="item__main">
            <div className="item__main__rating">
              <div className="item__main__rating__stars">
                {stars?.map((star, index) =>
                  star === 'gold' ? (
                    <IconStarGold key={index} />
                  ) : (
                    <IconStarGrey key={index} />
                  ),
                )}
              </div>
              <div className="item__main__rating__reviews">
                <Link to="/reviews" className="item__main__rating__reviews__label text-l">
                  {reviews}
                  <h5 className="item__main__rating__reviews__number text-l ">
                    {reviews} 5{' '}
                  </h5>
                  Reviews{' '}
                </Link>
              </div>
            </div>
            <div className="item__main__top">
              <Input
                label={t('store_name')}
                type="text"
                id="storeName"
                handleChange={handleName}
                value={name}
              />

              <Input
                label={t('store_type')}
                type="text"
                id="type"
                handleChange={handleType}
                value={type}
              />
            </div>

            <div className="item__main__description">
              <InputTextArea
                label={t('description')}
                type="text"
                id="description"
                name="description"
                handleChange={handleDescription}
                value={description}
              />
            </div>
            <div className="item__main__details">
              <Input
                label={t('location')}
                type="text"
                id="location"
                handleChange={handleLocation}
                value={location}
              />
            </div>
            <div className="item__main__bottom">
              <ButtonCreateItem
                title={t('create_product')}
                handleClick={handleCreateProduct}
              />
            </div>
            <div className="item__main__bottom">
              <ButtonLarge
                type="button"
                text={t('updated_store')}
                handleClick={handleSubmit}
              />
            </div>

            <div className="item__main__bottom">
              <ButtonLarge type="button" text={t('cancel')} handleClick={handleCancel} />
            </div>
          </div>
        </form>
      </section>
    </>
  )
  /* eslint-enable */
}

export default React.memo(UpdateStore)

const isVowelRegEx = (char) => {
  if (char.length == 1) {
    return /[aeiou]/.test(char);
  }
}

const searchCategory = (stringToSearch, categories) => {
  return categories?.forEach(ctgr => { if (ctgr.name.toLowerCase() === stringToSearch.toLowerCase()) { return stringToSearch.toLowerCase() } })
}

const searchPossibleCategory = (stringToSearch, categories) => {
  return categories?.forEach(ctgr => { if (ctgr.name.toLowerCase().includes(stringToSearch.toLowerCase())) { return ctgr.name.toLowerCase() } })
}

const checkCategory = (name) => {

}

const categoryNameSuggester = (parameters) => {
  const {
    categories,
    stringToSearch
  } = parameters;

  let categoryFound = searchCategory(stringToSearch, categories)
  if (categoryFound) {
    return stringToSearch.toLowerCase()
  }
  const lastLetter = stringToSearch[stringToSearch.length - 1]
  let stringToSearchMutated = stringToSearch
  if (lastLetter !== "s") {
    if (isVowelRegEx(lastLetter)) {
      stringToSearchMutated = stringToSearch + "s"
    }
    if (!isVowelRegEx(lastLetter)) {
      stringToSearchMutated = stringToSearch + "es"
    }
    categoryFound = searchCategory(stringToSearchMutated, categories)
    if (categoryFound) {
      return stringToSearchMutated.toLowercase()
    }
    let possibleCategoriesFound = searchPossibleCategory(stringToSearchMutated)
    if (possibleCategoriesFound) {
      return stringToSearchMutated = possibleCategoriesFound
    }
    possibleCategoriesFound = searchPossibleCategory(stringToSearch)
    if (possibleCategoriesFound) {
      return stringToSearchMutated = possibleCategoriesFound
    }
    return stringToSearchMutated.toLowerCase()
  }
  return stringToSearch
};

const categoryParentNameSuggester = (parameters) => {
  const {
    categoryName,
    categories,
    stringToSearch
  } = parameters;



}

export { categoryNameSuggester, categoryParentNameSuggester, checkCategory };

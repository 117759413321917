import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {retrieveNotifications, markNotificationAsRead} from '../../../redux'
import {Notifications} from './Notifications'
import useAuth from '../../../hooks/session/useAuth'

export const NotificationsContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const notificationsState = useSelector((state) => state.notificationsReducer)
  const [notifications, setNotifications] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      auth?.userInfo?.id &&
      !isLoading &&
      !notifications?.length &&
      !notificationsState?.notifications?.length
    ) {
      const user = auth?.userInfo
      const requestParameters = {
        buyerName: user.firstName,
        buyerId: user.id,
        storesSubscribed: user.storesSubscribed,
      }
      dispatch(retrieveNotifications(requestParameters))
      setIsLoading(true)
    }
    if (!notifications.length && notificationsState?.notifications?.length) {
      const newNotifications = notificationsState?.notifications

      return setNotifications(newNotifications)
    }
    if (
      isLoading &&
      !notifications.length &&
      !notificationsState?.notifications?.length
    ) {
      navigate('/', {replace: true}) // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }, [notificationsState])

  const handleOpenNotification = (props) => {
    const {productId, id} = props.notification
    if (productId && id) {
      dispatch(markNotificationAsRead({id, auth}))
      navigate(`/product/${productId}`, {replace: true})
    }
  }

  const notificationsProps = {
    userId: auth?.userInfo?.id,
    handleOpenNotification,
    notifications,
  }

  return <Notifications notificationsProps={notificationsProps} />
}

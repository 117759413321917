import { actionsEnumChat } from '../../../constants';
import { readNewMessageAPI } from '../../../api';

const readNewMessageRequest = () => ({
    type: actionsEnumChat.READ_NEW_MESSAGE_REQUEST,
});

export const readNewMessageCompleted = (newMessages) => ({
    type: actionsEnumChat.READ_NEW_MESSAGE_COMPLETED,
    newMessages,
});

export const readNewMessage = (requestParameters) => (dispatch) => {
    dispatch(readNewMessageRequest());
    console.log("Read new message done")
    readNewMessageAPI(requestParameters).then((messages) => {
        dispatch(readNewMessageCompleted(messages));
        return messages
    })
}

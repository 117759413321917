import { actionsEnumChat } from '../../../constants';
import { getNewMessagesAPI } from '../../../api';

const fetchNewMessagesRequest = () => ({
  type: actionsEnumChat.FETCH_NEW_MESSAGES_REQUEST,
});

export const fetchNewMessagesCompleted = (newMessages) => ({
  type: actionsEnumChat.FETCH_NEW_MESSAGES_COMPLETED,
  newMessages,
});

export const fetchNewMessages = (requestParameters) => (dispatch) => {
  dispatch(fetchNewMessagesRequest());
  getNewMessagesAPI(requestParameters).then((newMessages) => {
    dispatch(fetchNewMessagesCompleted(newMessages));
    return newMessages;
  });
};

import {ButtonRound} from '../common/commonButtons/ButtonRound'
import {IconHome} from '../../icons/IconHome'

export const ButtonHome = (props) => {
  const {handleClick} = props
  return (
    <ButtonRound
      className="button-home"
      icon={IconHome}
      type="button"
      placeholder={null}
      onClick={handleClick}
    />
  )
}

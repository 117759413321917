import {
  ButtonHome,
  ButtonSession,
  ButtonChats,
  ButtonCart,
  ButtonOrder,
  ButtonNotifications,
} from '../buttons'

export const MenuBarView = (props) => {
  const {
    handleClickHome,
    handleClickChat,
    handleClickSession,
    handleClickCart,
    handleClickOrder,
    notificationsStatus,
    chatStatus,
    sessionStatus,
    cartStatus,
    orderStatus,
  } = props.parameters

  return (
    <div className="menu-bar">
      <div className="menu-bar__background" />
      <div className="menu-bar__left">
        <ButtonHome handleClick={handleClickHome} />
      </div>
      <div className="menu-bar__right">
        <div className="menu-bar__right__like">
          <ButtonNotifications status={notificationsStatus} />
        </div>
        <div className="menu-bar__right__like" onClick={handleClickChat}>
          <ButtonChats status={chatStatus} />
        </div>
        <div className="menu-bar__right__order" onClick={handleClickOrder}>
          <ButtonOrder status={orderStatus} />
        </div>
        <div className="menu-bar__right__user" onClick={handleClickSession}>
          <ButtonSession status={sessionStatus} />
        </div>
        <div className="menu-bar__right__cart" onClick={handleClickCart}>
          <ButtonCart status={cartStatus} />
        </div>
      </div>
    </div>
  )
}

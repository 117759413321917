import Resizer from 'react-image-file-resizer'

export const resizeFile = (props) => {
  const {file, handleFile} = props
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      200,
      200,
      'jpeg',
      80,
      0,
      (uri) => {
        resolve(handleFile(uri))
      },
      'file',
    )
  })
}

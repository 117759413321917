import { actionsEnumCart } from '../../../constants';
import { updateCartAPI } from '../../../api';

const updateCartRequest = () => ({
  type: actionsEnumCart.UPDATE_CART_REQUEST,
});

const updateCartReceived = (cart) => ({
  type: actionsEnumCart.UPDATE_CART_RECEIVED,
  cart,
});

export const updateCart = (requestParameters, id) => (dispatch) => {
  dispatch(updateCartRequest());
  updateCartAPI(requestParameters, id).then((cart) => {
    dispatch(updateCartReceived(cart));
    return cart;
  });
};

import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonBack,
  ButtonExtraLarge,
  ButtonSubscribe,
  ButtonUnsubscribe,
  ItemAdder,
  RatingStars,
} from '../../../components'

export const ShowProduct = (props) => {
  const {
    auth,
    product,
    handleCartProduct,
    handleSubscription,
    handleCancelSubscription,
    handleCount,
    count,
    priceTotal,
    disabled,
    notificationsDisabled,
  } = props
  const t = useTranslate()

  return (
    <>
      <div className="background-circle" />
      <section className="item">
        <div className="item__top">
          <div className="item__top__left">
            <ButtonBack />
            <h1 className="item__top__left__user-name h4-bold ">
              {auth?.userInfo?.firstName}
            </h1>
          </div>
        </div>
        <div className="item__picture">
          {product ? (
            <img src={product?.pictureSignedUrl} alt="product_image" />
          ) : (
            <div className="img-placeholder-large" />
          )}
        </div>
        <div className="item__main">
          <div className="item__main__top">
            <h2 className="h2-bold">{product?.name}</h2>
            <h5 className="text-l">{product?.storeName}</h5>
            <h5 className="text-l">${product?.price}</h5>
          </div>

          <div className="item__main__rating">
            <RatingStars rating={product?.rating} />
          </div>
          <div className="item__handler">
            <div className="item__handler__item-adder">
              <h5 className="text-m-s">{t('add_remove')}</h5>
              <ItemAdder
                totalItems={count}
                handleQuantity={handleCount}
                stock={product?.itemsCount}
              />
            </div>
            <div className="item__handler__cart">
              <h5 className="text-m-s">Stock</h5>
              {product?.itemsCount}
            </div>
            <div className="item__handler__cart">
              <h5 className="text-m-s">{t('count')}</h5>
              {count}
            </div>
            <div className="item__handler__cart">
              <h5 className="text-m-s">Total</h5>$ {priceTotal}
            </div>
          </div>
          <div className="item__main__description">
            <h5 className="text-m-s">{t('description')}</h5>
            <p className="text-l">{product?.description}</p>
          </div>
          <div className="item__main__bottom">
            <div className="item__main__bottom__button">
              {!notificationsDisabled ? (
                <ButtonSubscribe text={t('subscribe')} handleClick={handleSubscription} />
              ) : (
                <ButtonUnsubscribe
                  text={t('unsubscribe')}
                  handleClick={handleCancelSubscription}
                />
              )}
              <ButtonExtraLarge
                type="button"
                text={t('add')}
                handleClick={handleCartProduct}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const actionsEnumNotifications = {
  CREATE_NOTIFICATIONS_REQUEST: 'CREATE_NOTIFICATIONS_REQUEST',
  CREATE_NOTIFICATIONS_RECEIVED: 'CREATE_NOTIFICATIONS_RECEIVED',
  JOIN_NOTIFICATIONS_REQUEST: 'JOIN_NOTIFICATIONS_REQUEST',
  JOIN_NOTIFICATIONS_COMPLETED: 'JOIN_NOTIFICATIONS_COMPLETED',
  CANCEL_NOTIFICATIONS_REQUEST: 'CANCEL_NOTIFICATIONS_REQUEST',
  CANCEL_NOTIFICATIONS_COMPLETED: 'CANCEL_NOTIFICATIONS_COMPLETED',
  RETRIEVE_NOTIFICATIONS_REQUEST: 'RETRIEVE_NOTIFICATIONS_REQUEST',
  RETRIEVE_NOTIFICATIONS_COMPLETED: 'RETRIEVE_NOTIFICATIONS_COMPLETED',
  MARK_NOTIFICATION_AS_READ_REQUEST: 'MARK_NOTIFICATION_AS_READ_REQUEST',
  MARK_NOTIFICATION_AS_READ_COMPLETED: 'MARK_NOTIFICATION_AS_READ_COMPLETED',
}

import {ButtonRound} from '../common/commonButtons/ButtonRound'
import {IconGear} from '../../icons/IconGear'
import {useNavigate} from 'react-router-dom'

export const ButtonSettings = (props) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/update-store', {
      replace: true,
    })
  }
  return (
    <ButtonRound
      className="button-settings"
      icon={IconGear}
      type="button"
      placeholder={null}
      onClick={handleClick}
    />
  )
}

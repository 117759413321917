import {Card} from '../cards/Card'

export const Gallery = (props) => {
  const {title, target, items} = props
  return (
    <div className="gallery">
      <h3 className="gallery__title h2-bold">{title}</h3>
      <div className="gallery__items">
        {items?.length > 0 ? (
          items.map((item) => {
            const key = Math.random()
            return (
              <div className="gallery__items__item" key={key}>
                <Card
                  title={item.name}
                  img={item.pictureSignedUrl}
                  price={item.price}
                  target={target}
                  id={item.id}
                />
              </div>
            )
          })
        ) : (
          <h6 className="h5-bold"> No items to show</h6>
        )}
      </div>
    </div>
  )
}

import { CardItemCart } from '../cards'

export const GalleryCart = (props) => {
    const { title, items, handleOpenItem, handleQuantity, handleRemoveItem } = props
    return (
        <div className="gallery">
            <h3 className="gallery__title h2-bold">{title}</h3>
            <div className="gallery__cart">
                {items?.length ? (
                    items.map((item) => {
                        const key = Math.random()
                        return (
                            <div className="gallery__cart__item" key={key}>
                                <CardItemCart
                                    id={item.itemId}
                                    title={item.itemName}
                                    img={item.pictureSignedUrl}
                                    price={item.price}
                                    total={item.total}
                                    category={item.category}
                                    measurementUnit={item.measurementUnit}
                                    totalItems={item.itemsCount}
                                    handleOpenItem={handleOpenItem}
                                    handleQuantity={handleQuantity}
                                    handleRemoveItem={handleRemoveItem}
                                />
                            </div>
                        )
                    })
                ) : (
                    <h6 className="h5-bold"> No items to show</h6>
                )}
            </div>
        </div>
    )
}

import { actionsEnumOrder } from '../../../constants';
import { deleteOrderAPI } from '../../../api';

const deleteOrderRequest = () => ({
  type: actionsEnumOrder.DELETE_ORDER_REQUEST,
});

const deleteOrderReceived = (msg) => ({
  type: actionsEnumOrder.DELETE_ORDER_RECEIVED,
  msg,
});

export const deleteOrder = (id, auth) => (dispatch) => {
  dispatch(deleteOrderRequest());
  deleteOrderAPI(id, auth).then((msg) => {
    dispatch(deleteOrderReceived(msg));
  });
};

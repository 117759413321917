import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShowStores } from './ShowStores';


const ShowStoresContainer = () => {
  const storesState = useSelector((state) => state.productReducer);
  const dispatch = useDispatch();
  const [stores, setstores] = useState([]);
  const [storesRequested, setStoresRequested] = useState(false);
  const layerHeader = 'Stores'
  //if (storesState?.stores !== stores) {
  //  setStores(storesState.stores);
  //}
  //if (!stores && !storesRequested) {
  //  dispatch(fetchStores());
  //  setStoresRequested(true);
  //}

  const handleNotifications = () => {
    console.log('handleNotifications');
  };

  const handleSearch = () => {
    console.log('handleSearch');
  };

  const handleSettings = () => {
    console.log('handleSettings');
  };

  const handleStoreClick = () => {
    console.log('handleStoreClick');
  };

  const notificationsStatus = true;
  const searchValue = '';

  return (<ShowStores

    handleNotifications={handleNotifications}
    searchValue={searchValue}
    handleSearch={handleSearch}
    handleSettings={handleSettings}
    handleStoreClick={handleStoreClick}
    notificationsStatus={notificationsStatus}
    stores={stores}
    layerHeader={layerHeader}
  />);
};

export default ShowStoresContainer;

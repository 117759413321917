import {actionsEnumNotifications} from '../../../constants'
import {cancelNotificationsAPI} from '../../../api'

const cancelNotificationsRequest = () => ({
  type: actionsEnumNotifications.CANCEL_NOTIFICATIONS_REQUEST,
})

export const cancelNotificationsReceived = (response) => ({
  type: actionsEnumNotifications.CANCEL_NOTIFICATIONS_COMPLETED,
  response,
})

export const cancelNotifications = (requestParameters) => (dispatch) => {
  dispatch(cancelNotificationsRequest())
  cancelNotificationsAPI(requestParameters).then((response) => {
    dispatch(cancelNotificationsReceived(response))
  })
}

const loadFromLocalStorage = (target) => {
  try {
    const storedDataJSON = localStorage.getItem(`${target}`);
    const storedData = JSON.parse(storedDataJSON)
    if (storedData === null) return undefined;
    return storedData
  } catch (e) {
    return undefined;
  }
};

const setToLocalStorage = (target, receivedData) => {
  localStorage.setItem(
    `${target}`,
    JSON.stringify(receivedData),
  )
}

export { loadFromLocalStorage, setToLocalStorage };

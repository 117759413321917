import React from "react";

export const IconPlus = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path fill="#none" d="M0 0H24V24H0z"></path>
            <path
                fill="#40BEAF"
                fillRule="evenodd"
                d="M13 9a1 1 0 10-2 0v2H9a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V9zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export const actionsEnumCart = {
  FETCH_CART_REQUEST: 'FETCH_CART_REQUEST',
  FETCH_CART_RECEIVED: 'FETCH_CART_RECEIVED',
  CREATE_CART_REQUEST: 'CREATE_CART_REQUEST',
  CREATE_CART_RECEIVED: 'CREATE_CART_RECEIVED',
  UPDATE_CART_REQUEST: 'UPDATE_CART_REQUEST',
  UPDATE_CART_RECEIVED: 'UPDATE_CART_RECEIVED',
  DELETE_CART_REQUEST: 'DELETE_CART_REQUEST',
  DELETE_CART_RECEIVED: 'DELETE_CART_RECEIVED',
  ADD_TO_CART: 'ADD_TO_CART',
  ADDED_TO_CART: 'ADDED_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  REMOVED_FROM_CART: 'REMOVED_FROM_CART',
  REMOVE_ITEM_UNIT_FROM_CART: 'REMOVE_ITEM_UNIT_FROM_CART',
  REMOVED_UNIT_FROM_CART: 'REMOVED_UNIT_FROM_CART',
  SET_CART_REQUEST: 'SET_CART_REQUEST',
  SET_CART_DONE: 'SET_CART_DONE',
  REMOVE_GROUP: 'REMOVE_GROUP',
};

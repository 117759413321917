import { useEffect } from "react";

export const useOutsideAlerter = (parameters) => {
    const { ref, handleClickEvent } = parameters
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                const inputElement = event.target.id
                if (inputElement === "categoryName" || inputElement === "categoryParentName")
                    return handleClickEvent({ stringToSearch: event.target.value, inputElement: event.target.id })
            }
        }
        document.addEventListener("blur", handleClickOutside);
        return () => {
            return false
        };
    }, [ref]);
}
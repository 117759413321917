import { actionsEnumProducts } from '../../../constants';
import { getProductRequestAPI } from '../../../api';

const fetchProductRequest = () => ({
  type: actionsEnumProducts.FETCH_PRODUCT_REQUEST,
});

const fetchProductReceived = (product) => ({
  type: actionsEnumProducts.FETCH_PRODUCT_RECEIVED,
  product,
});

export const fetchProduct = (id) => (dispatch) => {
  dispatch(fetchProductRequest());
  getProductRequestAPI(id).then((product) => {
    dispatch(fetchProductReceived(product));
    return product;
  });
};

import { ButtonRoundSmall } from '../common';
import { IconPlus } from '../../icons/IconPlus'

export const ButtonAdd = (props) => {
    const { handleClick } = props
    return (
        <ButtonRoundSmall
            className="button-add"
            icon={IconPlus}
            type="button"
            placeholder={null}
            onClick={handleClick}
        />
    )
}
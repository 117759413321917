import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {ShowOrders} from './ShowOrders'
import useAuth from '../../../hooks/session/useAuth'
import {fetchOrders} from '../../../redux'
import isEqual from 'lodash.isequal'

export const ShowOrdersContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {auth} = useAuth()
  const orderState = useSelector((state) => state.orderReducer)
  const [orders, setOrders] = useState(orderState.orders)
  const [requested, setRequested] = useState(false)
  const [requestedSuccess, setRequestedSuccess] = useState(false)
  const [persistedStateUpdated, setPersistedStateUpdated] = useState(false)

  useEffect(() => {
    console.log(auth)
    if (!auth) {
      console.log('No auth')
    }
  }, [auth])

  useEffect(() => {
    if (!persistedStateUpdated && !requested && !requestedSuccess) {
      const requestParameters = {
        auth: auth,
      }
      dispatch(fetchOrders(requestParameters))
      setRequested(true)
    }

    if (requested && !requestedSuccess && orderState?.orders?.length > 0) {
      setOrders(orderState?.orders)
      setRequestedSuccess(true)
      setPersistedStateUpdated(true)
      setRequested(false)
    }

    if (orderState?.orders.length === 0) {
      setOrders(null)
      setTimeout(() => {
        navigate('/shop')
      }, 2500)
    }

    if (orderState?.orders?.length > 0 && !isEqual(orders, orderState?.orders)) {
      const orders = orderState?.orders
      setOrders(orders)
    }
  }, [orderState])

  if (!orders) {
    let ordersFound = null
    if (orderState?.orders.length) {
      ordersFound = orderState?.orders
      if (ordersFound) {
        return setOrders(ordersFound)
      }
    }
  }

  const handleOpenOrder = (e) => {
    const orderId = e.currentTarget.id
    if (auth?.userInfo?.firstName === 'Tiendina') {
      navigate(`/edit-basket-order/${orderId}`, {replace: true})
      return
    }
    navigate(`/show-order/${orderId}`)
  }

  const props = {
    orders,
    auth,
    handleOpenOrder,
  }

  return <ShowOrders parameters={props} />
}

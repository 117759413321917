export const classStatus = (order) => {
    switch (order?.deliveryStatus) {
        case 'Pending':
            return 'text-m-m text-warning'
        case 'Accepted':
            return 'text-m-m text-warning'
        case 'Rejected':
            return 'text-m-m text-danger'
        case 'Delivered':
            return 'text-m-m text-success'
        default:
    }
}

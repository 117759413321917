import {StatusPilot} from '../buttons/StatusPilot'
import {classStatus} from '../../utils'

export const CardItemOrder = (props) => {
  const {auth, order, handleOpenOrder} = props
  const date = new Date(order?.createdAt)

  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()} ${parseInt(date.getUTCHours() - 5)}:${date.getUTCMinutes()}`

  return (
    <div id={order?.id} className="card-item-order" onClick={handleOpenOrder}>
      <StatusPilot status={auth.user !== order?.buyerName ? !order?.isRead : null} />
      <div className="card-item-order__left">
        {auth?.userInfo?.storeId === order?.storeId ? (
          <img
            className="order_user_img img-user-small"
            src={order?.buyerPictureSignedUrl}
          />
        ) : (
          <img
            className="order_user_img img-user-small"
            src={order?.storePictureSignedUrl}
          />
        )}
      </div>
      <div className="card-item-order__text">
        <h5 className="card-item-order__title h3-bold">
          {auth.user === order?.buyerName ? order?.storeName : order?.buyerName}
        </h5>
        <h5 className="card-item-order__category text-s ">{dateMDY}</h5>
        <h5 className={`card-item-order__price text-m-m ${classStatus(order)}`}>
          {order?.deliveryStatus}
        </h5>
      </div>
    </div>
  )
}

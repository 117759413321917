import {io} from 'socket.io-client'
import store from '../redux/store'
import {receiveMessageCompleted} from '../redux/actions/chatActions/receiveMessage'

// "undefined" means the URL will be computed from the `window.location` object
const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_SOCKET_URL
    : process.env.REACT_APP_SOCKET_URL_DEV

// const URL = 'http://tiendina.waldenberginc.com:5510'
//const URL = 'http://localhost:5510';
export const socket = io(BASE_URL)

// TICKETexport/var/www/html
//
// 1. Update the actions for Socket.IO to match the action method calls below (*Received)
// 2. Remove all 'on' calls from everywhere else in the CLIENT
// 3. Remove procedure useEffect code in ChatRoomContainer
//      * It should be emitting as needed
//      * It should render the state based on redux
//      * It should not need to store anything from redux into useState
//      * It should not need to do anything based on the completion of a specific emit or http call (those should update state via reducers)
//

//export function startSocketOn() {
//  socket.on(
//    'mostRecentMessages',
//    messages => {
//      store.dispatch(
//        mostRecentMessagesReceived(messages)
//      )
//    }
//  );

//socket.on(
//  'newChatMessage',
//  message => {
//    store.dispatch(
//      newChatMessageReceived(message)
//    );
//  }
//);

//socket.on(
//  'receive message',
//  message => {
//    store.dispatch(
//      receiveMessageReceived(message)
//    );
//  }
//);

//socket.on(
//  'new user',
//  users => {
//    store.dispatch(
//      newUserReceived(users)
//    );
//  }
//);

//// SAM!! I'm not sure if the client ever gets this message.
//// old code had the handler commented out.
//socket.on(
//  'get new messages',
//  messages => {
//    store.dispatch(
//      getNewMessagesReceived(messages)
//    );
//  }
//);
//}

export function startSocketOn(auth) {
  //socket.on(
  //  'get new messages',
  //  newMessages => {
  //    const newMessagesWithId = newMessages?.map((newMessage) => {
  //      const { _id, ...rest } = newMessage
  //      return { id: _id, ...rest }
  //    })
  //    store.dispatch(
  //      fetchNewMessagesCompleted(newMessagesWithId)
  //    );
  //  }
  //);

  //socket.on('receive message ping', function (receiverId, callback) {
  //  callback('received')
  //  const userOrStoreId = auth?.userInfo?.isVendor
  //    ? auth?.userInfo.storeId
  //    : auth?.userInfo?.id
  //  if (userOrStoreId === receiverId) {
  //    //callback(socket.id)
  //  }
  //})

  socket.on('receive message', (message) => {
    const {_id, ...rest} = message
    const messageWithId = {id: _id, ...rest}
    const found = localStorage.getItem('lastReceivedMessage') === messageWithId
    if (!found) {
      localStorage.setItem('lastReceivedMessage', messageWithId)
    }
    store.dispatch(receiveMessageCompleted(messageWithId))
  })

  // SAM!! I'm not sure if the client ever gets this message.
  // old code had the handler commented out.
  //socket.on('get new messages', (messages) => {
  //  store.dispatch(readNewMessageCompleted(messages))
  //})
}

export function stopAllListeners() {
  socket.removeAllListeners()
}

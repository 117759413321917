import {actionsEnumNotifications} from '../../../constants'
import {retrieveNotificationsAPI} from '../../../api'

const retrieveNotificationsRequest = () => ({
  type: actionsEnumNotifications.RETRIEVE_NOTIFICATIONS_REQUEST,
})

export const retrieveNotificationsReceived = (notifications) => ({
  type: actionsEnumNotifications.RETRIEVE_NOTIFICATIONS_COMPLETED,
  notifications,
})

export const retrieveNotifications = (requestParameters) => (dispatch) => {
  dispatch(retrieveNotificationsRequest())
  retrieveNotificationsAPI(requestParameters).then((notifications) => {
    dispatch(retrieveNotificationsReceived(notifications))
    return notifications
  })
}

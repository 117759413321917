import React from 'react'

export const IconMinus = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="#646464"
            viewBox="0 0 24 24"
        >
            <path
                fill="#D4D4D4"
                d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm3.92 10.75h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8a.749.749 0 110 1.5z"
            ></path>
        </svg>
    )
}

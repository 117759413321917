import { actionsEnumCategories } from '../../../constants';
import { getCategoriesByStoreRequestAPI } from '../../../api';

const fetchCategoriesByStoreRequest = () => ({
    type: actionsEnumCategories.FETCH_CATEGORIES_BY_STORE_REQUEST,
});

const fetchCategoriesByStoreReceived = (categories) => ({
    type: actionsEnumCategories.FETCH_CATEGORIES_BY_STORE_RECEIVED,
    categories,
});

export const fetchCategoriesByStore = (props) => (dispatch) => {
    dispatch(fetchCategoriesByStoreRequest());
    getCategoriesByStoreRequestAPI(props).then((categories) => {
        dispatch(fetchCategoriesByStoreReceived(categories));
        return categories;
    });
};

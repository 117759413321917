import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {DashboardPublic} from './DashboardPublic'
import {fetchCategories, fetchProducts} from '../../../redux/actions'
import useAuth from '../../../hooks/session/useAuth'
import {ROLES} from '../../../constants'

export const DashboardPublicContainer = () => {
  const categoryState = useSelector((state) => state.categoryReducer)
  const productState = useSelector((state) => state.productReducer)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {auth} = useAuth()
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [categoriesRequested, setCategoriesRequested] = useState(false)
  const [categoriesSuccessfullyReceived, setCategoriesSuccessfullyReceived] =
    useState(false)
  const [productsRequested, setProductsRequested] = useState(false)
  const [productsSuccessfullyReceived, setProductsSuccessfullyReceived] = useState(false)
  const [publicPage, setPublicPage] = useState(true)
  const [errorApi, setErrorApi] = useState(null)
  const [requested, setRequested] = useState(null)

  useEffect(() => {
    let isVendor = false
    auth?.roles?.map((role) => {
      if (ROLES.Vendor === role) {
        navigate('/my-shop')
        isVendor = true
        return null
      }

      if (ROLES.User === role && !isVendor) {
        navigate('/shop')
      }

      if (!isVendor) {
        setPublicPage(true)
      }
    })
  }, [auth])

  useEffect(() => {
    const receivedProducts = productState?.products
    const errorApi = categoryState?.msg
    if (productsRequested && receivedProducts !== products) {
      setProducts(receivedProducts)
      setProductsRequested(false)
      setProductsSuccessfullyReceived(true)
    }
    if (errorApi) {
      setErrorApi(errorApi)
      setRequested(false)
      setProductsSuccessfullyReceived(false)
    }
    if (
      !productsRequested &&
      products.length === 0 &&
      !productsSuccessfullyReceived &&
      publicPage
    ) {
      dispatch(fetchProducts())
      setProductsRequested(true)
    }
  }, [productState])

  useEffect(() => {
    const receivedCategories = categoryState.categories
    const errorApi = categoryState.msg
    if (categoriesRequested && receivedCategories !== categories) {
      setCategories(receivedCategories)
      setCategoriesRequested(false)
      setCategoriesSuccessfullyReceived(true)
    }
    if (errorApi) {
      setErrorApi(errorApi)
      setRequested(false)
      setCategoriesSuccessfullyReceived(false)
    }
    if (
      !categoriesRequested &&
      categories.length === 0 &&
      !categoriesSuccessfullyReceived
    ) {
      dispatch(fetchCategories())
      setCategoriesRequested(true)
    }
  }, [categoryState])

  const handleProduct = (e) => {
    e.preventDefault()
    navigate(`update-product/:${e.currentTarget.id}`, {replace: true})
    //navigate(0)
  }

  const handleVendor = (e) => {
    e.preventDefault()
    navigate(`/register`, {replace: true})
    //navigate(0)
  }

  return (
    <DashboardPublic
      handleVendor={handleVendor}
      handleProduct={handleProduct}
      categories={categories}
      products={products}
    />
  )
}

export const ButtonRoundSmall = (props) => {
    const { className, type, placeholder, onClick } = props
    const Icon = props.icon

    return (
        <div className={className}>
            <button className={`button-round round-small ${className}`} type={type} placeholder={placeholder} onClick={onClick} >
                <Icon />
            </button>
        </div>
    )
}

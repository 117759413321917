import {useNavigate} from 'react-router-dom'
import {useTranslate} from 'react-redux-multilingual'
import {ButtonSmall} from '../common/commonButtons'
import {IconDelivey} from '../../icons'

export const BasketBanner = () => {
  const navigate = useNavigate()
  const t = useTranslate()

  const handleClick = (e) => {
    e.preventDefault()
    navigate(`/create-basket`, {replace: true})
  }

  return (
    <div className="banner">
      <div className="banner__text">
        <div className="banner__text__title">{t('home_delivery')}</div>
        <div className="banner__text__text">{t('home_delivery_text')}</div>
        <ButtonSmall type="button" text={'Order now'} onClick={handleClick} />
      </div>
      <div className="banner__icon">
        <IconDelivey />
      </div>
    </div>
  )
}

import {
  ButtonNotifications,
  ButtonBack,
  ButtonSettings,
  SearchBar,
  Gallery,
} from '../../../components'

export const ShowStores = (props) => {
  const {
    handleSearch,
    handleSettings,
    handleStoreClick,
    handleNotifications,
    notificationsStatus,
    layerHeader,
    stores,
    searchValue,
  } = props

  return (
    <section className="items">
      <div className="items__top">
        <div className="items__top__left">
          <ButtonBack />
          <h1 className="items__top__left__user-name h4-bold ">User Name</h1>
        </div>
        <div className="items__top__right">
          <ButtonNotifications />
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{layerHeader}</h2>
      </div>
      <div className="items__tools">
        <SearchBar handleSearch={handleSearch}/>
        <ButtonSettings />
      </div>
      <div className="items__gallery">
        <Gallery title="Stores" items={stores} handleClick={handleStoreClick} />
      </div>
    </section>
  )
}

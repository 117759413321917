export const generateItemCart = (auth, item, calculated) => {
  const {itemsCount, itemSubTotal, itemShipping, itemTotal} = calculated
  return {
    userId: auth?.userInfo?.id,
    userName: auth?.userInfo?.firstName,
    userPicture: auth?.userInfo.picture,
    userPictureSignedUrl: auth?.userInfo?.pictureSignedUrl,
    picture: item.picture,
    pictureSignedUrl: item.pictureSignedUrl,
    cartId: item.cartId,
    categoryId: item.categoryId,
    categoryName: item.categoryName,
    price: item.price,
    rating: item.rating,
    measurementUnit: item.measurementUnit,
    itemId: item.itemId,
    itemName: item.itemName,
    itemsCount: 0,
    stock: item.itemsCount,
    subTotal: itemSubTotal || 0,
    shipping: itemShipping || 0,
    total: itemTotal,
    storeId: item.storeId,
    storeName: item.storeName,
    itemsCount: itemsCount,
    storeLocation: item.storeLocation,
    storeAddress: item.storeAddress,
    storePicture: item.storePicture,
    storePictureSignedUrl: item.storePictureSignedUrl,
    storeLocation: '',
    storeAddress: '',
    storePicture: '',
    storePictureSignedUrl: '',
  }
}

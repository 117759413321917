import React from 'react'
import {
  BurgerCarousel,
  ButtonExtraLarge,
  Gallery,
  BasketBanner,
} from '../../../components'
import {IconShop} from '../../../icons'
import {SearchBar} from '../../../components'
import {useTranslate} from 'react-redux-multilingual'

export const DashboardBuyer = (props) => {
  const {handleCreateStore, handleCarousel, categories, products} = props
  const t = useTranslate()

  return (
    <div className="dashboard-public">
      <div className="dashboard-public__top-bar">
        <div className="dashboard-public__top-bar__top">
          <div className="logo">
            <IconShop />
            <h6 className="app-name">La Tiendina</h6>
          </div>
        </div>
        <SearchBar />
      </div>
      <div className="dashboard-public__main">
        <div className="dashboard-public__main__block">
          <BurgerCarousel
            title={t('categories')}
            items={categories}
            handleClick={handleCarousel}
          />
        </div>
        <div className="dashboard-public__main__action">
          <BasketBanner />
        </div>
        <div className="dashboard-public__main__action">
          <ButtonExtraLarge
            type="button"
            text={t('become_a_vendor')}
            handleClick={handleCreateStore}
          />
        </div>
        <div className="dashboard-public__main__block">
          <Gallery title={t('products_for_you')} target="product" items={products} />
        </div>
      </div>
    </div>
  )
}

import React from 'react'

export const IconFlagSp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      version="1.1"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse">
          <use width="100%" height="100%" x="0" y="0" xlinkHref="#g831"></use>
        </clipPath>
        <clipPath id="clipPath839" clipPathUnits="userSpaceOnUse">
          <circle
            cx="248.733"
            cy="245.718"
            r="247.5"
            fill="#ff6464"
            fillOpacity="0.61"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeMiterlimit="4"
            strokeOpacity="0.98"
            strokeWidth="23.454"
            opacity="1"
            paintOrder="markers stroke fill"
          ></circle>
        </clipPath>
      </defs>
      <g clipPath="url(#clipPath839)" transform="matrix(.04848 0 0 .04866 -.06 0)">
        <g id="g831">
          <path fill="#c60b1e" d="M0 0H750V500H0z"></path>
          <path fill="#ffc400" d="M0 125H750V375H0z"></path>
        </g>
      </g>
    </svg>
  )
}

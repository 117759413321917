import {socket} from '../utils/socket'

const asyncIO = (eventName, data, method) => {
  return new Promise(function (resolve, reject) {
    switch (method) {
      case 'emit':
        socket.emit(eventName, data, (response) => {
          return resolve(response)
        })
        setTimeout(reject, 1000)
        break

      case 'on':
        return socket.on(eventName, async (response, cb) => {
          cb('received')
          return resolve(response)
        })
        break
    }
  })
}

const createChatRoomAPI = async (requestParameters) => {
  const {
    origin,
    itemId,
    itemName,
    userName,
    userId,
    userPicture,
    userPictureSignedUrl,
    receiverId,
    receiverName,
    receiverPicture,
    receiverPictureSignedUrl,
  } = requestParameters

  const newChatRoom = {
    chatRoomName: `${origin}-${itemId}-${itemName}-${userName}-${receiverName}`,
    type: `${origin}-${itemId}-${itemName}`,
    participants: [userId, receiverId],
    userName,
    userId,
    userPicture,
    userPictureSignedUrl,
    receiverId,
    receiverName,
    receiverPicture,
    receiverPictureSignedUrl,
  }
  try {
    const chatRoom = await asyncIO('create chat room', newChatRoom, 'emit')
    const chatRoomWithId = () => {
      const {_id, ...rest} = chatRoom
      return {id: _id, ...rest}
    }
    return chatRoomWithId()
  } catch (err) {
    return err
  }
}

const getChatRoomsAPI = async (requestParameters) => {
  try {
    const chatRooms = await asyncIO('get chat rooms', requestParameters, 'emit')
    return chatRooms.map((chatRoom) => {
      const {_id, ...rest} = chatRoom
      return {id: _id, ...rest}
    })
  } catch (err) {
    return err
  }
}

const joinChatRoomAPI = async (requestParameters) => {
  try {
    const chatRoom = await asyncIO('join chat room', requestParameters, 'emit')
    const chatRoomWithId = () => {
      const {_id, ...rest} = chatRoom
      return {id: _id, ...rest}
    }
    const response = chatRoomWithId()
    return response
  } catch (err) {
    return err
  }
}

const joinChatRoomsAPI = async (requestParameters) => {
  try {
    const chatRooms = await asyncIO('join chat rooms', requestParameters, 'emit')
    const chatRoomsWithId = () => {
      const {_id, ...rest} = chatRooms
      return {id: _id, ...rest}
    }
    const response = chatRoomsWithId()
    return response.msg
  } catch (err) {
    return err
  }
}

const getNewMessagesAPI = async (requestParameters) => {
  try {
    const newMessages = await asyncIO('get new messages', requestParameters, 'emit')
    return newMessages.map((newMessage) => {
      const {_id, ...rest} = newMessage
      return {id: _id, ...rest}
    })

    //socket.emit('get new messages', requestParameters, (newMessages) => {
    //  const newMessagesWithId = newMessages?.map((newMessage) => {
    //    const { _id, ...rest } = newMessage
    //    return { id: _id, ...rest }
    //  })
    //  return newMessagesWithId
    //})
  } catch (err) {
    return err
  }
}

const getMostRecentsMessagesAPI = async (requestParameters) => {
  const messages = await asyncIO('get most recent messages', requestParameters, 'emit')
  const messagesWithId = messages.map((newMessage) => {
    const {_id, ...rest} = newMessage
    return {id: _id, ...rest}
  })
  return messagesWithId
  //await socket.emit('get most recent messages', requestParameters, (newMessages) => {
  //  const newMessagesWithId = newMessages?.map((newMessage) => {
  //    const { _id, ...rest } = newMessage
  //    return { id: _id, ...rest }
  //  })
  //  return newMessagesWithId
  //})
}

const sendMessageAPI = async (requestParameters) => {
  try {
    const sentMessage = await asyncIO('send message', requestParameters, 'emit')
    const sentMessageWithId = () => {
      const {_id, ...rest} = sentMessage
      return {id: _id, ...rest}
    }
    const messageWithId = sentMessageWithId()
    return messageWithId

    //socket.emit('send messages', requestParameters, (newMessage) => {
    //  const newMessageWithId = ((newMessage) => {
    //    const { _id, ...rest } = newMessage
    //    return { id: _id, ...rest }
    //  })
    //  return newMessageWithId
    //})
  } catch (err) {
    return err
  }
}

const readNewMessageAPI = async (requestParameters) => {
  try {
    const newMessages = await asyncIO('read new message', requestParameters, 'emit')
    const newMessagesWithId = newMessages?.map((newMessage) => {
      const {_id, ...rest} = newMessage
      return {id: _id, ...rest}
    })
    return newMessagesWithId
  } catch (err) {
    return err
  }
}

const receiveMessageAPI = async (userOrStoreId) => {
  try {
    //await asyncIO('receive message', requestParameters, 'on').then((receivedMessage) => {
    //  return receivedMessage
    //})

    socket.on('receive message ping', (receiverId, callback) => {
      if (userOrStoreId === receiverId) {
        callback(socket.id)
      }
    })

    socket.on('receive message', (newMessage, cb) => {
      cb('received')
      const newMessageWithId = () => {
        const {_id, ...rest} = newMessage
        return {id: _id, ...rest}
      }
      return newMessageWithId
    })
    //const newMessage = await asyncIO('receive message', requestParameters, 'on')
    //  const newMessageWithId = (() => {
    //    const { _id, ...rest } = newMessage
    //    return { id: _id, ...rest }
    //  })
    //  return newMessageWithId
  } catch (err) {
    return err
  }
}

export {
  createChatRoomAPI,
  joinChatRoomAPI,
  joinChatRoomsAPI,
  getChatRoomsAPI,
  getNewMessagesAPI,
  getMostRecentsMessagesAPI,
  sendMessageAPI,
  readNewMessageAPI,
  receiveMessageAPI,
}

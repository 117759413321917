import {CardItemOrder} from '../cards'

export const GalleryOrder = (props) => {
  const {auth, title, orders, handleOpenOrder} = props
  return (
    <div className="gallery">
      <h3 className="gallery__title h2-bold">{title}</h3>
      <div className="gallery__cart">
        {orders?.length ? (
          orders.map((order) => {
            const key = Math.random()
            return (
              <div className="gallery__cart__order" key={key}>
                <CardItemOrder
                  auth={auth}
                  order={order}
                  handleOpenOrder={handleOpenOrder}
                />
              </div>
            )
          })
        ) : (
          <h6 className="h5-bold"> No orders to show</h6>
        )}
      </div>
    </div>
  )
}

import { actionsEnumCart } from '../../../constants';

export const removeUnitFromCart = (id) => (dispatch) => {
  dispatch(removeItemUnitFromCart(id));
  dispatch(itemUnitRemovedFromCart());
};

const removeItemUnitFromCart = (id) => ({
  type: actionsEnumCart.REMOVE_ITEM_UNIT_FROM_CART,
  payload: { id: id },
});

const itemUnitRemovedFromCart = () => ({
  type: actionsEnumCart.REMOVED_UNIT_FROM_CART,
});
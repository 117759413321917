export const Input = (props) => {
  const { label, id, name, value, refName, handleChange, type, handleBlur, placeholder
  } = props
  const onBlur = (e) => {
    if (handleBlur) {
      handleBlur(e)
    }
  }
  return (
    <div className="input">
      <label htmlFor={name} className="text-m-s">
        {label}
      </label>
      <input
        type={type}
        id={id}
        innerref={refName}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        name={name}
        className="text-m-l"
        onBlur={onBlur}
      />
    </div>
  )
}

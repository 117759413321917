import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonBack,
  ButtonExtraLarge,
  InputLarge,
  InputTextArea,
} from '../../../components'
import {BasketTable} from '../../../components/tables/BasketTable'

export const CreateBasketOrder = (props) => {
  const {
    handleSubmit,
    handleDelivery,
    handlePickup,
    handleDeliveryDateRangeStartChange,
    handleDeliveryDateRangeEndChange,
    handleDeliveryTimeRangeStartChange,
    handleDeliveryTimeRangeEndChange,
    buyerPictureSignedUrl,
    basketItems,
    buyerName,
    buyerId,
    storeId,
    storeName,
    buyerLocation,
    buyerAddress,
    storePictureSignedUrl,
    storeAddress,
    storeLocation,
    delivery,
    pickup,
    deliveryDateRangeStart,
    deliveryDateRangeEnd,
    deliveryTimeRangeStart,
    deliveryTimeRangeEnd,
    fields,
    form,
    errorDeliveryDateRange,
    errorDeliveryTimeRange,
    bannerMessage,
  } = props.parameters
  const t = useTranslate()

  return (
    <section className="order">
      {bannerMessage ? <h6 className="banner-message">{bannerMessage}</h6> : null}
      <div className="order__top">
        <div className="order__top__left">
          <ButtonBack />
          <h1 className="order__top__left__user-name h4-bold ">{t('user_name')}</h1>
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{t('order')}</h2>
      </div>
      <div>
        <div className="order__user">
          <img className="order_user_img img-user-small" src={buyerPictureSignedUrl} />
          <div className="order__user__left">
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s ">
                {t('buyer_name')}
              </h4>
              <h3 name="buyerName" className="order__user__left__field__text text-s ">
                {buyerName}
              </h3>
            </div>
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('buyer_id')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{buyerId}</h3>
            </div>
          </div>

          <div className="order__user__right">
            {/*<div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">Buyer address:</h4>
              <h3 className="order__user__left__field__text text-s ">{buyerAddress}</h3>
            </div>*/}
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('buyer_location')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{buyerLocation}</h3>
            </div>
          </div>
        </div>

        <div className="order__user">
          <img className="order_user_img img-user-small" src={storePictureSignedUrl} />
          <div className="order__user__left">
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_name')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{storeName}</h3>
            </div>
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_id')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{storeId}</h3>
            </div>
          </div>
          <div className="order__user__right">
            {/*<div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">Store address</h4>
              <h3 className="order__user__left__field__text text-s ">{storeAddress}</h3>
            </div>*/}
            <div className="order__user__left__field">
              <h4 className="order__user__left__field__label text-m-s">
                {t('store_location')}
              </h4>
              <h3 className="order__user__left__field__text text-s ">{storeLocation}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="order__details">
        <form>
          <fieldset className="order__details__radio flex-column">
            <div>
              <input
                type="radio"
                id="delivery"
                name="delivery"
                checked={delivery}
                onChange={handleDelivery}
              />
              <label className="order__details__label text-m-s" htmlFor="delivery">
                {t('delivery')}
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="pickup"
                name="pickup"
                checked={pickup}
                onChange={handlePickup}
              />
              <label className="order__details__label text-m-s" htmlFor="pickup">
                {t('pickup')}
              </label>
            </div>
          </fieldset>
          <div className="order__details__fields">
            {errorDeliveryDateRange ? (
              <h6 className="input-error">Error: {errorDeliveryDateRange}</h6>
            ) : null}
            <InputLarge
              textHelper={t('delivery_date_range_start')}
              id="deliveryDateRangeStart"
              name="deliveryDateRangeStart"
              value={deliveryDateRangeStart}
              type="date"
              handleChange={handleDeliveryDateRangeStartChange}
            />
            <InputLarge
              textHelper={t('delivery_date_range_end')}
              id="deliveryDateRangeEnd"
              name="deliveryDateRangeEnd"
              value={deliveryDateRangeEnd}
              type="date"
              handleChange={handleDeliveryDateRangeEndChange}
            />
            {errorDeliveryTimeRange ? (
              <h6 className="input-error">Error: {errorDeliveryTimeRange}</h6>
            ) : null}
            <InputLarge
              textHelper={t('delivery_date_time_start')}
              id="deliveryTimeRangeStart"
              name="deliveryTimeRangeStart"
              value={deliveryTimeRangeStart}
              type="time"
              handleChange={handleDeliveryTimeRangeStartChange}
            />
            <InputLarge
              textHelper={t('delivery_date_time_end')}
              id="deliveryTimeRangeEnd"
              name="deliveryTimeRangeEnd"
              value={deliveryTimeRangeEnd}
              type="time"
              handleChange={handleDeliveryTimeRangeEndChange}
            />

            {/*<InputLarge
              textHelper="Delivery address"
              id="deliveryAddress"
              name="deliveryAddress"
              value={fields.deliveryAddress}
              handleChange={form.handleChangeEvent}
            />*/}
            <div className="order__details__fields__location ">
              <InputLarge
                textHelper={t('delivery_location')}
                id="deliveryLocation"
                name="deliveryLocation"
                value={fields.deliveryLocation}
                handleChange={form.handleChangeEvent}
              />
            </div>

            <InputTextArea
              textHelper={t('delivery_note')}
              id="deliveryNote"
              name="deliveryNote"
              value={fields.deliveryNote}
              handleChange={form.handleChangeEvent}
            />
          </div>
        </form>
        <div className="order__cart__bottom">
          <h3 className="text-m-l">{t('products')}</h3>
          <div className="order__cart__bottom__details">
           
            {basketItems.length ? (
              <BasketTable
                listOfItems={basketItems}
                handleEditProductNote={null}
                handleRemoveItemFromList={null}
              />
            ) : null}
      
            <ButtonExtraLarge
              type="submit"
              text="Order Now"
              handleClick={handleSubmit}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useFormInputValidation} from 'react-form-input-validation'
import useAuth from '../../../hooks/session/useAuth'
import {
  createOrder,
  createCart,
  deleteOrder,
  deleteCart,
  removeGroup,
  removeBasketItemsFromOrder,
  removeStoreBasket,
} from '../../../redux/actions'
import {CreateOrder} from './CreateOrder'
import {getLocationByClicking, geoLocation} from '../../../utils'
import {CreateBasketOrder} from './CreateBasketOrder'

export const CreateOrderContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartState = useSelector((state) => state.cartReducer)
  const orderState = useSelector((state) => state.orderReducer)
  const storeState = useSelector((state) => state.storeReducer)
  const {auth} = useAuth()
  const [cart, setCart] = useState(null)
  const [order, setOrder] = useState(null)
  const [store, setStore] = useState(null)
  const [storeBasket, setStoreBasket] = useState(null)
  const [isStoreBasket, setIsStoreBasket] = useState(false)
  const [basketItems, setBasketItems] = useState([])
  const [cartId, setCartId] = useState(null)
  const [cartStoreId, setCartStoreId] = useState('')
  const [buyerPicture, setBuyerPicture] = useState('')
  const [buyerPictureSignedUrl, setBuyerPictureSignedUrl] = useState('')
  const [buyerName, setBuyerName] = useState('')
  const [buyerId, setBuyerId] = useState('')
  const [buyerAddress, setBuyerAddress] = useState('')
  const [buyerLocation, setBuyerLocation] = useState('')
  const [storePicture, setStorePicture] = useState('')
  const [storePictureSignedUrl, setStorePictureSignedUrl] = useState('')
  const [storeName, setStoreName] = useState('')
  const [storeId, setStoreId] = useState('')
  const [storeAddress, setStoreAddress] = useState('')
  const [storeLocation, setStoreLocation] = useState('')
  const [orderItems, setOrderItems] = useState([])
  const [subTotal, setSubTotal] = useState(null)
  const [total, setTotal] = useState(null)
  const [shipping, setShipping] = useState(null)
  const [cartCreateRequested, setCartCreateRequested] = useState(false)
  const [cartCreatedSuccessfully, setCartCreatedSuccessfully] = useState(false)
  const [orderCreateRequested, setOrderCreateRequested] = useState(false)
  const [orderCreatedSuccessfully, setOrderCreatedSuccessfully] = useState(false)
  const [bannerMessage, setBannerMessage] = useState('')
  const [delivery, setDelivery] = useState(true)
  const [pickup, setPickup] = useState(false)
  const [deliveryDateRangeStart, setDeliveryDateRangeStart] = useState('')
  const [deliveryDateRangeEnd, setDeliveryDateRangeEnd] = useState('')
  const [errorDeliveryDateRange, setErrorDeliveryDateRange] = useState(null)
  const [deliveryTimeRangeStart, setDeliveryTimeRangeStart] = useState('')
  const [deliveryTimeRangeEnd, setDeliveryTimeRangeEnd] = useState('')
  const [errorDeliveryTimeRange, setErrorDeliveryTimeRange] = useState(null)

  const navigatetoShowOrder = () => {
    setTimeout(() => {
      navigate(`/show-order/${orderState?.order?.id}`)
    }, 3000)
  }

  const getCurrentDate = () => {
    const newDate = new Date()
    const day = newDate.getDate()
    const month = newDate.getMonth() + 1
    const year = newDate.getFullYear()
    return `${day}-${month}-${year}`
  }

  const populateStoreVariables = (newStore) => {
    setStorePicture(newStore?.picture)
    setStorePictureSignedUrl(newStore?.pictureSignedUrl)
    setStoreName(newStore?.name)
    setStoreId(newStore?.id)
    setStoreAddress(newStore?.address)
    setStoreLocation(newStore?.location)
    setStore(newStore)
  }

  const checkDateValidation = (startDate, endDate) => {
    if (
      new Date(startDate) > new Date(endDate) ||
      new Date(endDate) < new Date(startDate) ||
      getCurrentDate() > new Date(startDate) ||
      getCurrentDate() > new Date(endDate)
    ) {
      setErrorDeliveryDateRange('Invalid date range')
    } else {
      setErrorDeliveryDateRange(null)
    }
  }

  const checkTimeValidation = (startTime, endTime) => {
    if (startTime && endTime && startTime > endTime) {
      setErrorDeliveryTimeRange('Invalid time range')
    } else {
      setErrorDeliveryTimeRange(null)
    }
  }

  const [fields, errors, form] = useFormInputValidation(
    {
      deliveryStatus: 'Pending',
      deliveryAddress: null,
      deliveryLocation: null,
      deliveryNote: null,
    },
    {
      deliveryStatus: 'required',
      deliveryAddress: 'required',
      deliveryLocation: 'required',
      deliveryNote: 'required',
    },
  )

  const orderParameters = {
    fields: {
      ...fields,
      buyerId: buyerId,
      buyerName: buyerName,
      buyerAddress: buyerAddress,
      buyerLocation: buyerLocation,
      buyerPicture: buyerPicture,
      buyerPictureSignedUrl: buyerPictureSignedUrl,
      storeName: storeName,
      storeId: storeId,
      storeAddress: storeAddress,
      storeLocation: storeLocation,
      storePicture: storePicture,
      storePictureSignedUrl: storePictureSignedUrl,
      cartId: cartId,
      basketItems: basketItems,
      orderItems: orderItems,
      subTotal: subTotal,
      total: total,
      shipping: shipping,
      delivery: delivery,
      pickup: pickup,
      deliveryDateRangeEnd: deliveryDateRangeEnd,
      deliveryDateRangeStart: deliveryDateRangeStart,
      deliveryTimeRangeEnd: deliveryTimeRangeEnd,
      deliveryTimeRangeStart: deliveryTimeRangeStart,
      pending: true,
      accepted: false,
      rejected: false,
      delivered: false,
    },
  }

  useEffect(() => {
    if (auth) {
      setBuyerPicture(auth?.userInfo?.picture)
      setBuyerPictureSignedUrl(auth?.userInfo?.pictureSignedUrl)
      setBuyerName(auth?.userInfo?.firstName)
      setBuyerId(auth?.userInfo?.id)
      setBuyerAddress(auth?.userInfo?.address)
      setBuyerLocation(auth?.userInfo?.location)
    }
  }, [auth])

  useEffect(() => {
    const newStore = storeState?.stores.find((store) => store.id === cartStoreId)
    if (newStore !== store && cartStoreId) {
      populateStoreVariables(newStore)
    }
  }, [cartStoreId])

  useEffect(() => {
    if (!storeBasket?.length && storeState.storeBasket) {
      setStoreBasket(storeState.storeBasket)
      setIsStoreBasket(true)
      populateStoreVariables(storeState.storeBasket)
    }
  }, [orderState, storeState])

  useEffect(() => {
    const newCart = cartState?.cart
    if (cartCreateRequested && !cartCreatedSuccessfully && newCart?.id) {
      setCartCreatedSuccessfully(true)
      setCartCreateRequested(false)
      populateCart(newCart)
    }

    if (newCart?.id !== cart?.id) {
      populateCart(newCart)

      if (newCart.items.length === 0) {
        dispatch(deleteOrder({auth, orderId: order?.id}))
        navigate('/shop')
      }
    }
    if (newCart?.userId && !cartCreateRequested && !newCart?.id) {
      setCartCreateRequested(true)
      setCartCreatedSuccessfully(false)
      dispatch(createCart({auth, cart: newCart}))
    }
  }, [cart])

  useEffect(() => {
    if (
      !order &&
      orderState?.order?.basketItems?.length &&
      orderState?.order?.basketItems?.length !== order
    ) {
      setBasketItems(orderState?.order?.basketItems)
    }
    if (!order && orderState?.order !== order) {
      setOrder(orderState?.order)
    }
    if (orderCreateRequested && !orderCreatedSuccessfully && orderState?.order?.id) {
      setOrderCreateRequested(false)
      setOrderCreatedSuccessfully
      setBannerMessage('Order created successfully')
      if (!cart?.groupsByStore?.length && !isStoreBasket) {
        dispatch(deleteCart({auth, cartId: cart?.id}))
        dispatch(removeGroup(cart?.groupsByStore[0]))
        navigatetoShowOrder()
      }
      if (isStoreBasket && !basketItems.length) {
        dispatch(removeBasketItemsFromOrder())
        dispatch(removeStoreBasket())
        navigatetoShowOrder()
      }
    }
  }, [orderState])

  useEffect(() => {
    if (cartState?.cart?.groupsByStore[0]?.storeId) {
      setCart(cartState?.cart)
      cart?.groupsByStore?.length && setOrderItems(cart?.groupsByStore[0]?.storeItems)
    }
  }, [cartState])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = await form.validate(e)
    if (isValid && !orderCreateRequested) {
      const requestParameters = {
        orderParameters,
        auth,
      }
      setOrderCreateRequested(true)
      dispatch(createOrder(requestParameters))
      if (isStoreBasket) {
        setBasketItems([])
      }
    }
  }

  const handleDelivery = (e) => {
    setDelivery(e.target.checked)
    setPickup(!e.target.checked)
  }

  const populateCart = (newCart) => {
    setSubTotal(newCart?.subTotal)
    setTotal(newCart?.total)
    setShipping(newCart?.shipping)
    setOrderItems(newCart.groupsByStore[0].storeItems)
    setCartId(newCart?.id)
    setCart(newCart)
    setCartStoreId(newCart.groupsByStore[0].storeItems[0].storeId)
  }

  const handlePickup = (e) => {
    setPickup(e.target.checked)
    setDelivery(!e.target.checked)
  }

  const handleDeliveryDateRangeStartChange = (e) => {
    setDeliveryDateRangeStart(e.target.value)
    checkDateValidation(e.target.value, deliveryDateRangeEnd)
  }

  const handleDeliveryDateRangeEndChange = (e) => {
    setDeliveryDateRangeEnd(e.target.value)
    checkDateValidation(deliveryDateRangeStart, e.target.value)
  }

  const handleDeliveryTimeRangeStartChange = (e) => {
    setDeliveryTimeRangeStart(e.target.value)
    checkTimeValidation(e.target.value, deliveryTimeRangeEnd)
  }

  const handleDeliveryTimeRangeEndChange = (e) => {
    setDeliveryTimeRangeEnd(e.target.value)
    checkTimeValidation(deliveryTimeRangeStart, e.target.value)
  }

  const handleGetLocationByClicking = () => {
    const location = getLocationByClicking()
    setBuyerLocation(location)
  }

  const handleGetLocationFromNetworkProvider = () => {
    const location = geoLocation()
    setBuyerLocation(location)
  }

  const props = {
    handleSubmit,
    handleDelivery,
    handlePickup,
    handleDeliveryDateRangeStartChange,
    handleDeliveryDateRangeEndChange,
    handleDeliveryTimeRangeStartChange,
    handleDeliveryTimeRangeEndChange,
    handleGetLocationByClicking,
    handleGetLocationFromNetworkProvider,
    basketItems,
    buyerPictureSignedUrl,
    buyerName,
    buyerId,
    storeId,
    storeName,
    buyerLocation,
    buyerAddress,
    storePictureSignedUrl,
    storeAddress,
    storeLocation,
    delivery,
    pickup,
    deliveryDateRangeStart,
    deliveryDateRangeEnd,
    errorDeliveryDateRange,
    deliveryTimeRangeStart,
    deliveryTimeRangeEnd,
    errorDeliveryTimeRange,
    subTotal,
    total,
    shipping,
    cart,
    fields,
    form,
    errors,
    bannerMessage,
  }

  return isStoreBasket ? (
    <CreateBasketOrder parameters={props} />
  ) : (
    <CreateOrder parameters={props} />
  )
}

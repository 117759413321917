import { actionsEnumChat } from '../../../constants';
import { joinChatRoomAPI } from '../../../api';

const joinChatRoomRequest = () => ({
    type: actionsEnumChat.JOIN_CHAT_ROOM_REQUEST,
});

const joinChatRoomReceived = (chatRoom) => ({
    type: actionsEnumChat.JOIN_CHAT_ROOM_COMPLETED,
    chatRoom,
});

export const joinChatRoom = (requestParameters) => (dispatch) => {
    dispatch(joinChatRoomRequest());
    joinChatRoomAPI(requestParameters).then((chatRoom) => {
        dispatch(joinChatRoomReceived(chatRoom));
        return chatRoom;
    });
};

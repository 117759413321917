import { ButtonRoundSmall } from '../common';
import { StatusPilot } from './StatusPilot'
import { IconChat } from '../../icons/IconChat'

export const ButtonChats = (props) => {
    const { handleClick, status } = props
    return (
        <div className="button-notifications-container">
            <StatusPilot status={status} />
            <ButtonRoundSmall className="button-round-notifications" icon={IconChat} type="button" placeholder={null} onClick={handleClick} />
        </div>
    );
}
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {SearchBarComponent} from './SearchBarComponent'
import {useNavigate} from 'react-router-dom'
import useAuth from '../../hooks/session/useAuth'
import {search} from '../../redux'

export const SearchBar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchRequested, setSearchRequested] = useState(false)
  const searchState = useSelector((state) => state.searchReducer)

  useEffect(() => {
    if (searchRequested) {
      setSearchRequested(false)
      navigate('/search-result', {replace: true})
    }
  }, [searchState])

  const handleSearch = (e) => {
    if (searchQuery) {
      dispatch(search({auth, searchQuery}))
      setSearchRequested(true)
    }
  }

  const handleChange = (e) => {
    const {value} = e.target
    setSearchQuery(value)
  }
  return (
    <SearchBarComponent
      searchQuery={searchQuery}
      handleChange={handleChange}
      handleSearch={handleSearch}
    />
  )
}

import {actionsEnumOrder} from '../../../constants'
import {resumeOrderAPI} from '../../../api'

const resumeOrderRequest = () => ({
  type: actionsEnumOrder.RESUME_ORDER_REQUEST,
})

const resumeOrderReceived = (order) => ({
  type: actionsEnumOrder.ACCEPT_ORDER_RECEIVED,
  order,
})

export const resumeOrder = (requestParameters) => (dispatch) => {
  dispatch(resumeOrderRequest())
  resumeOrderAPI(requestParameters).then((order) => {
    dispatch(resumeOrderReceived(order))
    return order
  })
}

import {ButtonSmall} from '../common'

export const BurgerCarousel = (props) => {
  const {title, items, handleClick} = props

  return (
    <div className="burger-carousel-frame">
      <h3 className="burger-carousel__title">{title}</h3>
      <div className="burger-carousel">
        <div className="burger-carousel__items">
          {items?.length > 0
            ? items.map((item) => {
                const key = Math.random() + item.name
                return (
                  <div className="burger-carousel__items__item" key={key}>
                    <ButtonSmall
                      type={item.type}
                      text={item.name}
                      icon={item.icon}
                      onClick={handleClick}
                    />
                  </div>
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

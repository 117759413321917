import React, {useState} from 'react'
import {ButtonSmall} from '../common/commonButtons'
import {useTranslate} from 'react-redux-multilingual'

const ModalConfirmation = ({children, description}) => {
  const [open, setOpen] = useState(false)
  const [callback, setCallback] = useState(null)
  const t = useTranslate()

  const show = (callback) => (event) => {
    event.preventDefault()
    setOpen(true)
    event = {
      ...event,
      target: {...event.target, value: event.target.value},
    }
    setCallback({
      run: () => callback(event),
    })
  }

  const hide = () => {
    setCallback(null)
    setOpen(false)
  }

  const confirm = () => {
    console.log('confirm')
    callback.run()
    hide()
  }

  return (
    <>
      {children(show)}
      {open && (
        <>
          <div className="modal-confirmation">
            <div className="modal-confirmation__dialog">
              <p className="modal-confirmation__dialog__description">{description}</p>
              <div className="modal-confirmation__dialog__action">
                <ButtonSmall type="button" onClick={hide} text={t('cancel')} />
                <ButtonSmall type="button" onClick={confirm} text={t('continue')} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {ModalConfirmation}

import * as React from "react"

export const IconCart = (props) => (
    <div className="button-icon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width={20}
            height={20}
            version="1.1"
            viewBox="0 0 512 512"
            fill="#646464"
        >
            <path
                fill="#646464"
                d="M166 300.004h271.004c6.71 0 12.598-4.438 14.414-10.883l60.004-210.004c1.289-4.527.406-9.39-2.434-13.152A15.034 15.034 0 0 0 497.004 60H131.37l-10.723-48.25A15 15 0 0 0 106 0H15C6.71 0 0 6.71 0 15c0 8.293 6.71 15 15 15h78.96l54.169 243.75C132.19 280.68 121 296.543 121 315.004c0 24.812 20.187 45 45 45h271.004c8.293 0 15-6.707 15-15 0-8.29-6.707-15-15-15H166c-8.262 0-15-6.723-15-15s6.738-15 15-15zM151 405.004c0 24.816 20.188 45 45.004 45 24.812 0 45-20.184 45-45 0-24.813-20.188-45-45-45-24.816 0-45.004 20.187-45.004 45zM362.004 405.004c0 24.816 20.187 45 45 45 24.816 0 45-20.184 45-45 0-24.813-20.184-45-45-45-24.813 0-45 20.187-45 45zm0 0" />
        </svg>
    </div>
)

import { actionsEnumOrder } from '../../../constants';
import { closeOrderAPI } from '../../../api';

const closeOrderRequest = () => ({
  type: actionsEnumOrder.CLOSE_ORDER_REQUEST,
});

const closeOrderReceived = (order) => ({
  type: actionsEnumOrder.CLOSE_ORDER_RECEIVED,
  order,
});

export const closeOrder = (requestParameters) => (dispatch) => {
  dispatch(closeOrderRequest());
  closeOrderAPI(requestParameters).then((order) => {
    dispatch(closeOrderReceived(order));
    return order;
  });
};

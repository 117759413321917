import { actionsEnumOrder } from '../../../constants';
import { createOrderAPI } from '../../../api';

const createOrderRequest = () => ({
  type: actionsEnumOrder.CREATE_ORDER_REQUEST,
});

const createOrderReceived = (order) => ({
  type: actionsEnumOrder.CREATE_ORDER_RECEIVED,
  order,
});

export const createOrder = (requestParameters) => (dispatch) => {
  dispatch(createOrderRequest());
  createOrderAPI(requestParameters).then((order) => {
    dispatch(createOrderReceived(order));
    return order;
  });
};

import { actionsEnumProducts } from '../../../constants';
import { updateProductAPI } from '../../../api';

const updateProductRequest = () => ({
  type: actionsEnumProducts.UPDATE_PRODUCT_REQUEST,
});

const updateProductReceived = (product) => ({
  type: actionsEnumProducts.UPDATE_PRODUCT_RECEIVED,
  product,
});

export const updateProduct = (requestParameters, id) => (dispatch) => {
  dispatch(updateProductRequest());
  updateProductAPI(requestParameters, id).then((product) => {
    dispatch(updateProductReceived(product));
    return product;
  });
};

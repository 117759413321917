import React from 'react'
import {useTranslate} from 'react-redux-multilingual'
import {BurgerCarousel, BannerCarousel, ButtonLarge, Gallery} from '../../../components'
import {IconShop} from '../../../icons'

export const DashboardPublic = (props) => {
  const {handleVendor, handleCarousel, handleBanner, banners, categories, products} =
    props
  const t = useTranslate()

  return (
    <div className="dashboard-public">
      <div className="dashboard-public__top-bar">
        <div className="dashboard-public__top-bar__top">
          <div className="logo">
            <IconShop />
            <h6 className="app-name">La Tiendina</h6>
          </div>
        </div>
      </div>
      <div className="dashboard-public__main">
        <div className="dashboard-public__main__block">
          <BurgerCarousel
            title={t('categories')}
            items={categories}
            handleClick={handleCarousel}
          />
        </div>
        <div className="dashboard-public__main__block">
          <BannerCarousel title={null} items={banners} handleClick={handleBanner} />
        </div>
        <div className="dashboard-public__main__action">
          <h6 className="text-m-l">{t('you_can_open_your_own_store')}</h6>
          <br></br>
          <ButtonLarge
            type="button"
            products
            text={t('become_a_vendor')}
            handleClick={handleVendor}
          />
        </div>
        <div className="dashboard-public__main__block">
          <Gallery title={t('products')} items={products} target="product" />
        </div>
      </div>
    </div>
  )
}

import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useTranslate} from 'react-redux-multilingual'
import {ButtonLarge} from '../common/commonButtons'
import {postRatingApi} from '../../api'
import {fetchOrders} from '../../redux'
import {IconStarGold, IconStarGrey} from '../../icons'

export const Rating = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {buyerId, buyerName, storeId, storeName, orderItems, orderId, auth} = props
  const [rating, setRating] = useState(5)
  const [message, setMessage] = useState(null)
  const [stars, setStars] = useState([])
  const isBuyer = auth?.userInfo?.id === buyerId
  const t = useTranslate()

  useEffect(() => {
    calculateStars(rating)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const requestParameters = {
      raterId: null,
      targetId: null,
      orderId: null,
      rate: null,
      targetType: null,
      auth: auth,
    }

    if (isBuyer) {
      requestParameters.raterId = buyerId
      requestParameters.targetId = storeId
      requestParameters.orderId = orderId
      requestParameters.rate = rating
      requestParameters.targetType = 'store'
    }

    if (!isBuyer) {
      requestParameters.raterId = storeId
      requestParameters.targetId = buyerId
      requestParameters.orderId = orderId
      requestParameters.rate = rating
      requestParameters.targetType = 'buyer'
    }

    try {
      const message = await postRatingApi(requestParameters)
      setMessage(message)
      console.log(message)
      dispatch(
        fetchOrders({
          auth: auth,
        }),
      )
      setTimeout(navigate('/'), 4000)
    } catch (err) {
      return setMessage(err?.message)
    }
  }

  const handleAddStar = (e) => {
    e.preventDefault()
    const ratingUpdated = parseInt(e.currentTarget.id) + 1
    rating < 5 && ratingUpdated < 5 && setRating(ratingUpdated)
    calculateStars(ratingUpdated)
  }

  const handleRemoveStar = (e) => {
    e.preventDefault()
    const ratingUpdated = parseInt(e.currentTarget.id) - 1
    rating > 0 && ratingUpdated > 0 && setRating(ratingUpdated)
    calculateStars(ratingUpdated)
  }

  const calculateStars = (rating) => {
    const starsUpdated = []
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        starsUpdated.push('gold')
      } else {
        starsUpdated.push('grey')
      }
    }
    setStars(starsUpdated)
  }

  const StarPainting = () => {
    return (
      <div className="stars">
        {stars?.map((star, index) =>
          star === 'gold' ? (
            <div className="" id={index} key={index} onClick={handleRemoveStar}>
              <IconStarGold />
            </div>
          ) : (
            <div className="" id={index} key={index} onClick={handleAddStar}>
              <IconStarGrey />
            </div>
          ),
        )}
      </div>
    )
  }

  const Message = () => {
    return <div className="rating-header h2-bold">{message}</div>
  }

  const Panel = () => {
    return (
      <>
        <div className="rating-header h2-bold">
          {' '}
          {`${t('the')} ${orderItems[0].itemName} ${t('delivered')} ${buyerName}`}
        </div>
        <h5 className="card__title h4-bold">{t('rate_transaction')}</h5>
        <StarPainting />
        <ButtonLarge type="button" text="Rate the service" handleClick={handleSubmit} />
      </>
    )
  }

  return (
    <div className="modal-rating">
      <div className="modal-rating__dialog">{message ? <Message /> : <Panel />}</div>
    </div>
  )
}

import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DashboardStore} from './DashboardStore'
import {
  fetchStore,
  fetchProducts,
  fetchMyProducts,
  fetchProductsByStore,
  fetchNewOrders,
  fetchOrders,
  fetchCategoriesByStore,
} from '../../../redux'
import {actionsEnumCategories} from '../../../constants'
import useAuth from '../../../hooks/session/useAuth'
import {useNavigate} from 'react-router-dom'

export const DashboardStoreContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const categoryState = useSelector((state) => state.categoryReducer)
  const productState = useSelector((state) => state.productReducer)
  const storeState = useSelector((state) => state.storeReducer)
  const orderState = useSelector((state) => state.orderReducer)
  const chatState = useSelector((state) => state.chatReducer)
  const {auth} = useAuth()
  const [store, setStore] = useState(storeState?.store)
  const [storeId, setStoreId] = useState(auth?.userInfo?.storeId)
  const [indexStart, setIndexStart] = useState(0)
  const [indexEnd, setIndexEnd] = useState(10)
  const [reviews, setReviews] = useState([])
  const [chatNewMessages, setChatNewMessages] = useState([])
  const [orders, setOrders] = useState(orderState?.orders)
  const [newOrders, setNewOrders] = useState(orderState?.newOrders)
  const [name, setName] = useState(storeState?.store?.name)
  const [description, setDescription] = useState(storeState?.store?.description)
  const [location, setLocation] = useState(storeState?.store?.location)
  const [address, setAddress] = useState(storeState?.store?.address)
  const [categories, setCategories] = useState(storeState?.store?.categories)
  const [products, setProducts] = useState(storeState?.store?.products)
  const [myProducts, setMyProducts] = useState([])
  const [pictureSignedUrl, setPictureSignedUrl] = useState(
    auth?.userInfo?.pictureSignedUrl,
  )
  const [type, setType] = useState(storeState?.store?.type)
  const [categoriesRequested, setCategoriesRequested] = useState(false)
  const [categoriesSuccessfullyReceived, setCategoriesSuccessfullyReceived] =
    useState(false)
  const [productsRequested, setProductsRequested] = useState(false)
  const [productsSuccessfullyReceived, setProductsSuccessfullyReceived] = useState(false)
  const [myProductsRequested, setMyProductsRequested] = useState(false)
  const [myProductsSuccessfullyReceived, setMyProductsSuccessfullyReceived] =
    useState(false)
  const [storeRequested, setStoreRequested] = useState(false)
  const [storeSuccessfullyReceived, setStoreSuccessfullyReceived] = useState(false)
  const [orderRequested, setOrderRequested] = useState(false)
  const [orderSuccessfullyReceived, setOrderSuccessfullyReceived] = useState(false)
  const [newOrdersRequested, setNewOrdersRequested] = useState(false)
  const [newOrdersSuccessfullyReceived, setNewOrdersSuccessfullyReceived] =
    useState(false)
  const [chatNewMessagesRequested, setChatNewMessagesRequested] = useState(false)
  const [chatNewMessagesSuccessfullyReceived, setChatNewMessagesSuccessfullyReceived] =
    useState(false)
  const [errorApi, setErrorApi] = useState('')
  const [rating, setRating] = useState(0)

  useEffect(() => {
    if (auth?.pwd && !storeId) {
      setStoreId(auth?.userInfo?.storeId)
    }
  }, [])

  useEffect(() => {
    if (!chatNewMessagesRequested && !chatNewMessagesSuccessfullyReceived) {
      setChatNewMessagesRequested(true)
    }
    if (
      chatNewMessagesRequested &&
      !chatNewMessagesSuccessfullyReceived &&
      chatState.newMessages
    ) {
      setChatNewMessages(chatState.newMessages)
      setChatNewMessagesSuccessfullyReceived(true)
      setChatNewMessagesRequested(false)
    }

    if (!newOrdersRequested && !newOrdersSuccessfullyReceived) {
      dispatch(fetchNewOrders({auth}))
      setNewOrdersRequested(true)
    }
    if (newOrdersRequested && !newOrdersSuccessfullyReceived && orderState.newOrders) {
      setNewOrders(orderState?.newOrders)
      setNewOrdersSuccessfullyReceived(true)
      setNewOrdersRequested(false)
    }
  })

  useEffect(() => {
    const receivedOrders = orderState.orders
    const errorApi = orderState.msg
    if (
      orderRequested &&
      receivedOrders?.length !== 0 &&
      orders?.length === 0 &&
      orderState.order.buyerId === auth?.userInfo?.id
    ) {
      setOrders(receivedOrders)
      setOrderRequested(false)
      setOrderSuccessfullyReceived(true)
    }
    if (errorApi) {
      setErrorApi(errorApi)
      setOrderRequested(false)
      setOrderSuccessfullyReceived(false)
    }
    if (!orderRequested && orders?.length === 0 && !orderSuccessfullyReceived && auth) {
      const requestParameters = {
        auth: auth,
      }
      dispatch(fetchOrders(requestParameters))
      setOrderRequested(true)
    }
  }, [orderState])

  useEffect(() => {
    const receivedCategories = categoryState.categories
    const errorApi = categoryState.msg
    if (categoriesRequested && !categoriesSuccessfullyReceived) {
      setCategories(receivedCategories)
      setCategoriesRequested(false)
      setCategoriesSuccessfullyReceived(true)
    }
    if (errorApi) {
      setErrorApi(errorApi)
      setProductsRequested(false)
      setCategoriesSuccessfullyReceived(false)
    }
    if (
      !categoriesRequested &&
      categories?.length === 0 &&
      !categoriesSuccessfullyReceived &&
      storeId &&
      categories[0]?.storeId !== storeId
    ) {
      //:category/:vendor/:index/:limit}
      const requestArguments = {
        category: actionsEnumCategories.CATEGORIES_ROOT,
        storeId: storeId,
        indexStart: indexStart,
        indexEnd: indexEnd,
      }
      dispatch(fetchCategoriesByStore(requestArguments))
      setCategoriesRequested(true)
    }
  }, [categoryState])

  useEffect(() => {
    const receivedProducts = productState.products
    const errorApi = categoryState.msg
    if (productsRequested && receivedProducts?.length && !products?.length) {
      const allProducts = productState.products
      setProducts(allProducts)
      setProductsRequested(false)
      setProductsSuccessfullyReceived(true)
    }
    if (errorApi) {
      setErrorApi(errorApi)
      setProductsRequested(false)
      setProductsSuccessfullyReceived(false)
    }
    if (
      !productsRequested &&
      products?.length === 0 &&
      !productsSuccessfullyReceived &&
      storeId
    ) {
      dispatch(fetchProducts())
      setProductsRequested(true)
      setProductsSuccessfullyReceived(false)
    }
  }, [productState])

  useEffect(() => {
    const errorApi = categoryState.msg
    if (
      myProductsRequested &&
      productState.myProducts?.length &&
      !myProductsSuccessfullyReceived
    ) {
      setMyProducts(productState.myProducts)
      setMyProductsRequested(false)
      setMyProductsSuccessfullyReceived(true)
    }
    //if (myProducts?.length < productState.myProducts?.length) {
    //  setMyProducts(productState.myProducts)
    //}
    if (errorApi) {
      setErrorApi(errorApi)
      setMyProductsRequested(false)
      setMyProductsSuccessfullyReceived(false)
    }
    if (
      !myProductsRequested &&
      myProducts?.length === 0 &&
      !myProductsSuccessfullyReceived &&
      storeId
    ) {
      //:category/:vendor/:index/:limit}
      const requestArguments = {
        category: actionsEnumCategories.CATEGORIES_ROOT,
        storeId: storeId,
        indexStart: indexStart,
        indexEnd: indexEnd,
      }
      setMyProductsRequested(true)
      setMyProductsSuccessfullyReceived(false)
      dispatch(fetchMyProducts(requestArguments))
    }
  }, [productState.myProducts])

  useEffect(() => {
    if (!storeRequested && !storeSuccessfullyReceived && storeId) {
      dispatch(fetchStore(storeId))
      setStoreRequested(true)
    }
    if (storeRequested && !storeSuccessfullyReceived && store) {
      const receivedStore = storeState.store
      setStoreSuccessfullyReceived(true)
      setStoreRequested(false)
      setStore(receivedStore)
      setPictureSignedUrl(auth?.userInfo?.pictureSignedUrl)
      setType(receivedStore.type)
    }
  }, [storeState])

  const handleCreateProduct = (e) => {
    e.preventDefault()
    navigate(`/create-product/`, {replace: true})
  }

  const handleNavigateUpdateStore = (e) => {
    e.preventDefault()
    navigate(`/update-store/${auth?.userInfo?.storeId}`, {replace: true})
  }

  return (
    <DashboardStore
      handleCreateProduct={handleCreateProduct}
      handleNavigateUpdateStore={handleNavigateUpdateStore}
      pictureSignedUrl={pictureSignedUrl}
      products={products}
      myProducts={myProducts}
      reviews={reviews}
      auth={auth}
      name={name}
      description={description}
      location={location}
      address={address}
      type={type}
      rating={rating}
      store={store}
      errorApi={errorApi}
    />
  )
}

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormInputValidation } from 'react-form-input-validation'
import { useNavigate } from 'react-router-dom'
import isEqual from 'lodash.isequal'
import UpdateStore from './UpdateStore'
import { updateStore, fetchStore } from '../../../redux/actions'
import useAuth from '../../../hooks/session/useAuth'

const UpdateStoreContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storeState = useSelector((state) => state.storeReducer)
  const { auth } = useAuth()
  const [store, setStore] = useState(storeState?.store)
  const [storeId, setStoreId] = useState(auth?.userInfo?.storeId)
  const [indexStart, setIndexStart] = useState(0)
  const [indexEnd, setIndexEnd] = useState(10)
  const [name, setName] = useState(storeState?.store?.name)
  const [userName, setUserName] = useState(storeState?.store?.userName)
  const [userId, setUserId] = useState(storeState?.store?.userId)
  const [description, setDescription] = useState(storeState?.store?.description)
  const [location, setLocation] = useState(storeState?.store?.location)
  const [address, setAddress] = useState(storeState?.store?.address)
  const [categories, setCategories] = useState(storeState?.store?.categories)
  const [products, setProducts] = useState(storeState?.store?.products)
  const [picture, setPicture] = useState(storeState?.store?.picture)
  const [type, setType] = useState(storeState?.store?.type)
  const [file, setFile] = useState(null)
  const [pictureSignedUrl, setPictureSignedUrl] = useState(store?.pictureSignedUrl)
  const [storeRequested, setStoreRequested] = useState(false)
  const [storeSuccessfullyReceived, setStoreSuccessfullyReceived] = useState(false)
  const [storeUpdateRequested, setStoreUpdateRequested] = useState(false)
  const [storeUpdatedSuccessfully, setStoreUpdatedSuccessfully] = useState(false)

  useEffect(() => {
    const sameStore = isEqual(store, storeState?.store)
    if (!store?.id && !storeRequested && !storeSuccessfullyReceived && storeId) {
      dispatch(fetchStore(storeId))
      setStoreRequested(true)
    }
    if (storeRequested && !storeSuccessfullyReceived) {
      const updatedStore = storeState.store
      setStoreSuccessfullyReceived(true)
      setStoreRequested(false)
      setStore(updatedStore)
      setName(updatedStore.name)
      setAddress(updatedStore.address)
      setDescription(updatedStore.description)
      setLocation(updatedStore.location)
      setUserName(updatedStore.userName)
      setUserId(updatedStore.userId)
      setCategories(updatedStore.categories)
      setProducts(updatedStore.products)
      setPicture(updatedStore.picture)
      setPictureSignedUrl(updatedStore.pictureSignedUrl)
      setType(updatedStore.type)

    }

    if (storeUpdateRequested && !storeUpdatedSuccessfully && !sameStore) {
      setStore(storeState.store)
      setStoreRequested(false)
      setStoreUpdatedSuccessfully(false)
    }
  }, [storeState])

  useEffect(() => {
    if (auth?.pwd && !storeId) {
      setStoreId(auth?.userInfo?.storeId)
    }
  }, [])

  const handleChangePicture = (e) => {
    const newFile = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(newFile)
    reader.onload = () => {
      setFile(newFile)
      setPictureSignedUrl(reader.result)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const storeParameters = {
      fields: {
        picture: picture,
        pictureSignedUrl: pictureSignedUrl,
        userName: userName,
        userId: userId,
        id: storeId,
        name: name,
        description: description,
        location: location,
        address: "",
        type: type,
      },
      file: file,
    }

    const requestParameters = {
      storeParameters,
      auth,
    }
    dispatch(updateStore(requestParameters))
    setStoreUpdateRequested(true)
  }

  const handleCreateGategory = (e) => {
    navigate('/create_category', {
      replace: true,
    })
  }

  const handleCreateProduct = (e) => {
    navigate('/create-product', {
      replace: true,
    })
  }

  const handleCancel = (e) => {
    navigate('/my-shop', {
      replace: true,
    })
  }

  const handleLocation = (e) => {
    setLocation(e.target.value)
  }

  const handleDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handleType = (e) => {
    setType(e.target.value)
  }

  return (
    <UpdateStore
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      handleChangePicture={handleChangePicture}
      handleCreateGategory={handleCreateGategory}
      handleCreateProduct={handleCreateProduct}
      //handleAddress={handleAddress}
      handleLocation={handleLocation}
      handleDescription={handleDescription}
      handleName={handleName}
      handleType={handleType}
      pictureSignedUrl={pictureSignedUrl}
      name={name}
      description={description}
      location={location}
      auth={auth}
      type={type}
      storeUpdatedSuccessfully={storeUpdatedSuccessfully}
    />
  )
}

export default UpdateStoreContainer

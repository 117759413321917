import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {fetchChatRooms} from '../../../redux'
import {ChatRooms} from './ChatRooms'
import useAuth from '../../../hooks/session/useAuth'

export const ChatRoomsContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const chatState = useSelector((state) => state.chatReducer)
  const [chatRooms, setChatRooms] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (auth?.userInfo?.id && !isLoading && !chatRooms?.length) {
      dispatch(fetchChatRooms(auth?.userInfo?.id))
      setIsLoading(true)
    }
    if (isLoading && !chatRooms.length && chatState?.chatRooms?.length) {
      const newMessages = chatState?.newMessages
      const updatedChatRooms = chatState?.chatRooms
      let found = false
      updatedChatRooms.map((chatRoom) => {
        found = newMessages.length
          ? newMessages.find((newMessage) => newMessage?.chatRoomId === chatRoom.id)
          : null
        if (found) {
          return (chatRoom.isRead = true)
        }
        return (chatRoom.isRead = false)
      })
      return setChatRooms(updatedChatRooms)
    }
    if (isLoading && !chatRooms.length && !chatState?.chatRooms?.length) {
      navigate('/', {replace: true}) // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }, [chatState])

  const handleOpenChatRoom = (e) => {
    const id = e.currentTarget.id
    navigate(`/chatRoom/${id}`)
  }

  const chatRoomsProps = {
    handleOpenChatRoom,
    chatRooms,
  }

  return <ChatRooms chatRoomsProps={chatRoomsProps} />
}

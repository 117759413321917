import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import App from './app'
import {AuthProvider} from './context/AuthProvider'
import {store} from './redux'


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

import {useRef, useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {useTranslate} from 'react-redux-multilingual'
import {ButtonBack, ButtonLarge, Input, InputTextArea} from '../../components'
import {USER_REGEX, PWD_REGEX, REGISTER_URL} from '../../constants/sessionConsts'
import {setToLocalStorage} from '../../utils/localStorage'
import useAuth from '../../hooks/session/useAuth'
import {ROLES} from '../../constants/rolesConsts'
import axios from '../../api/axios'
import {resizeFile} from '../../utils'

const Register = () => {
  const {auth, setAuth} = useAuth()
  const errRef = useRef()
  const [error, setError] = useState(null)
  const [name, setName] = useState('')
  const navigate = useNavigate()
  const [validName, setValidName] = useState(false)
  const [lastName, setLastName] = useState('')
  const [pwd, setPwd] = useState('')
  const [validPwd, setValidPwd] = useState(false)
  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [location, setLocation] = useState('')
  const [address, setAddress] = useState('')
  const [vendor, setVendor] = useState(false)
  const [description, setDescription] = useState('')
  const [mobile, setMobile] = useState('+34 654345654')
  const [email, setEmail] = useState('')
  const [file, setFile] = useState([])
  const [picture, setPicture] = useState('')
  const [pictureSignedUrl, setPictureSignedUrl] = useState('')
  const [success, setSuccess] = useState(false)
  const {register, handleSubmit} = useForm()
  const t = useTranslate()

  useEffect(() => {
    setValidName(USER_REGEX.test(name))
  }, [name])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd))
    setValidMatch(pwd === matchPwd)
  }, [pwd, matchPwd])

  useEffect(() => {
    setError(null)
  }, [name, lastName, pwd, matchPwd, location, vendor, description])

  const roles = vendor ? [ROLES.Vendor, ROLES.User] : [ROLES.User]
  const role = vendor ? 'Vendor' : 'User'

  const onSubmit = async () => {
    const formData = new FormData()
    formData.append('userPicture', file)
    formData.append('firstName', name)
    formData.append('lastName', lastName)
    formData.append('intro', description)
    formData.append('mobile', mobile)
    formData.append('address', address)
    formData.append('location', JSON.stringify(location))
    formData.append('email', email)
    formData.append('isVendor', vendor)
    formData.append('picture', null)
    formData.append('pictureSignedUrl', pictureSignedUrl)
    formData.append('pwd', pwd)
    formData.append('role', role)
    formData.append('roles', JSON.stringify(roles))

    try {
      const response = await axios.post(`${REGISTER_URL}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (typeof response === 'object') {
        if (response?.message) {
          return setError(response.message)
        }
        const accessToken = response?.data?.accessToken
        const userInfo = response?.data?.newUser
        userInfo.id = userInfo?._id
        delete userInfo?._id
        const user = userInfo?.firstName

        setAuth({
          user,
          pwd,
          roles,
          accessToken,
          userInfo,
        })
        setToLocalStorage('auth', {user, pwd, roles, accessToken, userInfo})
        setName(userInfo?.firstName)
        setPwd('')
        setError(null)
        vendor ? navigate('/shop', {replace: true}) : navigate('/', {replace: true})
      }
      setSuccess(true)

      return response
    } catch (err) {
      console.log('Error:', error)

      if (!err?.message) {
        setError('No Server Response')
      } else if (err.response?.status === 409) {
        setError('Username Taken')
      } else {
        if (err?.response?.data?.message) {
          setError(err?.response?.data?.message)
        }
      }
    }
  }

  const handleName = (e) => {
    setName(e.target.value)
  }

  const handleLastName = (e) => {
    setLastName(e.target.value)
  }

  const handlePwd = (e) => {
    setPwd(e.target.value)
  }

  const handleMatch = (e) => {
    setMatchPwd(e.target.value)
  }

  const handleLocation = (e) => {
    setLocation(e.target.value)
  }

  const handleVendor = () => {
    setVendor(!vendor)
  }

  const handleAddress = (e) => {
    setAddress(e.target.value)
  }

  const handleDescription = (e) => {
    if (description.length === 0) {
      const word = e.target.value.toUpperCase()
      setDescription(word)
    }
    setDescription(e.target.value)
  }

  const handleClickBack = () => {
    window.history.back()
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleFile = (f) => {
    console.log('Picture after resize:', f)
    setFile(f)
  }

  const onChangePicture = async (e) => {
    if (e.target?.files.length) {
      if (e.target?.files[0]?.name) {
        const newFile = e.target.files[0]

        await resizeFile({file: newFile, handleFile})
        console.log('file:', file)
        const reader = new FileReader()
        reader.readAsDataURL(newFile)

        reader.onload = () => {
          setPictureSignedUrl(reader.result)
        }
      }
    }
  }

  return (
    <>
      {success ? (
        <section>
          <h1>{t('success')}</h1>
          <span className="line">
            <Link to="/">{t('sign_in')}</Link>
          </span>
        </section>
      ) : (
        <section className="session">
          <div className="background-circle" />
          {error ? <h6 className="error">Error: {error}</h6> : null}
          <ButtonBack handleClick={handleClickBack} />
          <h1 className="title h1-bold">{t('sign_up')}</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="session__block__picture">
              {pictureSignedUrl ? (
                <img className="img-user" src={pictureSignedUrl} />
              ) : (
                <div className="session__block__picture__placeholder">
                  {/*<ButtonAdd
                    type="file"
                    name="userPicture"
                    className="session__block__picture__add"
                    onChange={(e) => onChangePicture(e)}
                  />*/}
                </div>
              )}
              <input
                className="session__block__picture__input"
                type="file"
                name="userPicture"
                onChange={(e) => onChangePicture(e)}
              ></input>
            </div>

            <div className="session__block">
              <div className="session__block__left">
                <Input
                  label={t('first_name')}
                  type="text"
                  id="firstName"
                  name="firstName"
                  handleChange={handleName}
                  value={name}
                />
              </div>
              <div className="session__block__right">
                <Input
                  label={t('last_name')}
                  type="text"
                  id="lastName"
                  name="lastName"
                  handleChange={handleLastName}
                  value={lastName}
                />
              </div>
            </div>
            <div className="session__block">
              <div className="session__block__left">
                <Input
                  label={t('password')}
                  type="text"
                  id="password"
                  name="password"
                  handleChange={handlePwd}
                  value={pwd}
                />
              </div>
              <div className="session__block__right">
                <Input
                  label={t('confirm_password')}
                  type="text"
                  id="confirm_pwd"
                  name="confirm_pwd"
                  handleChange={handleMatch}
                  value={matchPwd}
                />
              </div>
            </div>
            <div className="session__block">
              <div className="session__block__left">
                <Input
                  label={t('location')}
                  type="text"
                  id="location"
                  name="location"
                  handleChange={handleLocation}
                  value={location}
                />
              </div>
              <div className="session__block__right">
                <Input
                  label="Email:"
                  type="email"
                  id="email"
                  name="email"
                  handleChange={handleEmail}
                  value={email}
                />
              </div>
            </div>
            <div className="session__block">
              <div className="session__block__left">
                <div className="checkbox">
                  <label className="text-s" htmlFor="vendor">
                    {' '}
                    {t('are_you_vendor')}
                  </label>
                  <input
                    type="checkbox"
                    id="vendor"
                    name="vendor"
                    value="vendor"
                    onChange={handleVendor}
                  />
                </div>
                {/*          <Input
                  label="Address:"
                  type="text"
                  id="address"
                  name="address"
                  handleChange={handleAddress}
                  value={address}
              />*/}
              </div>

              <div className="session__block__right"></div>
            </div>
            <div className="session__block">
              <InputTextArea
                label={t('description')}
                type="text"
                id="description"
                name="description"
                handleChange={handleDescription}
                value={description}
              />
            </div>
            <ButtonLarge
              type="submit"
              text={t('sign_up')}
              handleClick={handleSubmit}
              disabled={!!(!name || !(pwd === matchPwd) || !pwd || !matchPwd)}
            />
          </form>
          <div className="session__options">
            <p className="text-s">
              {t('already_registered')}
              <br />
              <span className="line">
                <Link to="/">{t('sign_in')}</Link>
              </span>
            </p>
            <div className="flexGrow text-s">
              <Link to="/">{t('home')}</Link>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Register

import {actionsEnumNotifications} from '../../../constants'
import {createNotificationsAPI} from '../../../api'

const createNotificationsRequest = () => ({
  type: actionsEnumNotifications.CREATE_NOTIFICATIONS_REQUEST,
})

const createNotificationsReceived = (notificationsRoom) => ({
  type: actionsEnumNotifications.CREATE_NOTIFICATIONS_RECEIVED,
  notificationsRoom,
})

export const createNotifications = (requestParameters) => (dispatch) => {
  dispatch(createNotificationsRequest())
  createNotificationsAPI(requestParameters).then((notificationsRoom) => {
    dispatch(createNotificationsReceived(notificationsRoom))
    return notificationsRoom
  })
}

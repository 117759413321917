import { actionsEnumStore } from '../../../constants';
import { getStoreRequestAPI } from '../../../api';

const fetchStoreRequest = () => ({
  type: actionsEnumStore.FETCH_STORE_REQUEST,
});

const fetchStoreReceived = (store) => ({
  type: actionsEnumStore.FETCH_STORE_RECEIVED,
  store,
});

export const fetchStore = (storeId) => (dispatch) => {
  dispatch(fetchStoreRequest());
  getStoreRequestAPI(storeId).then((store) => {
    dispatch(fetchStoreReceived(store));
    return store;
  });
};

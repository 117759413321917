import axios from './axios'
import {
  URL_CATEGORIES,
  URL_CATEGORY_SELECT_OPTIONS,
  URL_CATEGORIES_BY_STORE,
} from '../urls'

const appendToFormData = (categoryParameters) => {
  const {name, description, count, availableAt, pictureSignedUrl} =
    categoryParameters.fields
  const {picture} = categoryParameters
  const formData = new FormData()
  formData.append('categoryPicture', picture[0])
  formData.append('pictureSignedUrl', pictureSignedUrl)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('sku', sku)
  formData.append('count', count)
  formData.append('parentName', parentName)
  formData.append('parentId', parentId)
  formData.append('children', children)
  formData.append('timestamps', timestamps)
  return formData
}

const getCategoriesRequestAPI = async () => {
  try {
    const response = await axios.get(`${URL_CATEGORIES}/`)
    return response.data.categories
  } catch (err) {
    return err
  }
}

const getCategoryRequestAPI = async (categoryId) => {
  try {
    const response = await axios.get(`${URL_CATEGORIES}/${categoryId}`)
    return response.data.category
  } catch (err) {
    return err
  }
}

const deleteCategoryAPI = async (categoryId, auth) => {
  try {
    const response = await axios.delete(`${URL_CATEGORIES}/${categoryId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.msg
  } catch (err) {
    return err
  }
}

const createCategoryAPI = async (requestParameters) => {
  const {categoryParameters, auth} = requestParameters

  const {fields, file} = categoryParameters
  const {name, description, picture, parentName, pictureSignedUrl} = fields

  const formData = new FormData()
  formData.append('categoryPicture', file)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('parentName', parentName)
  formData.append('pictureSignedUrl', pictureSignedUrl)
  formData.append('picture', picture)

  try {
    const response = await axios.post(URL_CATEGORIES, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.category
  } catch (err) {
    return err.response.data
  }
}

const updateCategoryAPI = async (requestParameters, id) => {
  const {categoryParameters, auth} = requestParameters
  const formData = appendToFormData(categoryParameters)
  try {
    const response = await axios.patch(`${URL_CATEGORIES}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.category
  } catch (err) {
    return err
  }
}

const getCategorySelectOptionsAPI = async () => {
  try {
    const response = await axios.get(`${URL_CATEGORY_SELECT_OPTIONS}`)
    return response.data.paths
  } catch (err) {
    return err
  }
}

const getCategoriesByStoreRequestAPI = async (props) => {
  const {category, storeId, indexStart, indexEnd} = props
  try {
    const response = await axios.get(
      `${URL_CATEGORIES_BY_STORE}/${category}/${storeId}/${indexStart}/${indexEnd}`,
    )
    const {filteredCategories} = response.data
    const categoriesWithId = filteredCategories?.map((category) => {
      const {_id, ...rest} = category
      return {id: _id, ...rest}
    })
    return categoriesWithId
  } catch (err) {
    return err
  }
}

export {
  getCategoriesRequestAPI,
  getCategoryRequestAPI,
  createCategoryAPI,
  updateCategoryAPI,
  deleteCategoryAPI,
  getCategorySelectOptionsAPI,
  getCategoriesByStoreRequestAPI,
}

import axios from './axios'
import {socket, ifUndefinedDeclareAsEmptyArray, setToLocalStorage} from '../utils'
import {URL_CANCEL_NOTIFICATION} from '../urls'

const asyncIO = (eventName, data, method) => {
  return new Promise(function (resolve, reject) {
    switch (method) {
      case 'emit':
        socket.emit(eventName, data, (response) => {
          return resolve(response)
        })
        setTimeout(reject, 1000)
        break

      case 'on':
        return socket.on(eventName, async (response, cb) => {
          cb('received')
          return resolve(response)
        })
        break
    }
  })
}

const updateStoreSubscriptions = (props) => {
  const {auth, setAuth, storesSubscribed} = props
  const {user, pwd, roles, accessToken, userInfo} = auth
  userInfo.storesSubscribed = storesSubscribed

  setAuth({
    user,
    pwd,
    roles,
    accessToken,
    userInfo,
  })
  setToLocalStorage('auth', {user, pwd, roles, accessToken, userInfo})
}

const createNotificationsAPI = async (requestParameters) => {
  try {
    const storeRoom = await asyncIO(
      'store create product or restocks notification',
      requestParameters,
      'emit',
    )
    const storeRoomWithId = () => {
      const {_id, ...rest} = storeRoom
      return {id: _id, ...rest}
    }
    return storeRoomWithId()
  } catch (err) {
    return err
  }
}

const joinNotificationsAPI = async (requestParameters) => {
  const {storesSubscribed, auth, setAuth} = requestParameters
  const subscriptionParameters = {
    buyerId: auth?.userInfo.id,
    storesSubscribed: storesSubscribed,
  }
  socket.emit('client join store room', subscriptionParameters, (response) => {
    if (response.message === 'Subscribed') {
      updateStoreSubscriptions({auth, setAuth, storesSubscribed})
    }

    return response.message
  })
}

const retrieveNotificationsAPI = async (requestParameters) => {
  socket.emit('client retrieve notifications', requestParameters, (newNotifications) => {
    const notificationsWithId = newNotifications?.map((newNotification) => {
      const {_id, ...rest} = newNotification
      return {id: _id, ...rest}
    })
    return notificationsWithId
  })
}

const cancelNotificationsAPI = async (requestParameters) => {
  const {storeId, auth, setAuth} = requestParameters
  try {
    const response = await axios.get(`${URL_CANCEL_NOTIFICATION}/${storeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })

    const serverResponse = response.data.response

    let notificationsWithId
    if (serverResponse.storesSubscribed) {
      updateStoreSubscriptions({
        auth,
        setAuth,
        setToLocalStorage,
        storesSubscribed: serverResponse.storesSubscribed,
      })
    }

    if (serverResponse.updatedNotifications) {
      const updatedNotifications = response.data.updatedNotifications
      notificationsWithId = updatedNotifications?.forEach((newNotification) => {
        const {_id, ...rest} = newNotification
        return {id: _id, ...rest}
      })
      notificationsWithId = ifUndefinedDeclareAsEmptyArray(notificationsWithId)
    }
    return notificationsWithId
  } catch (err) {
    return err
  }
}

const markNotificationAsReadAPI = async (requestParameters) => {
  const {id, auth} = requestParameters
  try {
    const response = await axios.get(`${URL_NOTIFICATION}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    const notification = response.data.notification
    notification.id = notification._id
    delete notification['_id']
    return notification
  } catch (err) {
    return err
  }
}

export {
  createNotificationsAPI,
  joinNotificationsAPI,
  cancelNotificationsAPI,
  retrieveNotificationsAPI,
  markNotificationAsReadAPI,
}

import React from 'react'
import { MyForm, ConnectionState, Messages } from '../../../components/chat'

export const ChatRoom = (props) => {
    const {
        handleMessageChange,
        handleSendMessage,
        isConnected,
        messages,
        chatRoom,
        message,
        auth
    } = props.parameters

    return (
        <div className="chat-room">
            <ConnectionState isConnected={isConnected} />
            <Messages data={messages} chatRoom={chatRoom} auth={auth} />
            <MyForm
                handleMessageChange={handleMessageChange}
                message={message}
                sendMessage={handleSendMessage}
            />
        </div>
    )
}


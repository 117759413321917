import { actionsEnumChat } from '../../../constants';
import { createChatRoomAPI } from '../../../api';

const createChatRoomRequest = () => ({
    type: actionsEnumChat.CREATE_CHAT_ROOM_REQUEST,
});

const createChatReceived = (chatRoom) => ({
    type: actionsEnumChat.CREATE_CHAT_ROOM_RECEIVED,
    chatRoom,
});

export const createChatRoom = (requestParameters) => (dispatch) => {
    dispatch(createChatRoomRequest());
    createChatRoomAPI(requestParameters).then((chatRoom) => {
        dispatch(createChatReceived(chatRoom));
        return chatRoom;
    });
};

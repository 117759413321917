export const actionsEnumProducts = {
  FETCH_PRODUCTS_REQUEST: 'FETCH_PRODUCTS_REQUEST',
  FETCH_PRODUCTS_RECEIVED: 'FETCH_PRODUCTS_RECEIVED',
  FETCH_MY_PRODUCTS_REQUEST: 'FETCH_MY_PRODUCTS_REQUEST',
  FETCH_MY_PRODUCTS_RECEIVED: 'FETCH_MY_PRODUCTS_RECEIVED',
  FETCH_PRODUCT_REQUEST: 'FETCH_PRODUCT_REQUEST',
  FETCH_PRODUCT_RECEIVED: 'FETCH_PRODUCT_RECEIVED',
  CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_RECEIVED: 'CREATE_PRODUCT_RECEIVED',
  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_RECEIVED: 'DELETE_PRODUCT_RECEIVED',
  UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_RECEIVED: 'UPDATE_PRODUCT_RECEIVED',
  FETCH_PRODUCTS_BY_STORE_REQUEST: 'FETCH_PRODUCTS_BY_STORE_REQUEST',
  FETCH_PRODUCTS_BY_STORE_RECEIVED: 'FETCH_PRODUCTS_BY_STORE_RECEIVED',
  FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_REQUEST: 'FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_REQUEST',
  FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_RECEIVED: 'FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_RECEIVED',
  SEARCH_PRODUCT: 'SEARCH_PRODUCT',
};

import {actionsEnumCart} from '../../constants'

const initialState = {
  cart: {
    userId: null,
    userInfo: {},
    groupsByStore: [
      {
        storeId: null,
        storeName: '',
        storePicture: '',
        storePictureSignedUrl: '',
        storeItems: [],
      },
    ],
    items: [],
    itemsCount: 0,
    subTotal: 0,
    shipping: 0,
    total: 0,
  },
  itemCart: {
    userId: null,
    userName: '',
    userPicture: '',
    userPictureSignedUrl: '',
    picture: '',
    pictureSignedUrl: '',
    cartId: null,
    categoryId: null,
    categoryName: '',
    price: 0,
    rating: 0,
    measurementUnit: '',
    itemId: null,
    itemName: '',
    itemsCount: 0,
    stock: 0,
    subTotal: 0,
    shipping: 0,
    total: 0,
    storeId: null,
    storeName: '',
    storeLocation: '',
    storeAddress: '',
    storePicture: '',
    storePictureSignedUrl: '',
  },
  msg: null,
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumCart.FETCH_CART_RECEIVED:
      return {
        ...state,
        cart: action.cart,
      }

    case actionsEnumCart.CREATE_CART_RECEIVED:
      if (!action?.cart?.error) {
        return {
          ...state,
          cart: action.cart,
          msg: action.msg,
        }
      } else {
        return {
          ...state,
          msg: action.cart.error,
        }
      }

    case actionsEnumCart.UPDATE_CART_RECEIVED:
      return {
        ...state,
        cart: action.cart,
      }

    case actionsEnumCart.DELETE_CART_RECEIVED:
      return initialState

    case actionsEnumCart.REMOVE_ITEM_UNIT_FROM_CART:
      const itemId = action.payload.id
      const itemCart = state.cart.items.find((p) => p.itemId === itemId)
      const itemIndexCart = state.cart.items.findIndex((p) => p.itemId === itemId)
      const itemIndexStoreCart = state.cart.groupsByStore.findIndex(
        (p) => p.storeId === itemCart.storeId,
      )
      const itemStoreCart = state.cart.groupsByStore[itemIndexStoreCart].storeItems.find(
        (p) => p.itemId === itemId,
      )
      const itemIndexStoreCartItems = state.cart.groupsByStore[
        itemIndexStoreCart
      ].storeItems.findIndex((p) => p.itemId === itemId)
      const itemStoreCartItems =
        state.cart.groupsByStore[itemIndexStoreCart].storeItems[itemIndexStoreCartItems]
      const itemsCountUpdated = itemCart.itemsCount - 1
      const subTotalUpdated = itemCart.subTotal - itemCart.price
      const shipping = itemCart.shipping - itemCart.shipping
      const total = itemCart.total - itemCart.price
      const updatedItemsCart = [...state.cart.items]
      updatedItemsCart[itemIndexCart] = {
        ...itemCart,
        itemsCount: itemsCountUpdated,
        subTotal: subTotalUpdated,
        shipping: shipping,
        total: total,
      }
      const updatedItemsStore = [
        ...state.cart.groupsByStore[itemIndexStoreCart].storeItems,
      ]
      updatedItemsStore[itemIndexStoreCartItems] = {
        ...itemStoreCartItems,
        itemsCount: itemsCountUpdated,
        subTotal: subTotalUpdated,
        shipping: shipping,
        total: total,
      }
      const updatedGroupsByStore = [...state.cart.groupsByStore]
      updatedGroupsByStore[itemIndexStoreCart] = {
        ...itemStoreCart,
        storeItems: updatedItemsStore,
      }
      const subTotalCart =
        updatedItemsCart.map((p) => p.subTotal).reduce((a, b) => a + b, 0) || 0
      const shippingCart =
        updatedItemsCart.map((p) => p.shipping).reduce((a, b) => a + b, 0) || 0
      const totalCart = updatedItemsCart.map((p) => p.total).reduce((a, b) => a + b, 0)
      return {
        ...state,
        cart: {
          ...state.cart,
          items: updatedItemsCart,
          groupsByStore: updatedGroupsByStore,
          subTotal: subTotalCart,
          shipping: shippingCart,
          total: totalCart,
        },
      }

    case actionsEnumCart.REMOVE_FROM_CART:
      const id = action.payload.id
      const itemIndex = state.cart.items.findIndex((p) => p.itemId === id)
      const item = state.cart.items[itemIndex]
      const updatedItems = [...state.cart.items]
      updatedItems.length === 1 ? updatedItems.pop() : updatedItems.splice(itemIndex, 1)
      const itemsCount = updatedItems[0]?.itemId ? updatedItems.length : 0

      if (itemsCount === 0) {
        return {
          ...initialState,
        }
      }

      const groupsByStore = state.cart?.groupsByStore
      if (groupsByStore?.length > 0) {
        const groupIndex = groupsByStore?.findIndex(
          (group) => group?.storeId === item?.storeId,
        )
        const groupItems = groupsByStore[groupIndex].storeItems
        if (groupsByStore[groupIndex].storeItems.length) {
          const itemIndexInGroup = groupItems.findIndex((item) => item.itemId === id)
          if (groupItems.length <= 1) {
            groupItems.pop()
            groupsByStore[groupIndex].splice(groupIndex, 1)
          } else {
            groupItems.splice(itemIndexInGroup, 1)
          }
          groupsByStore.groupItems = groupItems
        }
      }

      const subTotal = updatedItems.map((p) => p.subTotal).reduce((a, b) => a + b, 0) || 0
      const totalAfterRemove = updatedItems.map((p) => p.total).reduce((a, b) => a + b, 0)
      const shippingAfterRemove =
        updatedItems.map((p) => p.shipping).reduce((a, b) => a + b, 0) || 0

      return {
        ...state,
        cart: {
          ...state.cart,
          items: updatedItems,
          groupsByStore: groupsByStore,
          subTotal: subTotal,
          shipping: shippingAfterRemove,
          total: totalAfterRemove,
          itemsCount: itemsCount,
        },
      }

    case actionsEnumCart.ADD_TO_CART:
      if (state.cart?.items?.length > 0) {
        const itemIndex = state.cart.items.findIndex(
          (p) => p.itemId === action.payload.itemCart.itemId,
        )
        if (itemIndex > -1) {
          const oldItem = state.cart.items[itemIndex]
          if (item.itemsCount + 1 <= stock) {
            const item = action.payload.itemCart
            item.itemsCount = item.itemsCount + oldItem.itemsCount
            item.subTotal = item.subTotal * item.itemsCount
            item.total = item.subTotal + item.shipping
            const updatedItems = [...state.cart.items]
            updatedItems[itemIndex] = item
            let groups = state.cart.groupsByStore
            const groupItemIndex = groups?.findIndex(
              (group) => group.storeId === item.storeId,
            )

            if (groupItemIndex === -1) {
              groups = [
                ...state.cart.groupsByStore,
                {
                  storeId: item.storeId,
                  storeName: item.storeName,
                  storePicture: item.storePicture,
                  storePictureSignedUrl: item.storePictureSignedUrl,
                  storeItems: [item],
                },
              ]
            } else {
              const oldItemIndex = groups[groupItemIndex].storeItems.findIndex(
                (i) => i.itemId === item.itemId,
              )
              groups[groupItemIndex].storeItems[oldItemIndex] = item
            }

            const subTotalCart = updatedItems
              .map((p) => p.subTotal)
              .reduce((a, b) => a + b, 0)
            const shippingCart = updatedItems
              .map((p) => p.shipping)
              .reduce((a, b) => a + b, 0)
            const totalCart = updatedItems.map((p) => p.total).reduce((a, b) => a + b, 0)
            return {
              ...state,
              cart: {
                ...state.cart,
                items: updatedItems,
                groupsByStore: groups,
                subTotal: subTotalCart,
                shipping: shippingCart,
                total: totalCart,
              },
            }
          }

          if (item.itemsCount + 1 > stock) {
            return {
              ...state,
            }
          }
        }

        if (itemIndex === -1) {
          const cart = state.cart
          const cartItems = state.cart.items
          const item = action.payload.itemCart
          const updatedItems = [...cartItems, item]
          let groups = state.cart.groupsByStore
          const groupItemIndex =
            groups?.length > 0
              ? groups?.findIndex((group) => group.storeId === item.storeId)
              : -1

          if (groupItemIndex === -1) {
            groups = [
              ...state.cart.groupsByStore,
              {
                storeId: item.storeId,
                storeName: item.storeName,
                storePicture: item.storePicture,
                storePictureSignedUrl: item.storePictureSignedUrl,
                storeItems: [item],
              },
            ]
          } else {
            groups[groupItemIndex].storeItems.push(item)
          }

          return {
            ...state,
            cart: {
              ...cart,
              groupsByStore: groups,
              items: updatedItems,
              itemsCount: cart.itemsCount + 1,
              subTotal: cart.subTotal + item.subTotal,
              shipping: cart.shipping + item.shipping,
              total: cart.total + item.total,
            },
          }
        }
      } else {
        const item = action.payload.itemCart
        const groupsByStore = [
          {
            storeId: item.storeId,
            storeName: item.storeName,
            storePicture: item.storePicture,
            storePictureSignedUrl: item.storePictureSignedUrl,
            storeItems: [item],
          },
        ]
        return {
          ...state,
          cart: {
            ...state.cart,
            groupsByStore: groupsByStore,
            items: [item],
            itemsCount: 1,
            subTotal: item.subTotal,
            shipping: item.shipping,
            total: item.total,
          },
        }
      }

    case actionsEnumCart.SET_CART_DONE:
      return {
        ...state,
        cart: action.cart,
      }

    //    cart: {
    //      userId: null,
    //        userInfo: { },
    //      groupsByStore: [
    //        {
    //          storeId: null,
    //          storeName: '',
    //          storePicture: '',
    //          storePictureSignedUrl: '',
    //          storeItems: [],
    //        },
    //      ],
    //        items: [],
    //          itemsCount: 0,
    //            subTotal: 0,
    //              shipping: 0,
    //                total: 0,
    //},

    case actionsEnumCart.REMOVE_GROUP:
      const group = action.group
      if (group) {
        const groupsByStore = [...state.cart.groupsByStore]
        const cart = state.cart
        const itemsToRemove = group.storeItems
        const updatedItems = [...state.cart.items]

        state.cart.items.forEach((item, index) => {
          itemsToRemove.forEach((itm) => {
            if (itm.id == item.id) {
              updatedItems.splice(index, 1)
            }
          })
        })

        groupsByStore.shift()
        const subTotalCart =
          updatedItems.map((p) => p.subTotal).reduce((a, b) => a + b, 0) || 0
        const shippingCart =
          updatedItems.map((p) => p.shipping).reduce((a, b) => a + b, 0) || 0
        const totalCart = updatedItems.map((p) => p.total).reduce((a, b) => a + b, 0)
        if (updatedItems.length === 0) {
          return {
            ...initialState,
          }
        }
        cart.items = [...updatedItems]
        cart.groupsByStore = [...groupsByStore]
        cart.total = totalCart
        cart.subtotal = subTotalCart
        cart.shippingCart = shippingCart
        cart.itemsCount = updatedItems.length

        return {
          ...state,
          cart: cart,
        }
      }

      return {
        ...state,
        cart: action.cart,
      }

    default:
      return state
  }
}
export default cartReducer

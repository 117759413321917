import {useTranslate} from 'react-redux-multilingual'
import {ButtonBack, GalleryOrder} from '../../../components'

export const ShowOrders = (props) => {
  const {orders, auth, handleOpenOrder} = props.parameters
  const t = useTranslate()

  return (
    <section className="order">
      <div className="order__top">
        <div className="order__top__left">
          <ButtonBack />
          <h1 className="order__top__left__user-name h4-bold ">
            {auth?.userInfo?.firstName}
          </h1>
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{t('orders')}</h2>
      </div>
      <div>
        <div className="order__body">
          <GalleryOrder
            title={t('your_orders')}
            target="orders"
            auth={auth}
            orders={orders}
            handleOpenOrder={handleOpenOrder}
          />
        </div>
      </div>
    </section>
  )
}

export const InputLarge = (props) => {
  const {type = 'text', textHelper, id, name, value, isDisabled, handleChange} = props
  return (
    <div className="input input-large">
      <label htmlFor={name} className="text-m-s">
        {textHelper}
      </label>
      <input
        name={name}
        className="text-m-l"
        type={type}
        id={id}
        value={value}
        disabled={isDisabled}
        onChange={handleChange}
      />
    </div>
  )
}

import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import useAuth from '../../hooks/session/useAuth'
import {MenuBarView} from './MenuBarView'
import {fetchNewMessagesCompleted, retrieveNotificationsReceived} from '../../redux'
import {socket, startSocketOn} from '../../utils/socket'
import {Rating} from '../rating'

export const MenuBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {auth} = useAuth()
  const [likeStatus, setLikeStatus] = useState(false)
  const [sessionStatus, setSessionStatus] = useState(false)
  const [cartStatus, setCartStatus] = useState(false)
  const [orderStatus, setOrderStatus] = useState(false)
  const [order, setOrder] = useState(null)
  const [chatStatus, setChatStatus] = useState(false)
  const [rateStatus, setRateStatus] = useState(false)
  const [notificationsStatus, setNotificationsStatus] = useState(false)
  const [userConnected, setUserConnected] = useState(false)
  const chatState = useSelector((state) => state.chatReducer)
  const cartState = useSelector((state) => state.cartReducer.cart)
  const orderState = useSelector((state) => state.orderReducer)
  const productState = useSelector((state) => state.productReducer)
  const notificationsState = useSelector((state) => state.notificationReducer)

  useEffect(() => {
    if (auth?.userInfo?.id) {
      startSocketOn(auth)
      if (!userConnected) {
        const userName = auth?.userInfo?.firstName
        let userOrStoreId
        userOrStoreId = auth?.userInfo?.id
        if (auth?.userInfo.isVendor) {
          userOrStoreId = auth?.userInfo?.storeId
        }

        setUserConnected(true)
        socket.emit(
          'connect user socket',
          {userName: userName, userId: userOrStoreId, socketId: socket.id},
          (response) => {
            console.log(response)
            if (response === 'connected') {
            }
          },
        )
      }

      socket.emit('get new messages', auth?.userInfo?.id, (newMessages) => {
        const newMessagesWithId = newMessages?.map((newMessage) => {
          const {_id, ...rest} = newMessage
          return {id: _id, ...rest}
        })
        dispatch(fetchNewMessagesCompleted(newMessagesWithId))
      })
    }
  }, [auth, chatState])

  useEffect(() => {
    if (auth?.userInfo?.id) {
      const user = auth?.userInfo
      const requestParameters = {
        buyerName: user.firstName,
        buyerId: user.id,
        storesSubscribed: user.storesSubscribed ? user.storesSubscribed : [],
      }
      socket.emit(
        'client retrieve notifications',
        requestParameters,
        (newNotifications) => {
          const notificationsWithId = newNotifications?.map((newNotification) => {
            const {_id, ...rest} = newNotification
            return {id: _id, ...rest}
          })
          if (notificationsWithId.length) {
            setNotificationsStatus(true)
          } else {
            setNotificationsStatus(false)
          }
          dispatch(retrieveNotificationsReceived(notificationsWithId))
        },
      )
    }
  }, [auth, productState, notificationsState])

  useEffect(() => {
    const areOrders = orderState?.orders?.length ? true : null
    orderState?.newOrders?.length ? setOrderStatus(true) : setOrderStatus(false)
    if (areOrders) {
      const isVendor = auth?.userInfo?.isVendor
      if (!isVendor) {
        orderState?.orders.forEach((order) => {
          if (order.delivered && order.buyerHasRateStore === false) {
            setRateStatus(true)
            setOrder(order)
          }
          if (order.buyerHasRateStore === true) {
            setRateStatus(false)
          }
        })
      }
    }
  }, [orderState, auth, setRateStatus])

  useEffect(() => {
    const cartLength = cartState?.items?.length
    if (cartLength) {
      if (
        cartState?.items[cartLength - 1]?.itemId !== '' &&
        cartState?.items[cartLength - 1]?.itemId !== undefined
      ) {
        setCartStatus(true)
      }
    } else {
      setCartStatus(false)
    }
  }, [cartState])

  useEffect(() => {
    chatState?.newMessages?.length ? setChatStatus(true) : setChatStatus(false)
  }, [chatState])

  useEffect(() => {
    if (auth?.userInfo?.likes?.length) {
      setLikeStatus(true)
    } else {
      setLikeStatus(false)
    }
  }, [auth?.userInfo?.likes])

  useEffect(() => {
    if (auth?.pwd) {
      setSessionStatus(true)
    } else {
      setSessionStatus(false)
    }
  }, [auth])

  const handleClickHome = (e) => {
    e.preventDefault()
    navigate(`/`, {replace: true})
  }

  const handleClickSession = (e) => {
    e.preventDefault()
    if (auth?.userInfo) {
      navigate(`/update-user`, {replace: true})
    } else {
      navigate(`/login`, {replace: true})
    }
  }

  const handleClickOrder = (e) => {
    e.preventDefault()
    if (auth?.userInfo) {
      navigate(`/show-orders`, {replace: true})
    } else {
      navigate(`/login`, {replace: true})
    }
  }

  const handleClickLike = (e) => {
    e.preventDefault()
    console.log(e.currentTarget)
  }

  const handleClickChat = (e) => {
    e.preventDefault()
    navigate(`/chatRooms`, {replace: true})
  }

  const handleClickCart = (e) => {
    e.preventDefault()
    navigate(`/cart`, {replace: true})
  }

  const props = {
    handleClickHome,
    handleClickLike,
    handleClickSession,
    handleClickCart,
    handleClickChat,
    handleClickOrder,
    likeStatus,
    chatStatus,
    sessionStatus,
    cartStatus,
    orderStatus,
    notificationsStatus,
  }

  {
    return rateStatus && order ? (
      <Rating
        buyerId={order.buyerId}
        buyerName={order.buyerName}
        storeId={order.storeId}
        storeName={order.storeName}
        orderItems={order.orderItems}
        orderId={order.id}
        auth={auth}
      />
    ) : (
      <MenuBarView parameters={props} />
    )
  }
}

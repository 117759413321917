import { actionsEnumOrder } from '../../../constants';
import { getOrderRequestAPI } from '../../../api';

const fetchOrderRequest = () => ({
  type: actionsEnumOrder.FETCH_ORDER_REQUEST,
});

const fetchOrderReceived = (order) => ({
  type: actionsEnumOrder.FETCH_ORDER_RECEIVED,
  order,
});

export const fetchOrder = (requestParameters) => (dispatch) => {
  dispatch(fetchOrderRequest());
  getOrderRequestAPI(requestParameters).then((order) => {
    dispatch(fetchOrderReceived(order));
    return order;
  });
};

import {actionsEnumNotifications} from '../../constants'

const initialState = {
  notificationRoom: [],
  notifications: [],
  msg: null,
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumNotifications.CREATE_NOTIFICATIONS_RECEIVED:
      return {
        ...state,
        notificationRoom: action.notificationRoom ? action.notificationRoom : [],
        msg: null,
      }

    case actionsEnumNotifications.JOIN_NOTIFICATIONS_COMPLETED:
      return {
        ...state,
        msg: action.message ? action.message : state.message,
      }

    case actionsEnumNotifications.CANCEL_NOTIFICATIONS_COMPLETED:
      return {
        ...state,
        notificationRoom: action.response.length ? action.response : [],
        msg: action.response.message ? action.response.message : 'unsubscribed',
      }

    case actionsEnumNotifications.RETRIEVE_NOTIFICATIONS_COMPLETED:
      return {
        ...state,
        notifications: action.notifications ? action.notifications : state.notifications,
      }
    case actionsEnumNotifications.MARK_NOTIFICATION_AS_READ_COMPLETED:
      const notificationReceived = action.notification
      const updatedNotifications = []
      state.notifications.forEach((notification) => {
        if (notification?.id !== notificationReceived?.id) {
          updatedNotifications.push(notification)
        }
      })

      return {
        ...state,
        notifications: updatedNotifications,
      }
    default:
      return state
  }
}
export default notificationsReducer

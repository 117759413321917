import {useTranslate} from 'react-redux-multilingual'
import {IconShop} from '../../icons'
import {Gallery} from '../../components'

export const ShowSearchResult = (props) => {
  const {storeSearchResult, productSearchResult, searchQuery} = props
  const t = useTranslate()

  const StoreSearchResult = () => {
    return (
      <div className="search-result__main__block">
        <Gallery
          title={t('search_result_store')}
          items={storeSearchResult}
          target={'store'}
        />
      </div>
    )
  }

  const ProductSearchResult = () => {
    return (
      <div className="search-result__main__block">
        <Gallery
          title={t('search_result_product')}
          items={productSearchResult}
          target={'product'}
        />
      </div>
    )
  }

  return (
    <div className="search-result">
      <div className="search-result__main">
        <h3 className="search-result__main__gallery__title h2-bold">{`${t(
          'search_result',
        )} "${searchQuery}"`}</h3>

        {storeSearchResult?.length ? <StoreSearchResult /> : null}
        {productSearchResult.length ? <ProductSearchResult /> : null}
      </div>
    </div>
  )
}

import React from 'react'

export const IconDelivey = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="#40beaf"
    version="1.1"
    viewBox="0 0 612 612"
    xmlSpace="preserve"
  >
    <path d="M580.592 135.703L497.33 98.681 234.478 222.254v79.353l-45.854-20.389v-79.353L451.477 78.293 363.19 39.037c-11.822-5.256-31.166-5.256-42.987 0l-217.401 96.666c-17.319 7.701-31.453 29.51-31.409 48.464l.339 143.238c13.628-4.385 28.128-6.806 43.195-6.806 77.949 0 141.373 63.424 141.373 141.382 0 20.36-4.413 39.682-12.196 57.188l76.764 32.815c11.436 4.888 30.146 4.889 41.583.002l216.905-92.694c17.614-7.527 32.062-29.357 32.107-48.513L612 184.166c.044-18.954-14.089-40.763-31.408-48.463zM496.655 300.16l29.458-62.149c2.974-6.273 10.429-9.892 13.413-6.49l30.229 34.461c3.019 3.441-.812 11.277-6.869 14.087l-12.724 5.903-.257 89.528c-.012 3.912-3.467 8.569-7.708 10.399l-19.049 8.223c-4.176 1.803-7.554.132-7.556-3.729l-.064-88.354-12.325 5.718c-5.796 2.689-9.425-1.527-6.548-7.597zm-108.616 50.783l26.773-59.078c2.7-5.959 9.604-9.312 12.422-6.012l28.532 33.423c2.85 3.336-.628 10.779-6.237 13.381l-11.782 5.466.76 85.727c.033 3.749-3.135 8.163-7.066 9.86l-17.664 7.625c-3.873 1.672-7.042.025-7.087-3.675l-1.035-84.647-11.429 5.302c-5.374 2.493-8.804-1.597-6.187-7.372zm195.872 48.328c-.014 1.967-1.781 4.298-3.946 5.208l-201.751 84.757c-1.883.791-3.432-.037-3.459-1.851l-.155-9.861c-.028-1.817 1.476-3.942 3.361-4.745l202.111-86.097c2.17-.924 3.92-.075 3.908 1.896l-.069 10.693zm-468.986-52.217C51.454 347.054 0 398.508 0 461.979c0 63.472 51.454 114.926 114.925 114.926S229.85 525.451 229.85 461.979c.001-63.471-51.454-114.925-114.925-114.925zm75.096 91.313l-70.724 79.563a17.642 17.642 0 01-13.221 5.932 17.6 17.6 0 01-11.038-3.877l-44.202-35.361c-7.624-6.095-8.862-17.223-2.759-24.846 6.095-7.632 17.228-8.867 24.851-2.763l31.093 24.872 59.574-67.02c6.479-7.295 17.659-7.959 24.958-1.468 7.3 6.494 7.956 17.664 1.468 24.968z"></path>
  </svg>
)

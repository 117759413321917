export const actionsEnumOrder = {
  FETCH_ORDER_REQUEST: 'FETCH_ORDER_REQUEST',
  FETCH_ORDER_RECEIVED: 'FETCH_ORDER_RECEIVED',
  FETCH_ORDERS_REQUEST: 'FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_RECEIVED: 'FETCH_ORDERS_RECEIVED',
  FETCH_NEW_ORDERS_REQUESTED: 'FETCH_NEW_ORDERS_REQUESTED',
  FETCH_NEW_ORDERS_COMPLETED: 'FETCH_NEW_ORDERS_COMPLETED',
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_RECEIVED: 'CREATE_ORDER_RECEIVED',
  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_RECEIVED: 'DELETE_ORDER_RECEIVED',
  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_RECEIVED: 'UPDATE_ORDER_RECEIVED',
  ACCEPT_ORDER_REQUEST: 'ACCEPT_ORDER_REQUEST',
  ACCEPT_ORDER_RECEIVED: 'ACCEPT_ORDER_RECEIVED',
  REJECT_ORDER_REQUEST: 'REJECT_ORDER_REQUEST',
  REJECT_ORDER_RECEIVED: 'REJECT_ORDER_RECEIVED',
  CLOSE_ORDER_REQUEST: 'CLOSE_ORDER_REQUEST',
  CLOSE_ORDER_RECEIVED: 'CLOSE_ORDER_RECEIVED',
  RESUME_ORDER_REQUEST: 'RESUME_ORDER_REQUEST',
  RESUME_ORDER_RECEIVED: 'RESUME_ORDER_RECEIVED',
  READ_NEW_ORDER_REQUEST: 'READ_NEW_ORDER_REQUEST',
  READ_NEW_ORDER_RECEIVED: 'READ_NEW_ORDER_RECEIVED',
  ADD_BASKET_ITEMS_TO_ORDER: 'ADD_BASKET_ITEMS_TO_ORDER',
  REMOVE_BASKET_ITEMS_FROM_ORDER: 'REMOVE_BASKET_ITEMS_FROM_ORDER',
}

import { IconStarGold, IconStarGrey } from '../../../icons'

export const RatingStars = (props) => {
    const { rating } = props
    const stars = []

    for (let i = 0; i < 5; i++) {
        if (i < rating) {
            stars.push('gold')
        } else {
            stars.push('grey')
        }
    }

    return (
        <div className="item__main__rating__stars">
            {stars?.map((star, index) =>
                star === 'gold' ? (
                    <IconStarGold key={index} />
                ) : (
                    <IconStarGrey key={index} />
                ),
            )}
        </div>
    )
}

import { ButtonSmall } from '../common'

export const BannerCarousel = (props) => {
    const { title, items, handleClick } = props;

    return (
        <div className="banner-carousel">
            <h3 className="banner-carousel__title">
                {title}
            </h3>
            <div className="banner-carousel__items">
                {items ? items?.map(item => {
                    const key = Math.random()
                    return (
                        <div className="banner-carousel__items__item" key={key}>
                            <div className="banner-carousel__items__item_left">
                                <h4 className="banner-carousel__items__item__left__title">
                                    {item.title}
                                </h4>
                                <p className="text-s  banner-carousel__items__item__left__description">
                                    {item.description}
                                </p>
                                <ButtonSmall className="banner-carousel__items__item__left__button"
                                    type='button' text='Order now' icon={null} onClick={handleClick} />
                            </div>
                            <div className="banner-carousel__items__item__right">
                                <img src={item.pictureSignedUrl} />
                            </div>
                        </div>
                    )
                }) : null
                }
            </div>
        </div>
    )
}

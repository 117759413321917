import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonBack,
  ButtonLarge,
  Input,
  InputTextArea,
  InputSelect,
  ModalCategorySuggestion,
} from '../../../components'

export const CreateProduct = (props) => {
  const {
    handleName,
    handleCategoryName,
    handleCategoryParentName,
    handleDescription,
    handleCount,
    handleMeasurementUnit,
    handlePrice,
    handleSubmit,
    handleChangePicture,
    handleCategoryPath,
    handleModalSuggestionAccepted,
    handleModalSuggestionRejected,
    handleBlur,
    auth,
    name,
    description,
    price,
    categoryName,
    categoryParentName,
    count,
    measurementUnit,
    successfullyCreated,
    pictureSignedUrl,
    categorySelectOptions,
    categoryNameSuggestion,
    categoryPath,
    modalSuggestionOpen,
    categoryNameInputRef,
    categoryParentNameInputRef,
  } = props
  const t = useTranslate()

  return (
    <>
      <div className="background-circle" />
      <section className="item">
        <div className="item__top">
          <div className="item__top__left">
            <ButtonBack />
            <h1 className="item__top__left__user-name h4-bold ">
              {auth?.userInfo?.firstName}
            </h1>
          </div>
        </div>

        {successfullyCreated ? (
          <div className="item__success">
            <h1 className="item__success__title h1-bold">{t('product_created')}</h1>
          </div>
        ) : (
          <>
            {modalSuggestionOpen ? (
              <ModalCategorySuggestion
                categoryName={categoryNameSuggestion}
                handleClickAccepted={handleModalSuggestionAccepted}
                handleClickRejected={handleModalSuggestionRejected}
              />
            ) : null}

            <div className="layout__header">
              <h2 className="layout__header__label">{t('create_product')}</h2>
            </div>
            {/*{errors ? <h1 className="item__success__title h1-bold">{errors}</h1> : null}*/}
            <form onSubmit={handleSubmit}>
              <div className="item__picture">
                <img className="img-user" src={pictureSignedUrl} />

                <input
                  className="session__block__picture__input"
                  type="file"
                  name="productPicture"
                  onChange={(e) => handleChangePicture(e)}
                ></input>
              </div>
              <div className="item__main">
                <div className="item__main__top">
                  <Input
                    label="Product Name:"
                    type="text"
                    id="name"
                    handleChange={handleName}
                    value={name}
                  />
                </div>

                <div className="item__main__top">
                  <Input
                    label={t('category_name')}
                    type="text"
                    id="categoryName"
                    refName={categoryNameInputRef}
                    handleChange={handleCategoryName}
                    value={categoryName}
                    handleBlur={handleBlur}
                  />
                  <Input
                    label={t('category_parent_name')}
                    type="text"
                    id="categoryParentName"
                    refName={categoryParentNameInputRef}
                    handleChange={handleCategoryParentName}
                    value={categoryParentName}
                    handleBlur={handleBlur}
                  />
                </div>

                <div className="item__main__top">
                  <InputSelect
                    label={t('category_path')}
                    type="text"
                    id="categoryPath"
                    handleChange={handleCategoryPath}
                    value={categoryPath}
                    categoryOptions={categorySelectOptions}
                  />
                </div>

                <div className="item__main__description">
                  <InputTextArea
                    label={t('description')}
                    type="text"
                    id="description"
                    name="description"
                    handleChange={handleDescription}
                    value={description}
                  />
                </div>
                <div className="item__main__details">
                  <Input
                    label={t('count')}
                    type="number"
                    id="count"
                    handleChange={handleCount}
                    placeholder={'0'}
                    value={count}
                  />
                  <Input
                    label={t('measurement')}
                    type="text"
                    id="measurementUnity"
                    placeholder={t('unidad/libra/galon')}
                    handleChange={handleMeasurementUnit}
                    value={measurementUnit}
                  />
                  <Input
                    label={t('price')}
                    type="number"
                    id="price"
                    handleChange={handlePrice}
                    placeholder={'0'}
                    value={price}
                  />
                </div>

                <div className="item__main__bottom">
                  <ButtonLarge
                    type="button"
                    text={t('create_product')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </section>
    </>
  )
}

import React from 'react'
import {ButtonSmall} from '../common'
import {useTranslate} from 'react-redux-multilingual'

export const MyForm = (props) => {
  const {handleMessageChange, message, sendMessage} = props
  const t = useTranslate()

  const handleSubmit = (e) => {
    e.preventDefault()
    sendMessage(e)
  }

  return (
    <form className="chat-room__form" onSubmit={handleSubmit}>
      <input
        className="chat-room__form__input"
        onChange={(e) => handleMessageChange(e.target?.value)}
        value={message}
      />
      <ButtonSmall
        type="submit"
        text={t('submit')}
        className="chat-room__form__action"
        onClick={handleSubmit}
      />
    </form>
  )
}

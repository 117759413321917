import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams, useNavigate} from 'react-router-dom'
import {
  fetchProduct,
  addToCart,
  fetchStore,
  joinNotifications,
  cancelNotifications,
} from '../../../redux/actions/index'
import {ShowProduct} from './ShowProduct'
import useAuth from '../../../hooks/session/useAuth'

const ShowProductContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {id} = useParams()
  const {auth, setAuth} = useAuth()
  const cartState = useSelector((state) => state.cartReducer)
  const productState = useSelector((state) => state.productReducer)
  const storeState = useSelector((state) => state.storeReducer)
  const notificationsState = useSelector((state) => state.notificationReducer)
  const [product, setProduct] = useState(null)
  const [productRequested, setProductRequested] = useState(false)
  const [productReceived, setProductReceived] = useState(false)
  const [deleteRequested, setDeleteRequested] = useState(false)
  const [store, setStore] = useState(null)
  const [storeRequested, setStoreRequested] = useState(false)
  const [storeSuccesfullyReceived, setStoreSuccesfullyReceived] = useState(false)
  const [storesSubscribed, setStoresSubscribed] = useState([])
  const [notifications, setNotifications] = useState([])
  const [notificationsRequested, setNotificationsRequested] = useState(false)
  const [count, setCount] = useState(0)
  const [priceTotal, setPriceTotal] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [notificationsDisabled, setNotificationsDisabled] = useState(false)
  const [msg, setMsg] = useState(null)
  const [reviews, setReviews] = useState(0)
  const [cancelNotificationsRequested, setCancelNotificationsRequested] = useState(false)
  const [notificationsSuccesfullyReceived, setNotificationsSuccesfullyReceived] =
    useState(false)
  const [
    cancelNotificationsSuccesfullyReceived,
    setCancelNotificationsSuccesfullyReceived,
  ] = useState(false)

  useEffect(() => {
    const storesSubscribedInState = auth?.userInfo?.storesSubscribed
    if (storesSubscribedInState && product?.storeId) {
      const storeIdFound = storesSubscribedInState.find((id) => product.storeId === id)
      storeIdFound ? setNotificationsDisabled(false) : null
    }
    if (storesSubscribedInState?.length && storesSubscribed !== storesSubscribedInState) {
      setStoresSubscribed(storesSubscribedInState)
    }

    if (!notificationsSuccesfullyReceived && notificationsRequested) {
      setNotificationsDisabled(true)
      setNotifications(notificationsState.notifications)
      setNotificationsSuccesfullyReceived(true)
      setNotificationsRequested(false)
    }
    if (checkIfAlreadySubscribed()) {
      setNotificationsDisabled(true)
    }

    if (cancelNotificationsRequested && !cancelNotificationsSuccesfullyReceived) {
      const subscribed = checkIfAlreadySubscribed()
      if (!subscribed) {
        setCancelNotificationsSuccesfullyReceived(true)
        setNotificationsDisabled(false)
      }
    }
  }, [auth?.userInfo, notificationsState.msg, cancelNotificationsRequested, product])

  useEffect(() => {
    if (auth?.userInfo?.storeId && auth?.userInfo?.storeId === product?.storeId) {
      navigate(`/update-product/${product?.id}`, {replace: true})
    }
    if (
      !storeRequested &&
      auth?.userInfo?.storeId &&
      storeState?.store?.id !== auth?.userInfo?.storeId
    ) {
      dispatch(fetchStore(product?.storeId))
      setStoreRequested(true)
    }

    if (
      !storeRequested &&
      !storeSuccesfullyReceived &&
      !auth?.userInfo?.isVendor &&
      !storeState?.store?.id &&
      product?.storeId &&
      !store
    ) {
      dispatch(fetchStore(product?.storeId))
      setStoreRequested(true)
    }

    if (!storeSuccesfullyReceived && storeRequested) {
      setStore(storeState?.store)
      setStoreSuccesfullyReceived(true)
      setStoreRequested(false)
    }

    if (
      !storeRequested &&
      !storeSuccesfullyReceived &&
      !auth?.userInfo?.isVendor &&
      storeState?.store?.id &&
      product?.storeId &&
      storeState?.store?.id === product?.storeId &&
      !store
    ) {
      setStore(storeState?.store)
      setStoreSuccesfullyReceived(true)
    }
  }, [storeState, product])

  useEffect(() => {
    if (deleteRequested && productState?.product.length === 0) {
      setMsg(productState?.msg)
      setProduct(null)
      setTimeout(() => {
        navigate('/shop')
      }, 2500)
    }

    if (
      productState?.products?.length === 0 &&
      storeSuccesfullyReceived &&
      productReceived
    ) {
      setTimeout(() => {
        navigate('/')
      }, 500)
    }

    if (productState?.products?.length > 0 && id !== product?.id) {
      const product = productState?.products.find((p) => p.id === id)
      setMsg(productState?.msg)
      setProduct(product)
    }

    if (!product) {
      let productFound = null

      if (productState?.product?.id === id) {
        const product = productState?.product
        setMsg(productState?.msg)
        setProduct(product)
      }

      if (productState?.products?.length) {
        productFound = productState?.products.find((product) => product?.id === id)
        if (productFound) {
          setProductReceived(true)
          return setProduct(productFound)
        }
      }

      if (!productRequested && !productFound) {
        dispatch(fetchProduct(id))
        setProductRequested(true)
      }
    }
  }, [productState])

  const handleCartProduct = () => {
    if (auth && product && store && count && priceTotal) {
      const itemCart = {
        userId: auth?.userInfo?.id,
        userName: auth?.userInfo?.firstName,
        userPicture: auth?.userInfo?.picture,
        userPictureSignedUrl: auth?.userInfo?.pictureSignedUrl,
        picture: product.picture,
        pictureSignedUrl: product.pictureSignedUrl,
        cartId: cartState?.cart?.id ? cartState.cart?.id : null,
        categoryId: product.categoryId,
        categoryName: product.categoryName,
        price: product.price,
        rating: product.rating,
        measurementUnit: product.measurementUnit,
        itemId: product.id,
        itemName: product.name,
        itemsCount: count ? count : 1,
        stock: product.itemsCount,
        subTotal: priceTotal ? priceTotal : 1,
        shipping: product.shipping ? product.shipping : 0,
        total: (priceTotal ? priceTotal : 1) + (product.shipping ? product.shipping : 0),
        storeId: store?.id,
        storeName: store?.name,
        storeLocation: store?.location,
        storeAddress: store?.address,
        storePicture: store?.picture,
        storePictureSignedUrl: store?.pictureSignedUrl,
      }
      dispatch(addToCart(itemCart))
      setTimeout(() => {
        navigate('/')
      }, 500)
    }
  }

  const dispatchNotificationRequest = (updatedStoresSubscribed) => {
    const subscriptionParameters = {
      auth,
      setAuth,
      storesSubscribed: updatedStoresSubscribed,
    }
    dispatch(joinNotifications(subscriptionParameters))
    setNotificationsRequested(true)
  }

  const handleSubscription = () => {
    if (storesSubscribed.length) {
      if (
        !notificationsRequested &&
        notifications !== notificationsState?.notifications &&
        product
      ) {
        if (!storesSubscribed?.includes(product.storeId)) {
          const updatedStoresSubscribed = [...storesSubscribed, product.storeId]
          setStoresSubscribed(updatedStoresSubscribed)
          dispatchNotificationRequest(updatedStoresSubscribed)
        }
      }
    }

    const alreadySubscribed = checkIfAlreadySubscribed()
    if (!alreadySubscribed) {
      if (!notificationsRequested && !notifications.length) {
        const updatedStoresSubscribed = [...storesSubscribed, product.storeId]
        setStoresSubscribed(updatedStoresSubscribed)
        dispatchNotificationRequest(updatedStoresSubscribed)
      }
    }
  }

  const handleCancelSubscription = () => {
    if (!cancelNotificationsSuccesfullyReceived) {
      const cancelSubscriptionParameters = {
        storeId: product.storeId,
        auth: auth,
        setAuth: setAuth,
      }
      dispatch(cancelNotifications(cancelSubscriptionParameters))
      setCancelNotificationsRequested(true)
      setTimeout(() => setCancelNotificationsRequested(false), 2000)
    }
  }

  const checkIfAlreadySubscribed = () => {
    const {storesSubscribed} = auth?.userInfo
    if (storesSubscribed?.length && product) {
      return storesSubscribed?.includes(product.storeId)
    }
  }

  const handleCount = (count) => {
    const cartItemIndex = cartState.cart.items?.findIndex(
      (item) => item?.itemId === product?.id,
    )
    const cartItem = cartState?.cart.items[cartItemIndex]
    const noMoreStock = product.itemsCount >= cartItem?.itemsCount

    if (count === 0 || count === null || noMoreStock) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
    setCount(count)
    setPriceTotal(count * product?.price)
  }

  return (
    <ShowProduct
      auth={auth}
      product={product}
      handleCartProduct={handleCartProduct}
      handleSubscription={handleSubscription}
      handleCancelSubscription={handleCancelSubscription}
      handleCount={handleCount}
      count={count}
      priceTotal={priceTotal}
      disabled={disabled}
      notificationsDisabled={notificationsDisabled}
    />
  )
}

export default ShowProductContainer

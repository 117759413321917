import { ButtonRound } from '../common'
import { useNavigate } from 'react-router-dom'
import { IconBack } from '../../icons/IconBack'

export const ButtonBack = (props) => {
    const navigate = useNavigate()
    const handleClick = (e) => {
        e.preventDefault()
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1)
        } else {
            navigate('/', { replace: true }) // the current entry in the history stack will be replaced with the new one with { replace: true }
        }
    }
    return (
        <ButtonRound
            className="button-back"
            icon={IconBack}
            type="button"
            placeholder={null}
            onClick={handleClick}
        />
    )
}

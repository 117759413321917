import {actionsEnumChat} from '../../constants'

const initialState = {
  chatRooms: [],
  chatRoom: {
    id: null,
    chatRoomName: '',
    participants: [],
    userId: '',
    userName: '',
    userPicture: '',
    userPictureSignedUrl: '',
    receiverId: '',
    receiverName: '',
    receiverPicture: '',
    receiverPictureSignedUrl: '',
    messages: [],
  },
  messages: [],
  newMessages: [],
  msg: null,
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumChat.CREATE_CHAT_ROOM_RECEIVED:
      return {
        ...state,
        chatRoom: action.chatRoom ? action.chatRoom : state.chatRoom,
      }

    case actionsEnumChat.FETCH_CHAT_ROOMS_RECEIVED:
      return {
        ...state,
        chatRooms: action.chatRooms ? action.chatRooms : [],
      }

    case actionsEnumChat.JOIN_CHAT_ROOM_COMPLETED:
      return {
        ...state,
        chatRoom: action.chatRoom ? action.chatRoom : state.chatRoom,
      }

    case actionsEnumChat.JOIN_CHAT_ROOMS_COMPLETED:
      return {
        ...state,
        msg: action.msg,
      }

    case actionsEnumChat.RESET_CHAT_ROOM_COMPLETED:
      return {
        ...state,
        chatRoom: initialState.chatRoom,
      }

    case actionsEnumChat.FETCH_NEW_MESSAGES_COMPLETED:
      return {
        ...state,
        newMessages: action.newMessages ? action.newMessages : [],
      }

    case actionsEnumChat.SEND_MESSAGE_COMPLETED:
      const messagesUpdated = state.messages
      action.message ? messagesUpdated.push(action.message) : null
      return {
        ...state,
        messages: messagesUpdated,
      }

    case actionsEnumChat.RECEIVE_MESSAGE_COMPLETED:
      const foundInNewMessages = state.newMessages.length
        ? state.newMessages?.find((message) => message?.id === action.message?.id)
        : null
      const foundInMessages = state.messages.length
        ? state.messages.find((message) => message?.id === action.message?.id)
        : null
      if (
        state.chatRoom?.id === action.message?.chatRoomId &&
        !foundInMessages &&
        !foundInNewMessages
      ) {
        const messagesUpdated = state.messages
        const newMessagesUpdated = state.newMessages
        //if (!found) {
        if (action.message) {
          messagesUpdated.push(action.message)
          newMessagesUpdated.push(action.message)
        }
        return {
          ...state,
          messages: messagesUpdated,
          newMessages: newMessagesUpdated,
        }
        //}
      }
      const newMessagesUpdated = state.newMessages

      action.message &&
        !foundInMessages &&
        !foundInNewMessages &&
        newMessagesUpdated.push(action.message)

      //action.message ? newMessagesUpdated.push(action.message) : null
      return {
        ...state,
        newMessages: newMessagesUpdated,
      }

    case actionsEnumChat.READ_NEW_MESSAGE_COMPLETED:
      const newMessages =
        action.newMessages && action.newMessages.length ? action.newMessages : []
      return {
        ...state,
        newMessages: newMessages,
      }

    case actionsEnumChat.FETCH_MOST_RECENT_MESSAGES_RECEIVED:
      return {
        ...state,
        messages: action.messages ? action.messages : state.messages,
      }

    default:
      return state
  }
}
export default chatReducer

import {useTranslate} from 'react-redux-multilingual'
import {ButtonAddNote, ButtonRemove} from '../buttons'

export const BasketTable = (props) => {
  const {listOfItems, handleEditProductNote, handleRemoveItemFromList} = props
  const t = useTranslate()

  return (
    <div className="basket__main">
      <table border="1" cellPadding="10" cellSpacing="0" className="basket__main__list">
        <thead>
          <tr className="basket__main__list__headers">
            <th key="header-product-name" className="text-m-s ">
              {t('product_name')}
            </th>
            <th key="header-product-brand" className="text-m-s ">
              {t('product_brand')}
            </th>
            <th key="header-product-count" className="text-m-s ">
              {t('quantity')}
            </th>
            <th key="header-product-measurement" className="text-m-s ">
              {t('measurement')}
            </th>
            <th key="header-product-note" className="text-m-s "></th>
            <th key="header-product-remove" className="text-m-s "></th>
          </tr>
        </thead>
        <tbody className="basket__main__list__container">
          {listOfItems?.map((product, index) => (
            <>
              <tr
                className="basket__main__list__container__body"
                id={product.id}
                key={product.id}
              >
                <td key={`product-name ${product.id}`} className="text-l">
                  {product.productName}
                </td>
                <td key={`product-brand ${product.id}`} className="text-l">
                  {product.productBrand}
                </td>
                <td key={`product-count ${product.id}`} className="text-l">
                  {product.productCount}
                </td>
                <td key={`product-measurement-unit ${product.id}`} className="text-l">
                  {product.productMeasurementUnit}
                </td>
                <td key={`button-add-note ${product.id}`}>
                  {handleEditProductNote ? (
                    <ButtonAddNote handleClick={() => handleEditProductNote(index)} />
                  ) : null}
                </td>
                <td key={`button-remove ${product.id}`}>
                  {handleRemoveItemFromList ? (
                    <ButtonRemove handleClick={() => handleRemoveItemFromList(index)} />
                  ) : null}
                </td>
              </tr>
              {product.productNote && (
                <tr>
                  <td key={`product-note ${product.id}`} colSpan="6">
                    <div style={{padding: '10px', backgroundColor: '#f9f9f9'}}>
                      <strong>{`${t('product_note')}:  ${product.productName}`}</strong>
                      <p>{product.productNote}</p>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  )
}

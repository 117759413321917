import {actionsEnumSearch} from '../../../constants'

const searchRequestClear = () => ({
  type: actionsEnumSearch.SEARCH_REQUEST_CLEAR,
})

const searchClearDone = () => ({
  type: actionsEnumSearch.SEARCH_CLEAR_DONE,
})

export const searchClear = () => (dispatch) => {
  dispatch(searchRequestClear())
  dispatch(searchClearDone())
}

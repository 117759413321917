import {actionsEnumStore} from '../../constants'

const initialState = {
  store: {
    id: '',
    picture: '',
    pictureSignedUrl: '',
    userName: '',
    userId: '',
    name: '',
    description: '',
    location: '',
    address: '',
    type: '',
    products: '',
    categories: '',
    routes: '',
  },
  storeBasket: {},
  stores: [],
  msg: null,
}

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsEnumStore.FETCH_STORES_RECEIVED:
      return {
        ...state,
        stores: action.stores,
      }

    case actionsEnumStore.FETCH_STORE_RECEIVED:
      const storeFound = state?.stores?.find((store) => store.id === action.store.id)
      const newStores = storeFound ? state.stores : [...state.stores, action.store]
      return {
        ...state,
        store: action.store,
        stores: newStores,
      }

    case actionsEnumStore.REMOVE_STORE_BASKET_COMPLETED:
      return {
        ...state,
        storeBasket: {},
      }

    case actionsEnumStore.FETCH_STORE_BASKET_RECEIVED:
      return {
        ...state,
        storeBasket: action.storeBasket,
      }

    case actionsEnumStore.CREATE_STORE_RECEIVED:
      return {
        ...state,
        store: action.store,
      }

    case actionsEnumStore.UPDATE_STORE_RECEIVED:
      return {
        ...state,
        store: action.store,
      }

    case actionsEnumStore.DELETE_STORE_RECEIVED:
      return {
        ...state,
        store: [],
        msg: action.msg,
      }

    default:
      return state
  }
}
export default storeReducer

export const ButtonExtraLarge = (props) => {
  const {type, text, handleClick, disabled} = props

  return (
    <button
      className="button button-extra-large"
      type={type}
      onClick={handleClick}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

import React from 'react'
import {Gallery, ButtonCreateItem, ButtonLarge} from '../../../components'
import {RatingStars} from '../../../components'
import {useTranslate} from 'react-redux-multilingual'

export const DashboardStore = (props) => {
  const {
    handleCreateProduct,
    handleNavigateUpdateStore,
    handleNavigateUpdateProduct,
    pictureSignedUrl,
    auth,
    products,
    myProducts,
    store,
  } = props
  const t = useTranslate()

  return (
    <>
      <div className="background-circle" />
      <section className="item">
        <div className="item__top">
          <div className="item__top__left">
            <h1 className="item__top__left__user-name h4-bold ">
              {auth?.userInfo?.firstName}
            </h1>
          </div>
          <div className="item__top__right"></div>
        </div>
        <div className="layout__header">
          <h2 className="layout__header__label">{t('store_dashboard')}</h2>
        </div>

        <div className="item__picture">
          {pictureSignedUrl ? (
            <img className="img-user" src={pictureSignedUrl} />
          ) : (
            <div className="img-placeholder-large" />
          )}
        </div>
        <div className="item__main">
          <div className="item__main__rating">
            <div className="item__main__rating__stars">
              <RatingStars rating={store?.rating} />
            </div>
            <div className="item__main__rating__reviews">
              {/*<Link to="/reviews" className="item__main__rating__reviews__label text-l">
                                {reviews}
                                <h5 className="item__main__rating__reviews__number text-l ">
                                    {reviews} 5{' '}
                                </h5>
                                Reviews{' '}
                            </Link>*/}
            </div>
          </div>
          <div className="item__main__top">
            <h2 className="h2-bold">{store?.name}</h2>
            <h5 className="text-l">{store?.userName}</h5>
          </div>

          <div className="item__main__description">
            <h5 className="text-m-s">{t('description')}</h5>
            <p className="text-l">{store?.description}</p>
          </div>
          <div className="item__main__description">
            {/*<h5 className="text-m-s">Address</h5>
            <p className="text-l">{store?.address}</p>*/}
          </div>
          <div className="item__main__description">
            <h5 className="text-m-s">{t('location')}</h5>
            <p className="text-l">{store?.location}</p>
          </div>
          <div className="item__main__description">
            <h5 className="text-m-s">{t('type')}</h5>
            <p className="text-l">{store?.type}</p>
          </div>
        </div>
        <div className="item__main__bottom">
          <ButtonCreateItem
            title={t('create_product')}
            handleClick={handleCreateProduct}
          />
        </div>
        <div className="item__main__bottom">
          <ButtonLarge
            type="button"
            text={t('update_store')}
            handleClick={handleNavigateUpdateStore}
          />
        </div>
        <div className="dashboard-public__main__block">
          <Gallery
            title={t('your_products')}
            items={myProducts}
            target="product"
            callBack={handleNavigateUpdateProduct}
          />
        </div>
        <div className="dashboard-public__main__block">
          <Gallery title={t('other_store_products')} items={products} target="product" />
        </div>
      </section>
    </>
  )
}

import { actionsEnumCart } from '../../../constants';

export const addToCart = (itemCart) => (dispatch) => {
  dispatch(addItemToCart(itemCart));
  dispatch(itemAddedToCart());
};

const addItemToCart = (itemCart) => ({
  type: actionsEnumCart.ADD_TO_CART,
  payload: { itemCart: itemCart },
});

const itemAddedToCart = () => ({
  type: actionsEnumCart.ADDED_TO_CART,
});
import React from "react";

export const IconTruck = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            width={28}
            height={28}
            version="1.1"
            viewBox="0 0 1024 1024"
            fill="#646464"
        >
            <path d="M768 789.333a64 64 0 01-64-64 64 64 0 0164-64 64 64 0 0164 64 64 64 0 01-64 64m64-384L915.627 512H725.333V405.333M256 789.333a64 64 0 01-64-64 64 64 0 0164-64 64 64 0 0164 64 64 64 0 01-64 64m597.333-448h-128V170.667H128A85.038 85.038 0 0042.667 256v469.333H128a128 128 0 00128 128 128 128 0 00128-128h256a128 128 0 00128 128 128 128 0 00128-128h85.333V512l-128-170.667z"></path>
        </svg>
    );
}

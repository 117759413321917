export const actionsEnumChat = {
  CREATE_CHAT_ROOM_REQUEST: 'CREATE_CHAT_ROOM_REQUEST',
  CREATE_CHAT_ROOM_RECEIVED: 'CREATE_CHAT_ROOM_RECEIVED',
  FETCH_CHAT_ROOMS_REQUEST: 'FETCH_CHAT_ROOMS_REQUEST',
  FETCH_CHAT_ROOMS_RECEIVED: 'FETCH_CHAT_ROOMS_RECEIVED',
  JOIN_CHAT_ROOM_REQUEST: 'JOIN_CHAT_ROOM_REQUEST',
  JOIN_CHAT_ROOM_COMPLETED: 'JOIN_CHAT_ROOM_COMPLETED',
  JOIN_CHAT_ROOMS_REQUEST: 'JOIN_CHAT_ROOMS_REQUEST',
  JOIN_CHAT_ROOMS_COMPLETED: 'JOIN_CHAT_ROOMS_COMPLETED',
  RESET_CHAT_ROOM_REQUEST: 'RESET_CHAT_ROOM_REQUEST',
  RESET_CHAT_ROOM_COMPLETED: 'RESET_CHAT_ROOM_COMPLETED',
  FETCH_NEW_MESSAGES_REQUEST: 'FETCH_NEW_MESSAGES_REQUEST',
  FETCH_NEW_MESSAGES_COMPLETED: 'FETCH_NEW_MESSAGES_COMPLETED',
  FETCH_MOST_RECENT_MESSAGES_REQUEST: 'FETCH_MOST_RECENT_MESSAGES_REQUEST',
  FETCH_MOST_RECENT_MESSAGES_RECEIVED: 'FETCH_MOST_RECENT_MESSAGES_RECEIVED',
  SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_COMPLETED: 'SEND_MESSAGE_COMPLETED',
  READ_NEW_MESSAGE_REQUEST: 'READ_NEW_MESSAGE_REQUEST',
  READ_NEW_MESSAGE_COMPLETED: 'READ_NEW_MESSAGE_COMPLETED',
  RECEIVE_MESSAGE_REQUEST: 'RECEIVE_MESSAGE_REQUEST',
  RECEIVE_MESSAGE_COMPLETED: 'RECEIVE_MESSAGE_COMPLETED',
  RECEIVE_MESSAGES: 'RECEIVE_MESSAGES',
  RECEIVE_MESSAGES_COMPLETED: 'RECEIVE_MESSAGES_COMPLETED',
}

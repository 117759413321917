import { ButtonSmall } from "../common"

const ModalCategorySuggestion = (props) => {
    const { categoryName, handleClickAccepted, handleClickRejected } = props

    return (<>
        <div className="modal-category-name-suggestion">
            <h4 className="modal-category-name-suggestion__title">Category Suggestion Confirmation</h4>
            <div className="modal-category-name-suggestion__box">
                <h4 className="modal-category-name-suggestion__box__text text-b-m">Do you mean?</h4>
                <h4 className="modal-category-name-suggestion__box__name">{categoryName}</h4>
            </div>
            <div className="modal-category-name-suggestion__actions">
                <ButtonSmall type='button' text='No' onClick={handleClickRejected} />
                <ButtonSmall type='button' text='Yes' onClick={handleClickAccepted} />
            </div>
        </div>
    </>)
}

export { ModalCategorySuggestion }
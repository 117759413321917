import axios from './axios'
import {
  URL_ORDER,
  URL_ORDERS_GET_ALL_BY_USER,
  URL_ORDERS_GET_ALL_NEW_BY_USER,
  URL_ORDER_ACCEPT,
  URL_ORDER_CLOSE,
  URL_ORDER_REJECT,
  URL_ORDER_RESUME,
  URL_READ_NEW_ORDER,
} from '../urls'

const appendToFormData = (orderParameters) => {
  const {
    buyerId,
    buyerName,
    buyerAddress,
    buyerLocation,
    buyerPicture,
    buyerPictureSignedUrl,
    storeName,
    storeId,
    storeAddress,
    storeLocation,
    storePicture,
    storePictureSignedUrl,
    basketItems,
    cartId,
    orderItems,
    subTotal,
    total,
    shipping,
    pickup,
    delivery,
    deliveryStatus,
    deliveryAddress,
    deliveryLocation,
    deliveryNote,
    deliveryDateRangeStart,
    deliveryTimeRangeStart,
    deliveryDateRangeEnd,
    deliveryTimeRangeEnd,
    pending,
    accepted,
    rejected,
    delivered,
  } = orderParameters.fields

  const formData = new FormData()
  formData.append('buyerId', buyerId)
  formData.append('buyerName', buyerName)
  formData.append('buyerAddress', '')
  formData.append(
    'buyerLocation',
    typeof buyerLocation !== 'string' ? JSON.stringify(buyerLocation) : buyerLocation,
  )
  formData.append('buyerPicture', buyerPicture)
  formData.append('buyerPictureSignedUrl', buyerPictureSignedUrl)
  formData.append('storeId', storeId)
  formData.append('storeName', storeName)
  formData.append('storeAddress', '')
  formData.append(
    'storeLocation',
    typeof storeLocation !== 'string' ? JSON.stringify(storeLocation) : storeLocation,
  )
  formData.append('storePicture', storePicture)
  formData.append('storePictureSignedUrl', storePictureSignedUrl)
  formData.append('basketItems', JSON.stringify(basketItems))
  formData.append('cartId', cartId ? cartId : null)
  formData.append('orderItems', JSON.stringify(orderItems))
  formData.append('shipping', shipping ? shipping : '0')
  formData.append('subTotal', subTotal ? subTotal : '0')
  formData.append('total', total ? total : '0')
  formData.append('pickup', pickup)
  formData.append('delivery', delivery)
  formData.append('deliveryStatus', deliveryStatus)
  formData.append('deliveryNote', deliveryNote ? deliveryNote : '')
  formData.append('deliveryAddress', '')
  formData.append('deliveryLocation', deliveryLocation)
  formData.append(
    'deliveryDateRangeStart',
    typeof deliveryDateRangeStart !== 'string'
      ? JSON.stringify(deliveryDateRangeStart)
      : deliveryDateRangeStart,
  )
  formData.append(
    'deliveryTimeRangeStart',
    typeof deliveryTimeRangeStart !== 'string'
      ? JSON.stringify(deliveryTimeRangeStart)
      : deliveryTimeRangeStart,
  )

  formData.append(
    'deliveryDateRangeEnd',
    typeof deliveryDateRangeEnd !== 'string'
      ? JSON.stringify(deliveryDateRangeEnd)
      : deliveryDateRangeEnd,
  )
  formData.append(
    'deliveryTimeRangeEnd',
    typeof deliveryTimeRangeEnd !== 'string'
      ? JSON.stringify(deliveryTimeRangeEnd)
      : deliveryTimeRangeEnd,
  )
  formData.append('pending', pending)
  formData.append('accepted', accepted)
  formData.append('rejected', rejected)
  formData.append('delivered', delivered)
  return formData
}

const getOrderRequestAPI = async (requestParameters) => {
  const {auth, orderId} = requestParameters
  try {
    const response = await axios.get(`${URL_ORDER}/${orderId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    return processResponse(response)
  } catch (err) {
    return err
  }
}

const getOrdersRequestAPI = async (requestParameters) => {
  const {auth} = requestParameters
  try {
    const response = await axios.get(
      `${URL_ORDERS_GET_ALL_BY_USER}/${auth?.userInfo?.id}`,
      {
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${auth?.accessToken}`,
          User: `${auth?.userInfo?.id}`,
        },
        withCredentials: true,
      },
    )
    const {orders} = response.data

    const ordersWithId = orders.map((order) => {
      const response = {data: {order}}
      return processResponse(response)
    })

    return ordersWithId
  } catch (err) {
    return err
  }
}

const getNewOrdersRequestAPI = async (requestParameters) => {
  const {auth} = requestParameters
  try {
    const response = await axios.get(
      `${URL_ORDERS_GET_ALL_NEW_BY_USER}/${auth?.userInfo?.id}`,
      {
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${auth?.accessToken}`,
          User: `${auth?.userInfo?.id}`,
        },
        withCredentials: true,
      },
    )
    const {orders} = response.data

    const ordersWithId = orders.map((order) => {
      const response = {data: {order}}
      return processResponse(response)
    })

    return ordersWithId
  } catch (err) {
    return err
  }
}

const createOrderAPI = async (requestParameters) => {
  const {orderParameters, auth} = requestParameters
  const formData = appendToFormData(orderParameters)

  try {
    const response = await axios.post(URL_ORDER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })

    return processResponse(response)
  } catch (err) {
    return err
  }
}

const updateOrderAPI = async (requestParameters) => {
  const {orderParameters, auth} = requestParameters
  const formData = appendToFormData(orderParameters)
  const orderId = orderParameters.fields.id
  try {
    const response = await axios.patch(`${URL_ORDER}/${orderId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.order
  } catch (err) {
    return err.response.data
  }
}

const readNewOrderAPI = async (requestParameters) => {
  const {orderId, auth} = requestParameters

  try {
    const response = await axios.get(`${URL_READ_NEW_ORDER}/${orderId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.order
  } catch (err) {
    return err.response
  }
}

const deleteOrderAPI = async (orderId, auth) => {
  try {
    const response = await axios.delete(`${URL_ORDER}/${orderId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },

      withCredentials: true,
    })
    return response.data.msg
  } catch (err) {
    return err
  }
}

const acceptOrderAPI = async (requestParameters) => {
  const {orderId, auth} = requestParameters
  try {
    const response = await axios.post(`${URL_ORDER_ACCEPT}/${orderId}`, null, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })

    return processResponse(response)
  } catch (err) {
    return err
  }
}

const rejectOrderAPI = async (requestParameters) => {
  const {orderId, auth} = requestParameters
  try {
    const response = await axios.post(`${URL_ORDER_REJECT}/${orderId}`, null, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })

    return processResponse(response)
  } catch (err) {
    return err
  }
}

const closeOrderAPI = async (requestParameters) => {
  const {orderId, auth} = requestParameters
  try {
    const response = await axios.post(`${URL_ORDER_CLOSE}/${orderId}`, null, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })

    return processResponse(response)
  } catch (err) {
    return err
  }
}

const resumeOrderAPI = async (requestParameters) => {
  const {orderId, auth} = requestParameters
  try {
    const response = await axios.post(`${URL_ORDER_RESUME}/${orderId}`, null, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })

    return processResponse(response)
  } catch (err) {
    return err
  }
}

const processResponse = (response) => {
  const {
    _id,
    basketItems,
    orderItems,
    deliveryDateRangeEnd,
    deliveryDateRangeStart,
    deliveryTimeRangeEnd,
    deliveryTimeRangeStart,
    ...rest
  } = response.data.order
  const orderWithId = {
    id: _id,
    basketItems: typeof basketItems === 'object' ? basketItems : JSON.parse(basketItems),
    orderItems: JSON.parse(orderItems),
    deliveryDateRangeEnd:
      typeof deliveryDateRangeEnd === 'string'
        ? deliveryDateRangeEnd
        : JSON.parse(deliveryDateRangeEnd),
    deliveryDateRangeStart:
      typeof deliveryDateRangeStart === 'string'
        ? deliveryDateRangeStart
        : JSON.parse(deliveryDateRangeStart),
    deliveryTimeRangeEnd:
      typeof deliveryTimeRangeEnd === 'string'
        ? deliveryTimeRangeEnd
        : JSON.parse(deliveryTimeRangeEnd),
    deliveryTimeRangeStart:
      typeof deliveryTimeRangeStart === 'string'
        ? deliveryTimeRangeStart
        : JSON.parse(deliveryTimeRangeStart),
    ...rest,
  }
  return orderWithId
}

export {
  getOrderRequestAPI,
  getOrdersRequestAPI,
  getNewOrdersRequestAPI,
  createOrderAPI,
  deleteOrderAPI,
  updateOrderAPI,
  readNewOrderAPI,
  acceptOrderAPI,
  rejectOrderAPI,
  closeOrderAPI,
  resumeOrderAPI,
}

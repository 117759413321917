import axios from './axios'
import {URL_RATING} from '../urls'

export const postRatingApi = async (requestParameters) => {
  const {raterId, targetId, orderId, rate, targetType, auth} = requestParameters
  const formData = new FormData()
  formData.append('raterId', raterId)
  formData.append('targetId', targetId)
  formData.append('orderId', orderId)
  formData.append('rate', rate)
  formData.append('targetType', targetType)

  try {
    const response = await axios.post(URL_RATING, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    return response?.data?.message
  } catch (err) {
    return err
  }
}

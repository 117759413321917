import React, { useState } from 'react'
import Select from 'react-select'

export const InputSelect = (props) => {
    const [isClearable, setIsClearable] = useState(true)
    const [isSearchable, setIsSearchable] = useState(true)
    const [isDisabled, setIsDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isRtl, setIsRtl] = useState(false)
    const { categoryOptions, categoryValue, handleChange, label } = props
    const defaultValue = categoryValue

    return (
        <>
            <div className="input" >
                <label className="input-select-group__label text-m-s">{label}</label>
                <Select
                    className="input-large input-select"
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="category"
                    options={categoryOptions}
                    onChange={handleChange}
                />
            </div>

        </>
    )
}

import { ItemAdder } from '../itemAdder'
import { ButtonCancel } from '../buttons'

export const CardItemCart = (props) => {
    const {
        id,
        title,
        category,
        img,
        price,
        total,
        measurementUnit,
        totalItems,
        itemsCount,
        handleOpenItem,
        handleQuantity,
        handleRemoveItem,
    } = props

    const handleRemove = () => {
        handleRemoveItem(id)
    }

    const handleItemQuantity = (quantity) => {
        handleQuantity(id, quantity)
    }
    return (
      <div className="card-item-cart">
        <div className="card-item-cart__left">
          <img className="card-item-cart__img" src={img} onClick={handleOpenItem} />
        </div>
        <div className="card-item-cart__text">
          <h5 className="card-item-cart__title h3-bold">{title}</h5>
          <h5 className="card-item-cart__category text-s ">Fruits{category}</h5>
          <div className="card-item-cart__text__price-measurement">
            <h5 className="card-item-cart__price text-m-m">${price}</h5>
            <h5 className="card-item-cart__price text-s">/ {measurementUnit}</h5>
          </div>
        </div>
        <div className="card-item-cart__quantity">
          <ItemAdder
            totalItems={totalItems}
            handleQuantity={handleItemQuantity}
            stock={itemsCount}
          />
        </div>
        <div className="card-item-cart__right">
          <ButtonCancel handleClick={handleRemove} />
          <div className="card-item-cart__right__total">
            <h5 className="card-item-cart__price text-s">Total</h5>
            <h5 className="card-item-cart__price text-m-m">${total}</h5>
          </div>
        </div>
      </div>
    )
}

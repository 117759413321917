import { actionsEnumOrder } from '../../../constants';
import { getOrdersRequestAPI } from '../../../api';

const fetchOrdersRequest = () => ({
  type: actionsEnumOrder.FETCH_ORDERS_REQUEST,
});

const fetchOrdersReceived = (orders) => ({
  type: actionsEnumOrder.FETCH_ORDERS_RECEIVED,
  orders,
});

export const fetchOrders = (requestParameters) => (dispatch) => {
  dispatch(fetchOrdersRequest());
  getOrdersRequestAPI(requestParameters).then((orders) => {
    dispatch(fetchOrdersReceived(orders));
    return orders;
  });
};

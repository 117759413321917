import {useTranslate} from 'react-redux-multilingual'
import {Bubble, BubbleOther} from '../../components'

export const Messages = (props) => {
  const {data, chatRoom, auth} = props
  const reverseData = data?.slice().reverse()
  const t = useTranslate()
  const ownMessage = (message) => {
    const userName = auth?.userInfo?.firstName
    const storeName = auth?.userInfo?.storeName
    const ownedMessage =
      message?.authorName === userName || message?.authorName === storeName
    return ownedMessage
  }

  return data.length ? (
    <div className="chat-room__messages" key={Math.random()}>
      <h5>
        {t('room_name')}
        {chatRoom.receiverName} - {chatRoom.userName}
      </h5>
      <h6>{t('messages')}</h6>
      <div className="chat-room__messages__list">
        {reverseData.map((message) => {
          const own = ownMessage(message)
          return own ? (
            <Bubble message={message} key={Math.random()} />
          ) : (
            <BubbleOther message={message} key={Math.random()} />
          )
        })}
      </div>
    </div>
  ) : (
    <></>
  )
}

import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonBack,
  ButtonChatCreate,
  ModalConfirmation,
  Rating,
} from '../../../components'
import {ButtonExtraLarge} from '../../../components/common/commonButtons'
import {BasketTable} from '../../../components/tables/BasketTable'

export const ShowOrder = (props) => {
  const {
    handleAcceptOrder,
    handleCancelOrder,
    handleRejectOrder,
    handleCloseOrder,
    handleResumeOrder,
    handleChat,
    auth,
    order,
    deliveryStatus,
    chatStatus,
    classDeliveryStatus,
    ratingModal,
  } = props.parameters
  const t = useTranslate()

  return (
    <>
      {ratingModal ? (
        <Rating
          buyerId={order.buyerId}
          buyerName={order.buyerName}
          storeId={order.storeId}
          storeName={order.storeName}
          orderItems={order.orderItems}
          orderId={order.id}
          auth={auth}
        />
      ) : null}

      <section className="order">
        <div className="order__top">
          <div className="order__top__left">
            <ButtonBack />
            <h1 className="order__top__left__user-name h4-bold ">
              {auth?.userInfo?.firstName}
            </h1>
          </div>
        </div>
        <div className="layout__header">
          <h2 className="layout__header__label">{t('order')}</h2>
        </div>
        <div>
          <div className="order__user">
            <img
              className="order_user_img img-user-small"
              src={order?.buyerPictureSignedUrl}
            />
            <div className="order__user__left">
              <div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s ">
                  {t('buyer_name')}
                </h4>
                <h3 name="buyerName" className="order__user__left__field__text text-s ">
                  {order?.buyerName}
                </h3>
              </div>
              <div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s">
                  {t('buyer_id')}
                </h4>
                <h3 className="order__user__left__field__text text-s ">
                  {order?.buyerId}
                </h3>
              </div>
            </div>

            <div className="order__user__right">
              {/*<div className="order__user__left__field">
                                <h4 className="order__user__left__field__label text-m-s">Buyer address:</h4>
                                <h3 className="order__user__left__field__text text-s ">
                                    {order?.buyerAddress}
                                </h3>
                            </div>*/}
              <div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s">
                  {t('buyer_location')}
                </h4>
                <h3 className="order__user__left__field__text text-s ">
                  {order?.buyerLocation}
                </h3>
              </div>
            </div>
          </div>

          <div className="order__user">
            <div className="order__user__left">
              <img
                className="order_user_img img-user-small"
                src={order?.storePictureSignedUrl}
              />
              <ButtonChatCreate handleClick={handleChat} status={chatStatus} />
            </div>
            <div className="order__user__left">
              <div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s">
                  {t('store_name')}
                </h4>
                <h3 className="order__user__left__field__text text-s ">
                  {order?.storeName}
                </h3>
              </div>
              <div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s">
                  {t('store_id')}
                </h4>
                <h3 className="order__user__left__field__text text-s ">
                  {order?.storeId}
                </h3>
              </div>
            </div>
            <div className="order__user__right">
              {/*<div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s">
                  {t('store_')}
                </h4>
                <h3 className="order__user__left__field__text text-s ">
                  {order?.storeAddress}
                </h3>
              </div>*/}
              <div className="order__user__left__field">
                <h4 className="order__user__left__field__label text-m-s">
                  {t('store_location')}
                </h4>
                <h3 className="order__user__left__field__text text-s ">
                  {order?.storeLocation}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="order__details__show">
          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_range_start')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryDateRangeStart}
              </h3>
            </div>
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_range_end')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryDateRangeEnd}
              </h3>
            </div>
          </div>

          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_time_start')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryTimeRangeStart}
              </h3>
            </div>
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_date_time_end')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryTimeRangeEnd}
              </h3>
            </div>
          </div>
          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_location')}
              </h4>
              <h3 className="order__user__field__text text-s ">
                {order?.deliveryLocation}
              </h3>
            </div>
            {/*<div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">Delivery address</h4>
              <h3 className="order__user__field__text text-s ">{order?.deliveryAddress}</h3>
            </div>*/}
          </div>

          <div className="order__details__show__group">
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">{t('delivery_note')}</h4>
              <h3 className="order__user__field__text text-s ">{order?.deliveryNote}</h3>
            </div>
            <div className="order__details__show__group__field">
              <h4 className="order__user__field__label text-m-s">
                {t('delivery_status')}
              </h4>
              <h3 className={`order__user__field__text text-s ${classDeliveryStatus}`}>
                {deliveryStatus}
              </h3>
            </div>
          </div>

          <div className="order__cart__bottom">
            <h3 className="text-m-l">{t('products')}</h3>
            <div className="order__cart__bottom__details">
              {order?.orderItems?.length ? (
                <>
                  <div className="order__cart__bottom__details__products__headers">
                    <h4 className="text-m-s">{t('product')}</h4>
                    <h4 className="text-m-s">{t('price')}</h4>
                    <h4 className="text-m-s">{t('quantity')}</h4>
                    <h4 className="text-m-s">Total</h4>
                  </div>
                  <div className="line"></div>
                  {order.orderItems.map((item) => (
                    <div
                      className="order__cart__bottom__details__products"
                      key={item.itemName}
                    >
                      <div className="order__cart__bottom__details__products__img">
                        <img
                          className="order_user_img img-user-small"
                          src={item.pictureSignedUrl}
                        />
                        <h4 className="text-l">{item.itemName}</h4>{' '}
                      </div>

                      <div className="order__cart__bottom__details__products__price">
                        <h4 className="text-l">${item.price}</h4>
                        <p className="text-xs">/{item.measurementUnit}</p>
                      </div>

                      <h4 className="text-l">x{item.itemsCount} </h4>
                      <h4 className="text-l">${item.total}</h4>
                    </div>
                  ))}
                </>
              ) : null}
              {order?.basketItems?.length ? (
                <BasketTable
                  listOfItems={order.basketItems}
                  handleEditProductNote={null}
                  handleRemoveItemFromList={null}
                />
              ) : null}
              <div className="line"></div>
              <h3 className="text-m-l">{t('bill_details')}</h3>
              <div className="order__cart__bottom__details__subtotal">
                <h4 className="text-l">Subtotal</h4>
                <h4 className="text-l">${order?.subTotal}</h4>
              </div>
              <div className="order__cart__bottom__details__shipping">
                <h4 className="text-l">{t('shipping')}</h4>
                <h4 className="text-l">${order?.shipping}</h4>
              </div>
              <div className="order__cart__bottom__details__total">
                <h4 className="text-m-l">Total</h4>
                <h4 className="text-m-l">${order?.total}</h4>
              </div>

              <div className="order__cart__bottom__details__total">
                <h4 className="text-m-l">{t('order_status')}</h4>
                <h4 className={`text-m-l ${classDeliveryStatus}`}>{deliveryStatus}</h4>
              </div>

              {auth?.userInfo?.id === order?.buyerId &&
              (order?.deliveryStatus === 'Pending' ||
                order.orderStatus === 'accepted') ? (
                <ModalConfirmation
                  title={t('confirm')}
                  description={t('are_you_sure')}
                  callbackFunction={handleCancelOrder}
                >
                  {(confirm) => (
                    <ButtonExtraLarge
                      type="Cancel Order"
                      text={t('cancel_order')}
                      handleClick={confirm(handleCancelOrder)}
                    />
                  )}
                </ModalConfirmation>
              ) : null}
              {auth?.userInfo?.storeId === order?.storeId &&
              order?.deliveryStatus === 'Pending' ? (
                <>
                  <ModalConfirmation
                    title={t('confirm')}
                    description={t('are_you_sure')}
                    callbackFunction={handleAcceptOrder}
                  >
                    {(confirm) => (
                      <ButtonExtraLarge
                        type="Accept Order"
                        text={t('accept_order')}
                        handleClick={confirm(handleAcceptOrder)}
                      />
                    )}
                  </ModalConfirmation>

                  <ModalConfirmation
                    title={t('confirm')}
                    description={t('are_you_sure')}
                    callbackFunction={handleRejectOrder}
                  >
                    {(confirm) => (
                      <ButtonExtraLarge
                        type="Reject Order"
                        text={t('reject_order')}
                        handleClick={confirm(handleRejectOrder)}
                      />
                    )}
                  </ModalConfirmation>
                </>
              ) : null}
              {auth?.userInfo?.storeId === order?.storeId &&
              order?.deliveryStatus === 'Accepted' ? (
                <>
                  <ModalConfirmation
                    title="Confirm"
                    description="Are you sure?"
                    callbackFunction={handleRejectOrder}
                  >
                    {(confirm) => (
                      <ButtonExtraLarge
                        type="Reject Order"
                        text={t('reject_order')}
                        handleClick={confirm(handleRejectOrder)}
                      />
                    )}
                  </ModalConfirmation>

                  <ModalConfirmation
                    title={t('confirm')}
                    description={t('are_you_sure')}
                    callbackFunction={handleCloseOrder}
                  >
                    {(confirm) => (
                      <ButtonExtraLarge
                        type="Close Order"
                        text={t('close_order')}
                        handleClick={confirm(handleCloseOrder)}
                      />
                    )}
                  </ModalConfirmation>
                </>
              ) : null}

              {auth?.userInfo?.storeId === order?.storeId &&
              order?.deliveryStatus === 'Rejected' ? (
                <>
                  <ModalConfirmation
                    title={t('resume')}
                    description={t('are_you_sure')}
                    callbackFunction={handleResumeOrder}
                  >
                    {(confirm) => (
                      <ButtonExtraLarge
                        type="Resume Order"
                        text={t('resume_order')}
                        handleClick={confirm(handleResumeOrder)}
                      />
                    )}
                  </ModalConfirmation>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

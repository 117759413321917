import { actionsEnumOrder } from '../../../constants';
import { rejectOrderAPI } from '../../../api';

const rejectOrderRequest = () => ({
  type: actionsEnumOrder.REJECT_ORDER_REQUEST,
});

const rejectOrderReceived = (order) => ({
  type: actionsEnumOrder.REJECT_ORDER_RECEIVED,
  order,
});

export const rejectOrder = (requestParameters) => (dispatch) => {
  dispatch(rejectOrderRequest());
  rejectOrderAPI(requestParameters).then((order) => {
    dispatch(rejectOrderReceived(order));
    return order;
  });
};

import { actionsEnumCart } from '../../../constants';

const removeGroupRequest = (group) => ({
    type: actionsEnumCart.REMOVE_GROUP,
    group,
});

export const removeGroup = (requestParameters) => (dispatch) => {
    dispatch(removeGroupRequest(requestParameters));
};

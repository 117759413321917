import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ShowSearchResult} from './ShowSearchResult'
import {searchClear} from '../../redux'

const ShowSearchResultContainer = () => {
  const dispatch = useDispatch()
  const searchState = useSelector((state) => state.searchReducer)
  const [searchQuery, setSearchQuery] = useState('')
  const [storeSearchResult, setStoreSearchResult] = useState([])
  const [productSearchResult, setProductSearchResult] = useState([])

  useEffect(() => {
    if (
      (searchState?.storeSearchResult?.length &&
        searchState?.storeSearchResult !== storeSearchResult) ||
      (searchState?.productSearchResult?.length &&
        searchState?.productSearchResult !== productSearchResult &&
        !searchQuery)
    ) {
      setStoreSearchResult(searchState.storeSearchResult)
      setProductSearchResult(searchState.productSearchResult)
      setSearchQuery(searchState.searchQuery)
      dispatch(searchClear())
    }
  }, [searchState])

  return (
    <ShowSearchResult
      storeSearchResult={storeSearchResult}
      productSearchResult={productSearchResult}
      searchQuery={searchQuery}
    />
  )
}

export default ShowSearchResultContainer

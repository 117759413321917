import {useTranslate} from 'react-redux-multilingual'
import {ButtonBack, ButtonExtraLarge, GalleryCart} from '../../components'

export const Cart = (props) => {
  const {
    handleOpenItem,
    handleQuantity,
    handleRemoveItem,
    handleOrder,
    groupsByStore,
    subTotal,
    shipping,
    total,
  } = props.parameters
  const t = useTranslate()

  return (
    <section className="items">
      <div className="items__top">
        <div className="items__top__left">
          <ButtonBack />
          <h1 className="items__top__left__user-name h4-bold ">User Name</h1>
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{t('cart')}</h2>
      </div>
      <div className="items__groups">
        {groupsByStore?.length ? (
          groupsByStore?.map((group) => {
            const key = Math.random()
            return (
              <div className="items__group" key={key}>
                <h3 className="items__group__title h2-bold">{group.storeName}</h3>
                <div className="items__group__gallery">
                  <GalleryCart
                    title="Cart"
                    items={group?.storeItems}
                    handleOpenItem={handleOpenItem}
                    handleQuantity={handleQuantity}
                    handleRemoveItem={handleRemoveItem}
                  />
                </div>
              </div>
            )
          })
        ) : (
          <h6 className="h5-bold">{t('no_items')} </h6>
        )}
      </div>
      <div className="items__cart__bottom">
        {groupsByStore?.length ? (
          <div className="items__cart__bottom__details">
            <div className="items__cart__bottom__details__subtotal">
              <h4 className="text-l">Subtotal</h4>
              <h4 className="text-l">${subTotal}</h4>
            </div>
            <div className="items__cart__bottom__details__shipping">
              <h4 className="text-l">{t('shipping')}</h4>
              <h4 className="text-l">${shipping}</h4>
            </div>
            <div className="items__cart__bottom__details__total">
              <h4 className="text-m-l">Total</h4>
              <h4 className="text-m-l">${total}</h4>
            </div>
            <ButtonExtraLarge
              type="submit"
              text={t('order_now')}
              handleClick={handleOrder}
            />
          </div>
        ) : null}
      </div>
    </section>
  )
}

import { ButtonRoundSmall } from '../common';
import { StatusPilot } from './StatusPilot'
import { IconCart } from '../../icons/IconCart'

export const ButtonCart = (props) => {
    const { handleClick, status } = props
    return (
        <div className="button-notifications-container">
            <StatusPilot status={status} />
            <ButtonRoundSmall className="button-round-notifications" icon={IconCart} type="button" placeholder={null} onClick={handleClick} />
        </div>
    );
}
import axios from './axios'
import { URL_LOGIN } from '../urls';

export const loginApi = async (props) => {
    const { user, pwd } = props
    let message = null
    try {
        const response = await axios.post(URL_LOGIN, JSON.stringify({ user, pwd }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        })
        return response;
    } catch (err) {
        let error = err?.response?.data
        if (!err?.response) {
            console.log('No Server Response')
            message = 'No Server Response'
        } else if (err.response?.status === 400) {
            console.log('Missing Username or Password')
            message = 'Missing Username or Password'
        } else if (err.response?.status === 401) {
            console.log('This User is Unauthorized or Does Not Exist')
            message = 'This User is Unauthorized or Does Not Exist'
        } else {
            console.log('Login Failed')
            message = 'Login Failed'
        }
        return message
    }
};
import { actionsEnumCart } from '../../../constants';
import { createCartAPI } from '../../../api';

const createCartRequest = () => ({
  type: actionsEnumCart.CREATE_CART_REQUEST,
});

const createCartReceived = (cart) => ({
  type: actionsEnumCart.CREATE_CART_RECEIVED,
  cart,
});

export const createCart = (requestParameters) => (dispatch) => {
  dispatch(createCartRequest());
  createCartAPI(requestParameters).then((cart) => {
    dispatch(createCartReceived(cart));
    return cart;
  });
};

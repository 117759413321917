import { actionsEnumProducts } from '../../../constants';
import { getProductsByCategoryAndIndexRequestAPI } from '../../../api';

const fetchProductsByCategoryAndIndexRequest = () => ({
  type: actionsEnumProducts.FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_REQUEST,
});

const fetchProductsByCategoryAndIndexReceived = (products) => ({
  type: actionsEnumProducts.FETCH_PRODUCTS_BY_CATEGORY_AND_INDEX_RECEIVED,
  products,
});

export const fetchProductsByCategoryAndIndex = (props) => (dispatch) => {
  dispatch(fetchProductsByCategoryAndIndexRequest());
  getProductsByCategoryAndIndexRequestAPI(props).then((products) => {
    dispatch(fetchProductsByCategoryAndIndexReceived(products));
    return products;
  });
};

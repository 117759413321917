import {actionsEnumProducts} from '../../../constants'
import {getMyProductsRequestAPI} from '../../../api'

const fetchMyProductsRequest = () => ({
  type: actionsEnumProducts.FETCH_MY_PRODUCTS_REQUEST,
})

const fetchMyProductsReceived = (myProducts) => ({
  type: actionsEnumProducts.FETCH_MY_PRODUCTS_RECEIVED,
  myProducts,
})

export const fetchMyProducts = (requestArguments) => (dispatch) => {
  dispatch(fetchMyProductsRequest())
  getMyProductsRequestAPI(requestArguments).then((myProducts) => {
    dispatch(fetchMyProductsReceived(myProducts))
    return myProducts
  })
}

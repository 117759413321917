import axios from './axios'
import {URL_UPDATE_PICTURE_URL} from '../urls'

export const updatePictureUrlAPI = async (requestParameters) => {
  const {auth, pictureName} = requestParameters
  try {
    const response = await axios.get(`${URL_UPDATE_PICTURE_URL}/${pictureName}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })
    return response.data.url
  } catch (err) {
    return err
  }
}

import { actionsEnumChat } from '../../../constants';
import { sendMessageAPI } from '../../../api';

const sendMessageRequest = () => ({
    type: actionsEnumChat.SEND_MESSAGE_REQUEST,
});

const sendMessageCompleted = (message) => ({
    type: actionsEnumChat.SEND_MESSAGE_COMPLETED,
    message,
});

export const sendMessage = (requestParameters) => (dispatch) => {
    dispatch(sendMessageRequest());
    sendMessageAPI(requestParameters).then((message) => {
        dispatch(sendMessageCompleted(message));
        return message;
    });
};

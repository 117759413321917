import {ButtonRoundSmall} from '../common'
import {StatusPilot} from './StatusPilot'
import {useNavigate} from 'react-router-dom'
import {IconBell} from '../../icons/IconBell'

export const ButtonNotifications = (props) => {
  const navigate = useNavigate()
  const {status} = props

  const handleClick = () => {
    navigate('/notifications', {replace: true})
  }

  return (
    <div className="button-notifications-container">
      <StatusPilot status={status} />
      <ButtonRoundSmall
        className="button-round-notifications"
        icon={IconBell}
        type="button"
        placeholder={null}
        onClick={handleClick}
      />
    </div>
  )
}

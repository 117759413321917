import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {isEqual} from 'lodash'
import {
  addToCart,
  removeFromCart,
  removeUnitFromCart,
} from '../../redux/actions/cartActions'
import {generateItemCart} from './helpers/cartHelpers'
import useAuth from '../../hooks/session/useAuth'
import {Cart} from './Cart'

export const CartContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartState = useSelector((state) => state.cartReducer)
  const {auth} = useAuth()
  const [cart, setCart] = useState()
  const [msg, setMsg] = useState(null)
  const [userId, setUserId] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  const [itemsCount, setItemsCount] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [total, setTotal] = useState(0)
  const [deleteRequested, setDeleteRequested] = useState(false)
  const [items, setItems] = useState(0)
  const [groupsByStore, setGroupsByStore] = useState([{}])

  useEffect(() => {
    if (deleteRequested || cartState?.cart?.items?.length === 0) {
      setMsg(cartState?.msg)
      setCart(null)
      setTimeout(() => {
        navigate('/')
      }, 1000)
    }

    if (!isEqual(cartState?.cart, cart)) {
      const receivedCart = cartState?.cart
      receivedCart.userId = auth?.userInfo?.id
      receivedCart.userInfo = auth?.userInfo
      setUserId(receivedCart.userId)
      setUserInfo(receivedCart.userInfo)
      setGroupsByStore(receivedCart.groupsByStore)
      setItems(receivedCart.items)
      setItemsCount(receivedCart.itemsCount)
      setSubTotal(receivedCart.subTotal)
      setShipping(receivedCart.shipping)
      setTotal(receivedCart.total)
      setCart(receivedCart)
      setMsg(cartState?.msg)
    }
  }, [cartState])

  const handleRemoveItem = (id) => {
    dispatch(removeFromCart(id))
    cart
    setDeleteRequested(true)
  }

  const handleOrder = () => {
    navigate('/create-order')
  }

  const handleOpenItem = (item) => {
    console.log('handleOpenItem')
  }

  const handleQuantity = (itemId, quantity) => {
    const cartItems = cartState?.cart?.items
    const item = items.find((item) => item.itemId === itemId)
    const indexItemStoreCart = cartItems.findIndex((i) => i?.storeId === item?.storeId)
    const itemsInStoreCount = cartItems[indexItemStoreCart]?.itemsCount

    if (quantity <= itemsInStoreCount) {
      quantity === 0
        ? dispatch(removeFromCart(itemId))
        : dispatch(removeUnitFromCart(itemId))
      return
    }

    const itemsCount = quantity - itemsInStoreCount
    const itemSubTotal = item.price * itemsCount
    const itemShipping = item.shipping ? item.shipping : 0
    const itemTotal = itemSubTotal + itemShipping
    const calculated = {itemsCount, itemSubTotal, itemShipping, itemTotal}
    const itemCart = generateItemCart(auth, item, calculated)
    dispatch(addToCart(itemCart))
  }

  const props = {
    handleOpenItem,
    handleQuantity,
    handleRemoveItem,
    handleOrder,
    groupsByStore,
    subTotal,
    shipping,
    total,
  }

  return <Cart parameters={props} />
}

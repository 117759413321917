import { actionsEnumOrder } from '../../../constants';
import { readNewOrderAPI } from '../../../api';

const readNewOrderRequest = () => ({
    type: actionsEnumOrder.READ_NEW_ORDER_REQUEST,
});

const readNewOrderReceived = (order) => ({
    type: actionsEnumOrder.READ_NEW_ORDER_RECEIVED,
    order,
});

export const readNewOrder = (requestParameters) => (dispatch) => {
    dispatch(readNewOrderRequest());
    readNewOrderAPI(requestParameters).then((order) => {
        dispatch(readNewOrderReceived(order));
        return order;
    });
};

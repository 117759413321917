import axios from './axios'
import {URL_SEARCH} from '../urls'
import {formatIds} from '../utils'

export const searchAPI = async (requestParameters) => {
  const {auth, searchQuery} = requestParameters
  try {
    const response = await axios.get(`${URL_SEARCH}/${searchQuery}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.accessToken}`,
        User: `${auth?.userInfo?.id}`,
      },
      withCredentials: true,
    })

    const receivedResult = response.data.result
    const result = {
      storeSearchResult: receivedResult.storeSearchResult
        ? formatIds(receivedResult.storeSearchResult)
        : [],
      productSearchResult: receivedResult.productSearchResult
        ? formatIds(receivedResult.productSearchResult)
        : [],
    }

    return result
  } catch (err) {
    return err
  }
}
